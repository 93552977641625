import ConsumerDashboard from "./components/Consumer/Dashboard/ConsumerDashboard";
import ConsumerOffers from "./components/Consumer/Dashboard/ConsumerOffers";
import ConsumerTransactions from "./components/Consumer/Dashboard/ConsumerTransactions";
import ConsumerUploadReceipts from "./components/Consumer/Dashboard/ConsumerUploadReceipt";
import ConsumerHomepage from "./components/Consumer/Homepage/ConsumerHomepage";
import Transaction from "./components/Consumer/PublicTransaction/Transaction";
import Insights from "./components/Dashboard/Insights";
import Offers from "./components/Dashboard/Offers";
import Products from "./components/Dashboard/Products";
import RetailerDashboard from "./components/Dashboard/RetailerDashboard";
import Stores from "./components/Dashboard/Stores";
import Terminals from "./components/Dashboard/Terminals";
import Transactions from "./components/Dashboard/Transactions";
import Demo from "./components/Demo/Demo";
import Homepage from "./components/Homepage/Homepage";
import CookiesPolicy from "./components/Shared/CookiesPolicy";
import PrivacyPolicy from "./components/Shared/PrivacyPolicy";
import TermsAndConditions from "./components/Shared/TermsAndConditions";

const routes = [
  {
    path: "/",
    component: Homepage,
    isPublic: true,
    exact: true,
  },
  {
    path: "/retailer-dashboard",
    component: RetailerDashboard,
    isPublic: false,
    exact: false,
  },
  {
    path: "/stores",
    component: Stores,
    isPublic: false,
    exact: false,
  },
  {
    path: "/insights",
    component: Insights,
    isPublic: false,
    exact: false,
  },
  {
    path: "/offers",
    component: Offers,
    isPublic: false,
    exact: false,
  },
  {
    path: "/transactions",
    component: Transactions,
    isPublic: false,
    exact: false,
  },
  {
    path: "/consumer",
    component: ConsumerHomepage,
    isPublic: true,
    exact: true,
  },
  {
    path: "/consumer-account",
    component: ConsumerDashboard,
    isPublic: false,
    exact: true,
  },
  {
    path: "/consumer-transactions",
    component: ConsumerTransactions,
    isPublic: false,
    exact: true,
  },
  {
    path: "/consumer-offers",
    component: ConsumerOffers,
    isPublic: false,
    exact: true,
  },
  {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
    isPublic: true,
    exact: true,
  },
  {
    path: "/terminals",
    component: Terminals,
    isPublic: false,
    exact: true,
  },
  {
    path: "/products",
    component: Products,
    isPublic: false,
    exact: true,
  },
  {
    path: "/demo",
    component: Demo,
    isPublic: false,
    exact: true,
  },
  {
    path: "/upload-receipt",
    component: ConsumerUploadReceipts,
    isPublic: false,
    exact: true,
  },
  {
    path: "/cookies-policy",
    component: CookiesPolicy,
    isPublic: true,
    exact: true,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    isPublic: true,
    exact: true,
  },
  {
    path: "/transaction/:id",
    component: Transaction,
    isPublic: true,
  },
];

export default routes;
