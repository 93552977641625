import React from 'react';
import Scroll from 'react-scroll';
import styled from 'styled-components';
import Slider from "react-slick";
import { HorizontalLineSmall } from '../index.styled';
import { useTheme, useMediaQuery } from '@mui/material';

const Element  = Scroll.Element;

const TestemonialsWrapper = styled.div`
  width: ${({ $isMobile = false }) => $isMobile ? 90 : 50}%;
  margin: 0 auto;
  font-size: 18px;
  font-family: Gotham-Rounded-Medium !important;
  color: white;
  padding-top: 70px;
  padding-bottom: 100px;

  h4.title {
    margin-bottom: 70px;
    text-transform: uppercase;
    text-align: center;
  }

`;

const TestemonialItem = styled.div`
  h3.body {
    font-size: ${({ $isMobile = false }) => $isMobile ? 24 : 28}px;
    margin-bottom: 50px;
  }

  div.writer {
    border-top: 1px solid white;

    h3 {
      font-size: 16px;
      margin: 20px 0 10px 0;
      text-transform: uppercase;
    }

    h4 {
      margin: 10px 0;
      font-size: 13px;
    }
  }
`

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const Testemonials = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Element name="testemonials">
    <div style={{
      width: '100%',
      maxHeight: '100vh',
      background: 'linear-gradient(to top, #7fcbae, #00ff99)',
    }}
    >
      <TestemonialsWrapper $isMobile={isMobile}>
        <h4 className="title">The talk of the town</h4>
        <HorizontalLineSmall />

        {!isMobile ? (
          <TestemonialItem $isMobile={isMobile}>
            <h3 className="body">
              “A genuinely exciting technology!
              Simple and easy for customers
              to use and hugely beneficial
              to retailers.”
            </h3>
            <div className="writer">
              <h3>Robert Clarke</h3>
              <h4>Owner Luke Clothing, Bristol, cardiff</h4>
            </div>
          </TestemonialItem>
        ) : (
          <Slider {...settings}>
            <div>
              <TestemonialItem $isMobile={isMobile}>
                <h3 className="body">
                  “A genuinely exciting technology!
                  Simple and easy for customers
                  to use and hugely beneficial
                  to retailers.”
                </h3>
                <div className="writer">
                  <h3>Robert Clarke</h3>
                  <h4>Owner Luke Clothing, Bristol, cardiff</h4>
                </div>
              </TestemonialItem>
            </div>
          </Slider>
        )}
      </TestemonialsWrapper>
    </div>
  </Element>
  )
}

export default Testemonials;
