import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBKiEpo3mz8ZWL3pqqTaZsFRLRBbrllqKs",
  authDomain: "mypop-backup.firebaseapp.com",
  projectId: "mypop-backup",
  storageBucket: "mypop-backup.appspot.com",
  messagingSenderId: "78235550003",
  appId: "1:78235550003:web:b7cd98c410406b3c74fe46",
  measurementId: "G-LNV1156PMF"
};

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const storageRef = firebase.storage().ref();
const auth = firebase.auth();

export {
  app, db, storageRef, auth,
};
