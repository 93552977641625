import { Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomGreenButton, DashboardContainer, DashboardContent, HorizontalLineSmall, OneLineText } from '../index.styled';
import { makeStyles } from '@material-ui/core/styles';
import { getRetailerOffers, getRetailerTransactions } from '../../services/retailerService';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { setRetailerTransactions } from '../../redux/users/users';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { getStoreManagerOffers, getStoreManagerTransactions } from '../../services/storeService';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Template from '../Shared/TransactionsPDFTemplate';
import { RetailerHeaderDashboard } from '../Header/Header';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CSVLink } from "react-csv";
import { Redirect } from 'react-router-dom';
import logoGreen from '../../images/logo_green.png';

const useStyles = makeStyles((theme) => ({
  typographySelect: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#426A6D',
    textDecoration: 'none'
  },
  typographyLarge: {
    fontFamily: 'Gotham-Rounded-Book !important',
    color: '#A0D2BB',
    textAlign: 'right'
  },
  typographySmall: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#426A6D',
    textAlign: 'left',
    textTransform: 'uppercase',
    letterSpacing: '4px !important',
    marginLeft: '5px !important',
  
  },
  welcomeMessage: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '70%',
    marginTop: '-40px'
  },
  label: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '70%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  offersContainer: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '70%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  offer: {
    flex: '1 0 20%',
    margin: '5px',
    height: '230px',
    border: '0.5px solid black',
  },
  tableContainer: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '70%',
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#B5B5B5',
    color: theme.palette.common.white,
    fontFamily: 'Gotham-Rounded-Medium !important',
    textTransform: 'uppercase'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#3B3D40',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transactions = () => {
  const currentUser = useSelector((state) => state.users.currentUser);
  const transactionState = useSelector((state) => state.users.transactions);
  const classes = useStyles();
  const [transactions, setTransactions] = useState([]);
  const [offers, setOffers] = useState([]);
  const [showMessage, setShowMessage] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const csvData = [];

  const dispatch = useDispatch();

  const csvLabels = [
    { label: "Date", key: "date" },
    { label: "Store Name", key: "storeName" },
    { label: "Transaction ID", key: "id" },
    { label: "Consumer ID", key: "consumerId" },
    { label: "Price in GBP", key: "price" },
  ];

  const csvReport = {
    data: csvData,
    headers: csvLabels,
    filename: `Transactions report from ${new Date().toLocaleDateString()}@${new Date().toLocaleTimeString()}`
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (currentUser.role === 'retail_admin' || currentUser.role === 'retail_user') {
      getRetailerTransactions(currentUser.retailer_id, currentUser.token).then((response) => {
        setTransactions(response.data.transactions);
        dispatch(setRetailerTransactions(response.data));
      });
      getRetailerOffers(currentUser.retailer_id, currentUser.token).then((response) => {
        setOffers(response.data);
      })
    } else if (currentUser.role === 'store_admin' || currentUser.role === 'store_user') {
      getStoreManagerTransactions(currentUser.uid, currentUser.token).then((response) => {
        setTransactions(response.data.transactions);
      });
      getStoreManagerOffers(currentUser.uid, currentUser.token).then((response) => {
        setOffers(response.data);
      });
    }

    setTimeout(() => {
      setShowMessage(false)
    }, 3000);

  }, [currentUser.uid, currentUser.role, currentUser.retailer_id, dispatch, currentUser.token]);

  useMemo(() => {
    transactions.forEach((transaction) => {
      const object = {
        date: new Date(transaction.creationDateTime._seconds * 1000).toLocaleString(),
        storeName: transaction.parts.store.name,
        id: transaction.id,
        consumerId: transaction.parts.customer.id,
        price: transaction.parts.price.amount,
      }
      csvData.push(object);
    })
  }, [transactions, csvData]);

  return (
    <>
    {
      (currentUser.retailer_id) ? (
        <>
          <RetailerHeaderDashboard />
          <HorizontalLineSmall center color='#426A6D' thin width='80%' />
          <DashboardContainer>
            <DashboardContent>
              <div className={classes.welcomeMessage}>
                <Typography variant='h6' className={classes.typographyLarge}>
                  Hi {currentUser.firstName}, welcome back
                </Typography>
              </div>
              <div className={classes.label}>
                <Typography variant='body2' className={classes.typographySmall}>
                  Latest offers
                </Typography>
              </div>
              <div className={classes.offersContainer}>
                <div className={classes.offer}>
                  <div style={{ padding: '10px', overflowY: 'auto' }} >
                    <OneLineText title={true} className={classes.typographySmall}>
                      {offers[0]?.title}
                    </OneLineText>
                    <br />
                    <OneLineText className={classes.typographySmall}>
                      {offers[0]?.description}
                    </OneLineText>
                  </div>
                  <div>
                    <img alt="my-pop-logo" src={logoGreen} />
                    {/* <OfferHeadlinePhoto bg={offers[0]?.image} /> */}
                  </div>
                </div>
                <div className={classes.offer}>
                  <div style={{ padding: '10px', overflowY: 'auto' }} >
                    <OneLineText title={true} className={classes.typographySmall}>
                      {offers[1]?.title}
                    </OneLineText>
                    <br />
                    <OneLineText className={classes.typographySmall}>
                      {offers[1]?.description}
                    </OneLineText>
                  </div>
                  <div>
                    <img alt="my-pop-logo" src={logoGreen} />
                    {/* <OfferHeadlinePhoto bg={offers[1]?.image} /> */}
                  </div>
                </div>
                <div className={classes.offer}>
                  <div style={{ padding: '10px', overflowY: 'auto' }} >
                    <OneLineText title={true} className={classes.typographySmall}>
                      {offers[2]?.title}
                    </OneLineText>
                    <br />
                    <OneLineText className={classes.typographySmall}>
                      {offers[2]?.description}
                    </OneLineText>
                  </div>
                  <div>
                    <img alt="my-pop-logo" src={logoGreen} />
                    {/* <OfferHeadlinePhoto bg={offers[2]?.image} /> */}
                  </div>
                </div>
              </div>
              <br />
              <div className={classes.label}>
                <Typography variant='body2' className={classes.typographySmall}>
                  Transactions
                </Typography>
                <CustomGreenButton
                  hidden={transactions.length === 0}
                  id="demo-positioned-button"
                  aria-controls={open ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  Download...
                </CustomGreenButton>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem>
                    <PDFDownloadLink document={<Template currentUser={currentUser} transactions={transactionState.transactions} />} fileName={`Transactions for ${new Date().toLocaleDateString()}`}>
                      {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : <Typography className={classes.typographySelect}>PDF</Typography>
                      }
                    </PDFDownloadLink>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <CSVLink {...csvReport}>
                      <Typography className={classes.typographySelect}>CSV</Typography>
                    </CSVLink>
                  </MenuItem>
                </Menu>
              </div>
              {
                (transactions.length === 0) ? (
                  <div>
                    <Typography className={classes.typography} hidden={showMessage} variant='h4'>
                      You don't have any transactions yet.
                    </Typography>
                    <Typography hidden={!showMessage} className={classes.typography} variant='h4'>
                      Loading...
                    </Typography>
                  </div>
                ) : (
                  <div className={classes.tableContainer}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell align="right">Store name</StyledTableCell>
                            <StyledTableCell align="right">Transaction ID</StyledTableCell>
                            <StyledTableCell align="right">Consumer ID</StyledTableCell>
                            <StyledTableCell align="right">Price in GBP</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {transactions.map((row) => (
                            <StyledTableRow key={row.title}>
                              <StyledTableCell component="th" scope="row">
                                {new Date(row.creationDateTime._seconds * 1000).toDateString()}
                              </StyledTableCell>
                              <StyledTableCell align="right">{row.parts.store.name}</StyledTableCell>
                              <StyledTableCell align="right">{row.id}</StyledTableCell>
                              <StyledTableCell align="right">{row.parts.customer.id}</StyledTableCell>
                              <StyledTableCell align="right">{row.parts.price.amount.toFixed(2)}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )
              }
            </DashboardContent>
          </DashboardContainer>
        </>
      ) : (
        <Redirect to='/' />
      )
    }
    </>
  )
};

export default Transactions;
