import React from 'react';
import { Redirect } from 'react-router-dom';
import PrivateLayout from './PrivateLayout';
import { useSelector } from 'react-redux';

const PrivateLayoutContainer = ({ children, ...props }) => {
  const currentUser = useSelector((state) => state.users.currentUser);

  return (
    <>
      {
        (currentUser?.uid
        ? (
          <PrivateLayout
            currentUser={currentUser}
            {...props}
          >
            {children}
          </PrivateLayout>
        )
        : (<Redirect to="/" />)
      )
    }
  </>
  )
}


export default PrivateLayoutContainer;
