import React, { forwardRef, useEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  CustomButton,
  CustomInput,
  HorizontalLineSmall,
  CustomSelect,
  LoginBoxOverflown,
  CustomGreenButton,
} from "../index.styled";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { getStoresByRetailerId } from "../../services/storeService";
import { useMediaQuery, useTheme } from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createOffer, updateOffer } from "../../services/offerService";
import { Edit } from "@material-ui/icons";
import { ContentCopy } from "@mui/icons-material";
import { getProducts } from "../../services/productService";
import Select from "react-select";

const useStyles = makeStyles({
  typography: {
    fontFamily: "Gotham-Rounded-Medium !important",
  },
  label: {
    fontFamily: "Gotham-Rounded-Book !important",
  },
});

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#FFFFFF",
    color: "#426A6D",
    fontFamily: "Gotham-Rounded-Book !important",
    width: "350px",
    marginLeft: "auto",
    marginRight: "auto",
    height: "40px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "#FFFFFF",
      color: "#426A6D",
      cursor: isDisabled ? "not-allowed" : "default",
      fontFamily: "Gotham-Rounded-Book !important",
    };
  },
};

const criterias = [
  "Customers who spent more than 25£",
  "Customers who spent more than 50£",
  "Customers who spent more than 100£",
  "Customers who spent more than 150£",
  "Customers who spent more than 200£",
  "Customers whose birthday is this month",
  "Customers who are from Bristol",
  "Customers who are from London",
  "Customers who are from Manchester",
  "Customers who are from Birmingham",
  "Customers who purchased a given product",
  // 'Customers who are within 5 miles radius',
  // 'Customers who are within 10 miles radius',
  // 'Customers who are within 25 miles radius',
  "All customers",
];

const Transition = forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CreateOffer = ({
  transactions,
  edit = false,
  copy = false,
  offer = {},
}) => {
  const [open, setOpen] = useState(false);
  const [store, setStore] = useState(edit ? offer.s_id : "");
  const [criteria, setCriteria] = useState(edit ? offer.criteria : "");
  const [title, setTitle] = useState(edit || copy ? offer.title : "");
  const [description, setDescription] = useState(
    edit || copy ? offer.description : ""
  );
  const [offerCode, setOfferCode] = useState(
    edit || copy ? offer.offerCode : ""
  );
  const [offerStarts, setOfferStarts] = useState(edit ? offer.s_date : "");
  const [offerEnds, setOfferEnds] = useState(edit ? offer.e_date : "");
  const [terms, setTerms] = useState(
    edit || copy ? offer.terms : "Please add your terms and conditions..."
  );
  const [notificationHeading, setNotificationHeading] = useState(
    edit || copy ? offer.notificationHeading : ""
  );
  const [notificationContent, setNotificationContent] = useState(
    edit || copy ? offer.notificationContent : ""
  );
  const [stores, setStores] = useState([]);
  const classes = useStyles();
  const [showAlert, setShowAlert] = useState(false);
  const [users, setUsers] = useState([]);
  const currentUser = useSelector((state) => state.users.currentUser);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");

  const handleStoreChange = (e) => {
    setStore(e.target.value);
  };

  const handleCriteriaChange = (e) => {
    setCriteria(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleOfferCodeChange = (e) => {
    setOfferCode(e.target.value);
  };

  const handleOfferStartsChange = (e) => {
    setOfferStarts(e.target.value);
  };

  const handleOfferEndsChange = (e) => {
    setOfferEnds(e.target.value);
  };

  const handleNotificationHeadingChange = (e) => {
    setNotificationHeading(e.target.value);
  };

  const handleNotificationContentChange = (e) => {
    setNotificationContent(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearFields = () => {
    setTitle("");
    setDescription("");
    setOfferCode("");
    setOfferStarts("");
    setOfferEnds("");
    setTerms("");
    setNotificationHeading("");
    setNotificationContent("");
    setCriteria("");
  };

  const handleAddOfferClick = async () => {
    if (edit) {
      if (users.length === 0) {
        window.alert("Your criteria matches no users!");
      } else {
        await updateOffer({
          data: {
            id: offer.id,
            title,
            description,
            s_date: offerStarts,
            e_date: offerEnds,
            offerCode,
            s_id: store,
            notificationContent,
            notificationHeading,
            offerUsers: users,
            criteria,
            terms,
          },
          token: currentUser.token,
        }).then(() => {
          setShowAlert(true);
          clearFields();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      }
    } else {
      if (users.length === 0) {
        window.alert("Your criteria matches no users!");
      } else {
        await createOffer({
          data: {
            title,
            description,
            s_date: offerStarts,
            e_date: offerEnds,
            offerCode,
            r_id: currentUser.retailer_id,
            s_id: store,
            notificationContent,
            notificationHeading,
            offerUsers: users,
            criteria,
            terms,
          },
          token: currentUser.token,
        }).then(() => {
          setShowAlert(true);
          clearFields();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      }
    }
  };

  useEffect(() => {
    getStoresByRetailerId(currentUser.retailer_id, currentUser.token).then(
      (response) => {
        setStores(response.data.stores);
      }
    );

    getProducts(currentUser.token).then((response) => {
      let arr = [];
      response.data.forEach((p) => {
        arr.push({
          label: p.name,
          value: p.id,
        });
      });
      setAllProducts(arr);
    });
  }, [currentUser.retailer_id, currentUser.token]);

  useEffect(() => {
    let tempUsers = [];
    if (criteria !== "") {
      transactions.forEach((transaction) => {
        if (store === "All stores") {
          if (!tempUsers.some((u) => u.id === transaction.parts.customer.id)) {
            let tempItems = [];
            transaction.parts.lineItems.forEach((item) => {
              tempItems.push(item.customerProduct);
            });
            tempUsers.push({
              ...transaction.parts.customer,
              spent: transaction.parts.price.amount,
              products: tempItems,
            });
          } else {
            let objIndex = tempUsers.findIndex(
              (obj) => obj.id === transaction.parts.customer.id
            );
            tempUsers[objIndex].spent += transaction.parts.price.amount;
            transaction.parts.lineItems.forEach((item) => {
              if (
                !tempUsers[objIndex].products.some(
                  (p) => p.id === item.customerProduct.id
                )
              ) {
                tempUsers[objIndex].products.push(item.customerProduct);
              }
            });
          }
        } else {
          if (transaction.parts.store.id === store) {
            if (
              !tempUsers.some((u) => u.id === transaction.parts.customer.id)
            ) {
              let tempItems = [];
              transaction.parts.lineItems.forEach((item) => {
                tempItems.push(item.customerProduct);
              });
              tempUsers.push({
                ...transaction.parts.customer,
                spent: transaction.parts.price.amount,
                products: tempItems,
              });
            } else {
              let objIndex = tempUsers.findIndex(
                (obj) => obj.id === transaction.parts.customer.id
              );
              tempUsers[objIndex].spent += transaction.parts.price.amount;
              transaction.parts.lineItems.forEach((item) => {
                if (
                  !tempUsers[objIndex].products.some(
                    (p) => p.id === item.customerProduct.id
                  )
                ) {
                  tempUsers[objIndex].products.push(item.customerProduct);
                }
              });
            }
          }
        }
      });
    }

    if (criteria === "Customers who are from Bristol") {
      tempUsers = tempUsers.filter(
        (u) =>
          u.address.city === "Bristol" ||
          u.address.city === "BRISTOL" ||
          u.address.city === "bristol"
      );
    } else if (criteria === "Customers whose birthday is this month") {
      tempUsers = tempUsers.filter(
        (u) => new Date(u.birthday).getMonth() === new Date().getMonth()
      );
    } else if (criteria === "Customers who spent more than 25£") {
      tempUsers = tempUsers.filter((u) => u.spent > 25);
    } else if (criteria === "Customers who spent more than 50£") {
      tempUsers = tempUsers.filter((u) => u.spent > 50);
    } else if (criteria === "Customers who spent more than 100£") {
      tempUsers = tempUsers.filter((u) => u.spent > 100);
    } else if (criteria === "Customers who spent more than 150£") {
      tempUsers = tempUsers.filter((u) => u.spent > 150);
    } else if (criteria === "Customers who spent more than 200£") {
      tempUsers = tempUsers.filter((u) => u.spent > 200);
    } else if (criteria === "All customers") {
      tempUsers = tempUsers.filter((u) => u.id !== null);
    } else if (criteria === "Customers who are from London") {
      tempUsers = tempUsers.filter(
        (u) =>
          u.address.city === "London" ||
          u.address.city === "LONDON" ||
          u.address.city === "london"
      );
    } else if (criteria === "Customers who are from Manchester") {
      tempUsers = tempUsers.filter(
        (u) =>
          u.address.city === "Manchester" ||
          u.address.city === "MANCHESTER" ||
          u.address.city === "manchester"
      );
    } else if (criteria === "Customers who are from Birmingham") {
      tempUsers = tempUsers.filter(
        (u) =>
          u.address.city === "Birmingham" ||
          u.address.city === "BIRMINGHAM" ||
          u.address.city === "birmingham"
      );
    } else if (criteria === "Customers who purchased a given product") {
      tempUsers.forEach((user) => {
        let index = tempUsers.indexOf(user);
        let flag = false;
        user.products.forEach((product) => {
          if (product.id === selectedProduct) {
            flag = true;
          }
        });
        if (!flag) {
          tempUsers.splice(index, 1);
        }
      });
    }
    let usersToSend = tempUsers.map(
      ({ address, birthday, email, name, ...id }) => id
    );
    usersToSend = usersToSend.map((u) => u.id);
    setUsers(usersToSend);
  }, [criteria, transactions, store, selectedProduct]);

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleClickOpen}>
          <Edit />
        </IconButton>
      ) : copy ? (
        <IconButton onClick={handleClickOpen}>
          <ContentCopy />
        </IconButton>
      ) : (
        <CustomGreenButton
          hidden={
            currentUser.role !== "retail_admin" &&
            currentUser.role !== "store_admin"
          }
          onClick={handleClickOpen}
          size="small"
        >
          Create new
        </CustomGreenButton>
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: "linear-gradient(#7fcbae, #00ff99)",
            textAlign: "center",
          },
        }}
      >
        <LoginBoxOverflown>
          <Collapse in={showAlert}>
            {edit ? (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                Successfully updated your offer!
              </Alert>
            ) : (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                Successfully created new offer!
              </Alert>
            )}
          </Collapse>
          <IconButton
            onClick={handleClose}
            aria-label="close"
            style={{
              position: "absolute",
              right: "0",
              color: "#FFFFFF",
            }}
          >
            <CloseIcon />
          </IconButton>
          {edit ? (
            <Typography className={classes.typography} variant="body1">
              EDIT OFFER
            </Typography>
          ) : (
            <Typography className={classes.typography} variant="body1">
              CREATE NEW OFFER
            </Typography>
          )}
          <HorizontalLineSmall center />
          <br />
          <br />
          <CustomSelect
            placeholder="Store*"
            aria-label="Store"
            value={store}
            onChange={handleStoreChange}
          >
            <option value="" disabled defaultValue="">
              Select store *
            </option>
            {stores.map((store) => {
              return (
                <option key={store.s_id} value={store.s_id}>
                  {store.s_name}
                </option>
              );
            })}
            <option value="All stores">All stores</option>
          </CustomSelect>
          <br />
          <CustomSelect
            placeholder="Criteria*"
            aria-label="Ctieria"
            value={criteria}
            onChange={handleCriteriaChange}
            disabled={store === ""}
          >
            <option value="" disabled defaultValue="">
              Select criteria *
            </option>
            {criterias.map((c) => {
              return (
                <option key={c} value={c}>
                  {c}
                </option>
              );
            })}
          </CustomSelect>
          <div hidden={criteria !== "Customers who purchased a given product"}>
            <Select
              styles={colourStyles}
              options={allProducts}
              className="basic-single"
              classNamePrefix="select"
              isDisabled={false}
              isLoading={false}
              isClearable={false}
              isRtl={false}
              isSearchable={true}
              name="product"
              onChange={(product) => {
                if (product.value) {
                  setSelectedProduct(product.value);
                } else {
                  setSelectedProduct("");
                }
              }}
            />
          </div>
          <br />
          <CustomInput
            placeholder="Offer title*"
            aria-label="Offer title"
            value={title}
            onChange={handleTitleChange}
          />
          <br />
          <CustomInput
            placeholder="Offer description*"
            aria-label="Offer description"
            value={description}
            onChange={handleDescriptionChange}
          />
          <br />
          <CustomInput
            placeholder="Offer code*"
            aria-label="Offer code"
            value={offerCode}
            onChange={handleOfferCodeChange}
          />
          <br />
          <Typography className={classes.label}>Offer start date</Typography>
          <CustomInput
            type="date"
            value={offerStarts}
            onChange={handleOfferStartsChange}
            min={new Date().toISOString().split("T")[0]}
          />
          <br />
          <Typography className={classes.label}>Offer end date</Typography>
          <CustomInput
            type="date"
            value={offerEnds}
            onChange={handleOfferEndsChange}
            min={offerStarts}
            disabled={offerStarts === ""}
          />
          <br />
          <CustomInput
            placeholder="Notification heading*"
            aria-label="Notification heading"
            value={notificationHeading}
            onChange={handleNotificationHeadingChange}
          />
          <br />
          <CustomInput
            placeholder="Notification content*"
            aria-label="Notification content"
            value={notificationContent}
            onChange={handleNotificationContentChange}
          />
          <br />
          <br />
          <ReactQuill
            style={{
              margin: "auto",
              backgroundColor: "#FFFFFF",
              color: "#000000",
              overflowX: "scroll",
              height: "150px",
              width: isMobile ? "350px" : "",
            }}
            theme="snow"
            value={terms}
            onChange={setTerms}
          />
          <br />
          <CustomButton
            variant="contained"
            onClick={() => handleAddOfferClick()}
          >
            {edit ? <>Edit offer</> : <>Create offer</>}
          </CustomButton>
        </LoginBoxOverflown>
      </Dialog>
    </div>
  );
};

export default CreateOffer;
