import React, { forwardRef, useEffect, useState } from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CustomButton, HorizontalLineSmall, LoginBoxOverflown, CustomGreenButton, CustomInput, CustomSelect } from '../index.styled';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import { createStore, updateStore } from '../../services/storeService';
import 'react-quill/dist/quill.snow.css';
import { getRetailerUsers } from '../../services/userService';
import { Edit } from '@material-ui/icons';

const useStyles = makeStyles({
  typography: {
    fontFamily: 'Gotham-Rounded-Medium !important'
  },
  label: {
    fontFamily: 'Gotham-Rounded-Book !important',
  },
});

const Transition = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const CreateStore = ({ edit = false, store = {} }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(edit ? store.s_name : '');
  const [email, setEmail] = useState(edit ? store.s_email : '');
  const [address, setAddress] = useState(edit ? store.s_address : '');
  const [openingTimeMonday, setOpeningTimeMonday] = useState(edit ? store.opening_timings.monday : '');
  const [openingTimeTuesday, setOpeningTimeTuesday] = useState(edit ? store.opening_timings.tuesday : '');
  const [openingTimeWednesday, setOpeningTimeWednesday] = useState(edit ? store.opening_timings.wednesday : '');
  const [openingTimeThursday, setOpeningTimeThursday] = useState(edit ? store.opening_timings.thursday : '');
  const [openingTimeFriday, setOpeningTimeFriday] = useState(edit ? store.opening_timings.friday : '');
  const [openingTimeSaturday, setOpeningTimeSaturday] = useState(edit ? store.opening_timings.saturday : '');
  const [openingTimeSunday, setOpeningTimeSunday] = useState(edit ? store.opening_timings.sunday : '');
  const [users, setUsers] = useState([]);
  const [manager, setManager] = useState(edit ? store.s_manager : '');
  const classes = useStyles();
  const [showAlert, setShowAlert] = useState(false);
  const currentUser = useSelector((state) => state.users.currentUser);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearFields = () => {
    setName('');
    setEmail('');
    setAddress('');
    setOpeningTimeMonday('');
    setOpeningTimeTuesday('');
    setOpeningTimeWednesday('');
    setOpeningTimeThursday('');
    setOpeningTimeFriday('');
    setOpeningTimeSaturday('');
    setOpeningTimeSunday('');
    setManager('');
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleOpeningTimeMondayChange = (e) => {
    setOpeningTimeMonday(e.target.value);
  };

  const handleOpeningTimeTuesdayChange = (e) => {
    setOpeningTimeTuesday(e.target.value);
  };

  const handleOpeningTimeWednesdayChange = (e) => {
    setOpeningTimeWednesday(e.target.value);
  };

  const handleOpeningTimeThursdayChange = (e) => {
    setOpeningTimeThursday(e.target.value);
  };

  const handleOpeningTimeFridayChange = (e) => {
    setOpeningTimeFriday(e.target.value);
  };

  const handleOpeningTimeSaturdayChange = (e) => {
    setOpeningTimeSaturday(e.target.value);
  };

  const handleOpeningTimeSundayChange = (e) => {
    setOpeningTimeSunday(e.target.value);
  };

  const hnadleManagerChange = (e) => {
    setManager(e.target.value);
  };

  const handleAddStoreClick = async () => {
      if (edit) {
        await updateStore({
          data: {
            id: store.s_id,
            r_id: currentUser.retailer_id,
            s_name: name,
            s_email: email,
            s_address: address,
            s_cdate: new Date(),
            s_manager: manager,
            opening_timings: {
              monday: openingTimeMonday,
              tuesday: openingTimeTuesday,
              wednesday: openingTimeWednesday,
              thursday: openingTimeThursday,
              friday: openingTimeFriday,
              saturday: openingTimeSaturday,
              sunday: openingTimeSunday,
            },
          },
          token: currentUser.token,
        }).then(() => {
          setShowAlert(true);
          clearFields();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      } else {
        await createStore({
          data : {
            r_id: currentUser.retailer_id,
            s_name: name,
            s_email: email,
            s_address: address,
            s_cdate: new Date(),
            s_manager: manager,
            opening_timings: {
              monday: openingTimeMonday,
              tuesday: openingTimeTuesday,
              wednesday: openingTimeWednesday,
              thursday: openingTimeThursday,
              friday: openingTimeFriday,
              saturday: openingTimeSaturday,
              sunday: openingTimeSunday,
            },
          },
          token: currentUser.token,
        }).then(() => {
          setShowAlert(true);
          clearFields();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      }
  };

  useEffect(() => {
    getRetailerUsers(currentUser.retailer_id, currentUser.token).then((response) => {
      setUsers(response.data);
    });
  }, [currentUser.retailer_id, currentUser.token]);

  return (
    <div>
      {
        (edit) ? (
          <IconButton onClick={handleClickOpen}>
            <Edit />
          </IconButton>
        ) : (
          <CustomGreenButton hidden={currentUser.role !== 'retail_admin' && currentUser.role !== 'store_admin'} onClick={handleClickOpen} size="small">
            Create new
          </CustomGreenButton>
        )
      }
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: 'linear-gradient(#7fcbae, #00ff99)',
            textAlign: 'center'
          }
        }}
      >
        <LoginBoxOverflown>
        <Collapse in={showAlert}>
          {
            (edit) ? (
              <Alert
                severity='success'
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                Successfully edited your store!
              </Alert>
            ) : (
              <Alert
                severity='success'
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                Successfully created new store!
              </Alert>
            )
          }
        </Collapse>
          <IconButton
            onClick={handleClose}
            aria-label="close"
            style={{
              position: 'absolute',
              right: '0',
              color: '#FFFFFF'
            }}
          >
            <CloseIcon />
          </IconButton>
          {
            (edit) ? (
              <Typography className={classes.typography} variant='body1'>
                EDIT YOUR STORE
              </Typography>
            ) : (
              <Typography className={classes.typography} variant='body1'>
                CREATE NEW STORE
              </Typography>
            )
          }
          <HorizontalLineSmall center />
          <br />
          <br />
          <CustomInput
            value={name}
            onChange={handleNameChange}
            placeholder="Enter store name*"
          />
          <br />
          <CustomInput
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter store email*"
          />
          <br />
          <CustomInput
            value={address}
            onChange={handleAddressChange}
            placeholder="Enter store address*"
          />
          <br />
          <CustomSelect
            placeholder='Store manager*'
            value={manager}
            onChange={hnadleManagerChange}
          >
            <option value="" disabled defaultValue="">Select manager *</option>
            {
              users.map((c) => {
                return <option key={c.id} value={c.id}>{c.firstName} {c.lastName}</option>
              })
            }
          </CustomSelect>
          <br />
          <CustomInput
            value={openingTimeMonday}
            onChange={handleOpeningTimeMondayChange}
            placeholder="Enter opening time on Monday*"
          />
          <br />
          <CustomInput
            value={openingTimeTuesday}
            onChange={handleOpeningTimeTuesdayChange}
            placeholder="Enter opening time on Tuesday*"
          />
          <br />
          <CustomInput
            value={openingTimeWednesday}
            onChange={handleOpeningTimeWednesdayChange}
            placeholder="Enter opening time on Wednesday*"
          />
          <br />
          <CustomInput
            value={openingTimeThursday}
            onChange={handleOpeningTimeThursdayChange}
            placeholder="Enter opening time on Thursday*"
          />
          <br />
          <CustomInput
            value={openingTimeFriday}
            onChange={handleOpeningTimeFridayChange}
            placeholder="Enter opening time on Friday*"
          />
          <br />
          <CustomInput
            value={openingTimeSaturday}
            onChange={handleOpeningTimeSaturdayChange}
            placeholder="Enter opening time on Saturday*"
          />
          <br />
          <CustomInput
            value={openingTimeSunday}
            onChange={handleOpeningTimeSundayChange}
            placeholder="Enter opening time on Sunday*"
          />
          <br />
          <br />
          <CustomButton
            variant='contained'
            onClick={() => handleAddStoreClick()}
            disabled={name === ''
            || email === ''
            || address === ''
            || manager === ''
            || openingTimeMonday === ''
            || openingTimeTuesday === ''
            || openingTimeWednesday === ''
            || openingTimeThursday === ''
            || openingTimeFriday === ''
            || openingTimeSaturday === ''
            || openingTimeSunday === ''
          }
          >
            {
              (edit) ? (
                <>
                  Edit store
                </>
              ) : (
                <>
                  Create store
                </>
              )
            }
          </CustomButton>
        </LoginBoxOverflown>
      </Dialog>
    </div>
  )
}

export default CreateStore;
