import React from 'react';
import Scroll from 'react-scroll';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Slider from "react-slick";
import SectionTitle from '../../Shared/SectionTitle';
import NeverLoseRec from '../../../images/never_lose_rec.png';
import Tracking from '../../../images/easy_to_org.png';
import Personalized from '../../../images/personilised.png';
import NfcImg from '../../../images/nfc1.png';
import bristol from '../../../images/Bristol #1.png';
import envAgency from '../../../images/Environment Agency.png';
import fsc from '../../../images/FSC.png';
import goGreen from '../../../images/Go Green.png';
import rainforest from '../../../images/Rainforest Alliance.png';
import { useTheme, useMediaQuery } from '@mui/material';

const Element  = Scroll.Element;

const BoxWrapper = styled.div`
  display: flex;
  padding: 30px 20px 0 20px;
  margin-top: 30px;
`;

const StyledBox = styled(Box)`
  max-width: ${({ $isMobile = false }) => $isMobile ? 100 : 25}%;
  flex-basis: auto;
  flex-grow: 1;
  text-align: center;
  font-family: Gotham-Rounded-Book;
  font-size: 18px;
  color: #3b3d40;
  padding: 0 15px;

  img {
    height: 100px;
    margin: 0 auto;
    margin-bottom: 5px;
  }

  h3 {
    font-family: Gotham-Rounded-Medium !important;
    text-transform: uppercase;
    font-size: 18px;
    margin: 20px 0;
  }

  p {
    padding: 0 18px;
    margin: auto;
  }
`;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const HowItWorks = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Element name="consumer-how-it-works">
    <div style={{
      width: '100%',
      height: 'auto',
      background: '#FFFFFF',
      paddingTop: '80px',
      paddingBottom: '100px',
    }}
    >
      <SectionTitle color="#3B3D40">
        How it works
      </SectionTitle>

      {!isMobile ? (
        <BoxWrapper>
          <StyledBox $isMobile={isMobile}>
            <img src={Personalized} alt="TARGETED MARKETING" />
            <h3>OFFERS JUST FOR YOU</h3>
            <p>Shops and retailers can send you relevant and money-saving offers and promotions.</p>
          </StyledBox>
          <StyledBox $isMobile={isMobile}>
            <img src={NeverLoseRec} alt="POINT OF PURCHASE" />
            <h3>NEVER LOSE A RECEIPT</h3>
            <p>Wallet or purse stuffed full of receipts? Not any more. Simply download and register your account. With My-POP there are no fees or subscriptions.</p>
          </StyledBox>
          <StyledBox $isMobile={isMobile}>
            <img src={NfcImg} alt="ONE ZAP AND IT'S DONE" />
            <h3>ONE ZAP AND IT'S DONE</h3>
            <p>Just tap your phone with NFC at the till, or scan your QR code/bar code from your My-POP app. Whoosh - your receipt is sent straight to your phone.</p>
          </StyledBox>
          <StyledBox $isMobile={isMobile}>
            <img src={Tracking} alt="EVERYTHING IN ONE PLACE" />
            <h3>KEEP TRACK OF STUFF</h3>
            <p>My-POP lets you manage all your receipts online, or on your phone. Perfect for personal finances and business accounts.</p>
          </StyledBox>
        </BoxWrapper>
      ) : (
        <Slider {...settings}>
          <div>
            <StyledBox $isMobile={isMobile}>
              <img src={Personalized} alt="TARGETED MARKETING" />
              <h3>OFFERS JUST FOR YOU</h3>
              <p>Shops and retailers can send you relevant and money-saving offers and promotions.</p>
            </StyledBox>
          </div>
          <div>
            <StyledBox $isMobile={isMobile}>
              <img src={NeverLoseRec} alt="POINT OF PURCHASE" />
              <h3>NEVER LOSE A RECEIPT</h3>
              <p>Wallet or purse stuffed full of receipts? Not any more. Simply download and register your account. With My-POP there are no fees or subscriptions.</p>
            </StyledBox>
          </div>
          <div>
            <StyledBox $isMobile={isMobile}>
              <img src={NfcImg} alt="ONE ZAP AND IT'S DONE" />
              <h3>ONE ZAP AND IT'S DONE</h3>
              <p>Just tap your phone with NFC at the till, or scan your QR code/bar code from your My-POP app. Whoosh - your receipt is sent straight to your phone.</p>
            </StyledBox>
          </div>
          <div>
            <StyledBox $isMobile={isMobile}>
              <img src={Tracking} alt="EVERYTHING IN ONE PLACE" />
              <h3>KEEP TRACK OF STUFF</h3>
              <p>My-POP lets you manage all your receipts online, or on your phone. Perfect for personal finances and business accounts.</p>
            </StyledBox>  
          </div>
        </Slider>
      )}
      <br />
      <br />
      <StyledBox $isMobile={isMobile} style={{ maxWidth: '100%' }}>
        <h3>
          Good for mother nature
        </h3>
        <p style={{ width: '60%', textAlign: 'center' }}>And, of course, you’ll always know you’re doing your bit for the environment.
           Did you know that UK retailers issue 11.2 billion paper receipts every year? That’s a lot of wasted paper that’s doing no good for the environment.
           It’s time we looked to technology for an answer. With My-POP all receipts  are sent digitally to your phone. No hassle, no waiting and no paper.
        </p>
      </StyledBox>
      <br />
      <br />
      <StyledBox $isMobile={isMobile} style={{ maxWidth: '100%' }}>
          <h3>
            Passionate supporters of
          </h3>
      </StyledBox>
      {
        (!isMobile) ? (
        <BoxWrapper>
            <StyledBox $isMobile={isMobile} style={{ maxWidth: '20%' }}>
              <img src={fsc} alt="POINT OF PURCHASE" />
            </StyledBox>
            <StyledBox $isMobile={isMobile} style={{ maxWidth: '20%' }}>
              <img src={bristol} alt="ONE ZAP AND IT'S DONE" />
            </StyledBox>
            <StyledBox $isMobile={isMobile} style={{ maxWidth: '20%' }}>
              <img src={goGreen} alt="EVERYTHING IN ONE PLACE" />
            </StyledBox>
            <StyledBox $isMobile={isMobile} style={{ maxWidth: '20%' }}>
              <img src={envAgency} alt="TARGETED MARKETING" />
            </StyledBox>
            <StyledBox $isMobile={isMobile} style={{ maxWidth: '20%' }}>
              <img src={rainforest} alt="TARGETED MARKETING" />
            </StyledBox>
        </BoxWrapper>
        ) : (
          <Slider {...settings}>
            <div>
              <StyledBox $isMobile={isMobile}>
                <img src={fsc} alt="POINT OF PURCHASE" />
              </StyledBox>
            </div>
            <div>
              <StyledBox $isMobile={isMobile}>
                <img src={bristol} alt="ONE ZAP AND IT'S DONE" />
              </StyledBox>
            </div>
            <div>
              <StyledBox $isMobile={isMobile}>
                <img src={goGreen} alt="EVERYTHING IN ONE PLACE" />
              </StyledBox> 
            </div>
            <div>
              <StyledBox $isMobile={isMobile}>
                <img src={envAgency} alt="TARGETED MARKETING" />
              </StyledBox>
            </div>
            <div>
              <StyledBox $isMobile={isMobile}>
                <img src={rainforest} alt="TARGETED MARKETING" />
              </StyledBox>
            </div>
          </Slider>
        )
      }
    </div>
  </Element>
  )
}

export default HowItWorks;
