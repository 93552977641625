import React from 'react';
import { ConsumerHeaderHome } from '../../Header/Header';
import Home from './Home';
import HowItWorks from './HowItWorks';
import SignUp from './SignUp';

const ConsumerHomepage = () => {
  return (
    <>
      <ConsumerHeaderHome />
      <Home />
      <HowItWorks />
      <SignUp />
    </>
  )
}

export default ConsumerHomepage;
