import React from 'react';

const PrivateLayout = ({
  children
}) => {

  return (
    <div className="private-layout-container">
      <div className="private-layout-main-content">
        {children}
      </div>
    </div>
  );
};

export default PrivateLayout;
