import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { getTransactionById } from "../../../services/transactionsService";
import Loading from "../../Shared/Loading";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getRetailerTerms } from "../../../services/retailerService";

const useStyles = makeStyles({
  containerNoTransactions: {
    width: "100%",
    height: "100vh",
    textAlign: "center",
    alignContent: "center",
  },
  container: {
    width: "100%",
  },
  topHeader: {
    padding: "40px",
    textAlign: "right",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  typography: {
    fontFamily: "Gotham-Rounded-Book !important",
  },
  typographyBold: {
    fontFamily: "Gotham-Rounded-Book !important",
    fontWeight: "bold",
  },
  divider: {
    height: "2px",
    backgroundColor: "#426A6D",
  },
  title: {
    padding: "15px",
  },
  mainContentMobile: {
    padding: "30px",
    justifyContent: "space-between",
  },
  mainContent: {
    display: "flex",
    padding: "30px",
    justifyContent: "space-between",
  },
  payment: {
    marginTop: "20px",
  },
  lineItems: {
    padding: "30px",
  },
  totalAmount: {
    textAlign: "right",
    padding: "30px",
  },
  termsAndConditions: {
    padding: "30px",
    marginTop: "20px",
  },
});

const Transaction = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const { id } = useParams();
  const [transaction, setTransaction] = useState({});
  const [retailerTerms, setRetailerTerms] = useState("");

  const getTransaction = async (id) => {
    const transactionData = await getTransactionById(id);
    if (transactionData.data.data) {
      setTransaction(transactionData.data.data);
      const retailerTermsData =
        transactionData.data.data.parts.store.r_id &&
        transactionData.data.data.parts.store.r_id !== "N/A" &&
        (await getRetailerTerms(transactionData.data.data.parts.store.r_id));
      if (!retailerTermsData) {
        setRetailerTerms({
          termsPlainText:
            "No terms and conditions found as retailer is not registered within My-POP platform.",
        });
      } else {
        setRetailerTerms(retailerTermsData.data);
      }
    } else {
      setTransaction(null);
    }
  };

  useEffect(() => {
    getTransaction(id);
  }, [id]);

  return (
    <div>
      {!transaction ? (
        <div className={classes.containerNoTransactions}>
          <Typography className={classes.typography} variant="h2">
            Transaction is not found.
          </Typography>
        </div>
      ) : !transaction.id || !retailerTerms ? (
        <Loading />
      ) : (
        <div className={classes.container}>
          <div className={classes.topHeader}>
            <div>
              <img src={transaction.parts.store.r_logo} alt="Logo" />
            </div>
            <div>
              <Typography variant="h5" className={classes.typography}>
                {transaction.parts.merchant.merchantName}
              </Typography>
              <Typography variant="h5" className={classes.typography}>
                {transaction.parts.merchant.merchantAddress}
              </Typography>
            </div>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.title}>
            <Typography className={classes.typography} variant="h3">
              Sales receipt
            </Typography>
            <Typography className={classes.typography} variant="h5">
              Customer: {transaction.parts.customer.name}
            </Typography>
          </div>
          <Divider className={classes.divider} />
          <div
            className={
              isMobile ? classes.mainContentMobile : classes.mainContent
            }
          >
            <div>
              <Typography className={classes.typographyBold} variant="h4">
                PAYMENTS
              </Typography>
              {transaction.parts.payments.map((payment) => {
                return (
                  <div className={classes.payment} key={payment.name}>
                    <Typography className={classes.typography} variant="h5">
                      Payment type: {payment.name}
                    </Typography>
                    <Typography className={classes.typography} variant="h5">
                      Amount: {payment.amount} GBP
                    </Typography>
                    <Typography className={classes.typography} variant="h5">
                      Change: {payment.change} GBP
                    </Typography>
                    <Typography className={classes.typography} variant="h5">
                      Date:{" "}
                      {new Date(
                        payment.date?._seconds * 1000
                      ).toLocaleDateString()}
                    </Typography>
                  </div>
                );
              })}
            </div>
            <div>
              <Typography className={classes.typography} variant="h5">
                Store ID: {transaction.parts.store.id}
              </Typography>
              <Typography className={classes.typography} variant="h5">
                Time:{" "}
                {new Date(
                  transaction.creationDateTime?._seconds * 1000
                ).toLocaleDateString()}{" "}
                at{" "}
                {new Date(
                  transaction.creationDateTime?._seconds * 1000
                ).toLocaleTimeString()}
              </Typography>
              <Typography className={classes.typography} variant="h5">
                Transaction ID: {transaction.id}
              </Typography>
              <Typography className={classes.typography} variant="h5">
                Till ID: {transaction.parts.payments[0].terminalId}
              </Typography>
              <Typography className={classes.typography} variant="h5">
                Sales advisor: {transaction.parts.agent.name}
              </Typography>
            </div>
          </div>
          <div className={classes.lineItems}>
            <Typography className={classes.typographyBold} variant="h4">
              List of purchased items
            </Typography>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead sx={{ bgcolor: "#d5d5d5" }}>
                  <TableRow>
                    <TableCell>Item name</TableCell>
                    <TableCell align="right">Description</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Cost per item</TableCell>
                    <TableCell align="right">Total NET cost</TableCell>
                    <TableCell align="right">Vat (amount)</TableCell>
                    <TableCell align="right">Total cost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transaction.parts.lineItems.map((item) => (
                    <TableRow
                      key={item.customerProduct.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {item.customerProduct.name}
                      </TableCell>
                      <TableCell align="right">
                        {item.customerProduct.description}
                      </TableCell>
                      <TableCell align="right">
                        {item.customerProduct.quantity}
                      </TableCell>
                      <TableCell align="right">{item.price.amount}</TableCell>
                      <TableCell align="right">
                        {(
                          item.price.amount * item.customerProduct.quantity
                        ).toFixed(2)}
                      </TableCell>
                      {item.taxes.map((tax) => {
                        return (
                          <TableCell align="right">
                            {tax.description.toLowerCase() === "vat"
                              ? item.taxes[0].quantity
                              : null}
                          </TableCell>
                        );
                      })}
                      {item.taxes.length > 0 ? (
                        <TableCell align="right">
                          {item.taxes.map((tax) => {
                            return (
                              tax.quantity +
                              item.price.amount * item.customerProduct.quantity
                            ).toFixed(2);
                          })}
                        </TableCell>
                      ) : (
                        <TableCell align="right">
                          {(
                            item.price.amount * item.customerProduct.quantity
                          ).toFixed(2)}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.totalAmount}>
            <Typography className={classes.typography} variant="h5">
              {transaction.parts.taxes.map((tax) => {
                if (tax.description.toLowerCase() === "vat") {
                  return (
                    <div>
                      Subtotal: {transaction.parts.price.amount.toFixed(2)} GBP
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              Total:{" "}
              {transaction.parts.price.amount +
                transaction.parts.taxes.find((tax) => tax.description === "vat")
                  .quantity}{" "}
              GBP
            </Typography>
          </div>
          <div className={classes.termsAndConditions}>
            {
              <Typography className={classes.typography}>
                {retailerTerms.termsPlainText}
              </Typography>
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default Transaction;
