import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConsumerHeaderDashboard } from '../../Header/Header';
import { makeStyles } from '@material-ui/core/styles';
import { ConsumerTransactionsContainer, HorizontalLineSmall } from '../../index.styled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import logo from '../../../images/logo.png';
import { getConsumerOffers } from '../../../services/userService';
import { Settings, ShoppingBasket, Star } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Support } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#426A6D',
    textDecoration: 'none'
  },
  styledRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  typographyDarkUppercase: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#383838',
    textTransform: 'uppercase'
  },
  typographyDarkLowercase: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#383838',
  },
  typographyLightUppercase: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#808080',
    textTransform: 'uppercase'
  },
  typographyLightLowercase: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#808080',
  },
  typographyLightWhite: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    width: '100%',
    textAlign: 'center',
  },
  tabDark: {
    flex: '1 0 24%',
    borderRight: '0.5px solid #FFFFFF',
    paddingLeft: '20px',
    paddingRight: '20px',
    background: '#2D6163',
    height: '150px',
    textAlign: 'center',
    paddingTop: '30px'
  },
  tabLight: {
    flex: '1 0 24%',
    borderRight: '0.5px solid #FFFFFF',
    paddingLeft: '20px',
    paddingRight: '20px',
    background: '#74C6A5',
    height: '150px',
    textAlign: 'center',
    paddingTop: '30px',
    textDecoration: 'none !important',
    "&:hover": {
      cursor: 'pointer',
    }
  },
  icon: {
    fontSize: '80px !important',
    color: '#FFFFFF',
  },
  typographyWhite: {
    fontFamily: 'Gotham-Rounded-Book !important',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    "&:hover": {
      cursor: 'pointer',
    },
    textDecoration: 'none'
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#B5B5B5',
    color: theme.palette.common.white,
    fontFamily: 'Gotham-Rounded-Medium !important',
    textTransform: 'uppercase'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#3B3D40',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ConsumerOffers = () => {
  const classes = useStyles();
  const currentUser = useSelector((state) => state.users.currentUser);
  const [offers, setOffers] = useState([]);
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    getConsumerOffers(currentUser?.myPopId, currentUser.token).then((response) => {
      setOffers(response.data);
    });

    setTimeout(() => {
      setShowMessage(false)
    }, 2000);
  }, [currentUser?.myPopId, currentUser.token]);

  return (
    <>
      <ConsumerHeaderDashboard />
      <HorizontalLineSmall center color='#426A6D' thin width='80%' />
      <ConsumerTransactionsContainer>
        <img src={logo} alt='logo' />
        <Typography className={classes.typography}>
          Hi {currentUser.firstName},
        </Typography>
        <Typography className={classes.typography}>
          Welcome to your pop page. Here you can view and manage all your receipts.
        </Typography>
        <Typography className={classes.typography}>
          Don't forget to check the latest offers from your favorite stores in 'my deals'.
        </Typography>
        <div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Link className={classes.tabLight} to='/consumer-transactions'>
              <div>
                <ShoppingBasket className={classes.icon} />
                <Typography className={classes.typographyWhite}>
                  Transactions
                </Typography>
              </div>
            </Link>
            <div className={classes.tabDark}>
              <Star className={classes.icon} />
              <Typography className={classes.typographyWhite}>
                <Link className={classes.typographyWhite} to='/consumer-offers'>My offers</Link>
              </Typography>
            </div>
            <div className={classes.tabLight}>
              <Support className={classes.icon} />
              <Typography className={classes.typographyWhite}>
                Support
              </Typography>
            </div>
            <div className={classes.tabLight}>
              <Settings className={classes.icon} />
              <Typography className={classes.typographyWhite}>
                Settings
              </Typography>
            </div>  
          </div>
        </div>
        {
            (offers.length === 0) ? (
              <div>
                <Typography className={classes.typography} hidden={showMessage} variant='h4'>
                  You don't have any offers yet.
                </Typography>
                <Typography hidden={!showMessage} className={classes.typography} variant='h4'>
                  Loading...
                </Typography>
              </div>
            ) : (
              <div className={classes.tableContainer}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                      <TableRow>
                        <StyledTableCell>Offer name</StyledTableCell>
                        <StyledTableCell align="right">Description</StyledTableCell>
                        <StyledTableCell align="right">Store ID</StyledTableCell>
                        <StyledTableCell align="right">Created at</StyledTableCell>
                        <StyledTableCell align="right">Start date</StyledTableCell>
                        <StyledTableCell align="right">End date</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {offers.map((row) => (
                      <StyledTableRow key={row.title}>
                        <StyledTableCell component="th" scope="row">
                          {row.title}
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.description}</StyledTableCell>
                        <StyledTableCell align="right">{row.s_id}</StyledTableCell>
                        <StyledTableCell align="right">{new Date(row.createdAt._seconds * 1000).toDateString()}</StyledTableCell>
                        <StyledTableCell align="right">{row.s_date}</StyledTableCell>
                        <StyledTableCell align="right">{row.e_date}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )
          }
      </ConsumerTransactionsContainer>

    </>
  )
}

export default ConsumerOffers