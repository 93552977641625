import React, { forwardRef, useEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  CustomButton,
  HorizontalLineSmall,
  LoginBoxOverflown,
  CustomGreenButton,
  CustomInput,
  CustomSelect,
} from "../index.styled";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import {
  getStoresByRetailerId,
  getStoresForManager,
} from "../../services/storeService";
import "react-quill/dist/quill.snow.css";
import { createTerminal, editTerminal } from "../../services/terminalService";
import { Edit } from "@material-ui/icons";

const useStyles = makeStyles({
  typography: {
    fontFamily: "Gotham-Rounded-Medium !important",
  },
  label: {
    fontFamily: "Gotham-Rounded-Book !important",
  },
});

const Transition = forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CreateTerminal = ({ edit = false, terminal = {} }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(edit ? terminal.pseudoName : "");
  const [acquirerId, setAcquirerId] = useState(
    edit ? terminal.acquirer_terminal_ID : ""
  );
  const [gatewayId, setGatewayId] = useState(
    edit ? terminal.gateway_terminal_ID : ""
  );
  const [store, setStore] = useState(edit ? terminal.s_id : "");
  const [serialNumber, setSerialNumber] = useState(
    edit ? terminal.serialNumber : ""
  );
  const [stores, setStores] = useState([]);
  const classes = useStyles();
  const [showAlert, setShowAlert] = useState(false);
  const currentUser = useSelector((state) => state.users.currentUser);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearFields = () => {
    setName("");
    setAcquirerId("");
    setGatewayId("");
    setStore("");
    setSerialNumber("");
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleAcquirerIdChange = (e) => {
    setAcquirerId(e.target.value);
  };

  const handleGatewayIdChange = (e) => {
    setGatewayId(e.target.value);
  };

  const handleStoreChange = (e) => {
    setStore(e.target.value);
  };

  const handleSerialNumberChange = (e) => {
    setSerialNumber(e.target.value);
  };

  const handleAddStoreClick = async () => {
    if (edit) {
      await editTerminal({
        data: {
          id: terminal.id,
          s_id: store,
          pseudoName: name,
          acquirer_terminal_ID: acquirerId,
          gateway_terminal_ID: gatewayId,
          createDate: new Date(),
          serialNumber,
        },
        token: currentUser.token,
      }).then(() => {
        setShowAlert(true);
        clearFields();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    } else {
      await createTerminal({
        data: {
          s_id: store,
          pseudoName: name,
          acquirer_terminal_ID: acquirerId,
          gateway_terminal_ID: gatewayId,
          createDate: new Date(),
          serialNumber,
        },
        token: currentUser.token,
      }).then(() => {
        setShowAlert(true);
        clearFields();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    }
  };

  useEffect(() => {
    if (
      currentUser.role === "retail_admin" ||
      currentUser.role === "retail_user"
    ) {
      getStoresByRetailerId(currentUser.retailer_id, currentUser.token).then(
        (response) => {
          setStores(response.data.stores);
        }
      );
    } else if (
      currentUser.role === "store_admin" ||
      currentUser.role === "store_user"
    ) {
      getStoresForManager(currentUser.uid, currentUser.token).then(
        (response) => {
          setStores(response.data);
        }
      );
    }
  }, [
    currentUser.retailer_id,
    currentUser.role,
    currentUser.token,
    currentUser.uid,
  ]);

  return (
    <div>
      {edit ? (
        <IconButton>
          <Edit
            hidden={
              currentUser.role !== "retail_admin" &&
              currentUser.role !== "store_admin"
            }
            onClick={handleClickOpen}
          />
        </IconButton>
      ) : (
        <CustomGreenButton
          hidden={
            currentUser.role !== "retail_admin" &&
            currentUser.role !== "store_admin"
          }
          onClick={handleClickOpen}
          size="small"
        >
          Create new
        </CustomGreenButton>
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: "linear-gradient(#7fcbae, #00ff99)",
            textAlign: "center",
          },
        }}
      >
        <LoginBoxOverflown>
          <Collapse in={showAlert}>
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {edit ? (
                <>Successfully edited the terminal!</>
              ) : (
                <>Successfully created new terminal!</>
              )}
            </Alert>
          </Collapse>
          <IconButton
            onClick={handleClose}
            aria-label="close"
            style={{
              position: "absolute",
              right: "0",
              color: "#FFFFFF",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography className={classes.typography} variant="body1">
            {edit ? <>EDIT YOUR TERMINAL</> : <>CREATE NEW TERMINAL</>}
          </Typography>
          <HorizontalLineSmall center />
          <br />
          <br />
          <CustomInput
            value={name}
            onChange={handleNameChange}
            placeholder="Enter terminal name*"
          />
          <br />
          <CustomInput
            value={acquirerId}
            onChange={handleAcquirerIdChange}
            placeholder="Enter terminal acquirer ID*"
          />
          <br />
          <CustomInput
            value={gatewayId}
            onChange={handleGatewayIdChange}
            placeholder="Enter terminal gateway ID*"
          />
          <br />
          <CustomSelect
            placeholder="Store*"
            value={store}
            onChange={handleStoreChange}
          >
            <option value="" disabled defaultValue="">
              Select store *
            </option>
            {stores.map((s) => {
              return (
                <option key={s.s_id} value={s.s_id}>
                  {s.s_name}
                </option>
              );
            })}
          </CustomSelect>
          <br />
          <CustomInput
            value={serialNumber}
            onChange={handleSerialNumberChange}
            placeholder="Enter terminal SN*"
          />
          <br />
          <br />
          <CustomButton
            variant="contained"
            onClick={() => handleAddStoreClick()}
            disabled={
              name === "" ||
              acquirerId === "" ||
              gatewayId === "" ||
              store === "" ||
              serialNumber === ""
            }
          >
            {edit ? <>Edit terminal</> : <>Create terminal</>}
          </CustomButton>
        </LoginBoxOverflown>
      </Dialog>
    </div>
  );
};

export default CreateTerminal;
