import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer/lib/react-pdf.browser.es.js';
import logoGreen from '../../images/logo_green.png';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Times-Roman'
  },
  section: {
    margin: 10,
    padding: 10,
  },
  transactionContainer: {
    display: 'flex',
  },
  image: {
    width: 100,
    margin: 10,
  }
});

const Template = (props) => {

  return (
    <Document>
      <Page size="A4" style={styles.page}>
      <Image
        style={styles.image}
        src={logoGreen}
      />
        <View style={styles.section}>
          <Text>Transactions</Text>
          <Text>
            {
              new Date().toDateString()
            } at {
              new Date().toLocaleTimeString()
            }
          </Text>
          <Text>
            Requested by: { props.currentUser?.firstName } { props.currentUser?.lastName }
          </Text>
        </View>
        <View style={styles.section}>
          <Table data={props.transactions}>
            <TableHeader>
              <TableCell>
                Date
              </TableCell>
              <TableCell>
                Store name
              </TableCell>
              <TableCell>
                Transaction ID
              </TableCell>
              <TableCell>
                Consumer ID
              </TableCell>
              <TableCell>
                Price in GBP
              </TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell getContent={(r) => new Date(r.creationDateTime._seconds * 1000).toLocaleString()}/>
              <DataTableCell getContent={(r) => r.parts.store.name}/>
              <DataTableCell getContent={(r) => r.id}/>
              <DataTableCell getContent={(r) => r.parts.customer.id}/>
              <DataTableCell getContent={(r) => r.parts.price.amount}/>
            </TableBody>
          </Table>
        </View>
      </Page>
    </Document>
  )
};

export default Template;
