import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles({
  "@keyframes blinker": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
  container: {
    width: "100%",
    height: "100vh",
    textAlign: "center",
    alignContent: "center",
  },
  typography: {
    fontFamily: "Gotham-Rounded-Book !important",
    animationName: '$blinker',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
});

const Loading = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography className={classes.typography} variant="h2">
        Loading...
      </Typography>
    </div>
  );
};

export default Loading;
