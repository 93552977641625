import styled from 'styled-components';
import bg from '../images/homeBackground5.png';
import bgMobile from '../images/BackgroundWithShadow1.png';
import bgVisitConsumer from '../images/backgroundgirl.png';


export const LoginBox = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: transparent;
  width: 600px;
  color: #FFFFFF;
`
export const LoginBoxOverflown = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: transparent;
  color: #FFFFFF;
  max-height: 85%;
  overflow-y: auto;
`;

export const HorizontalLineSmall = styled.hr`
  border: ${props => props.thin ? '0.5px' : '2px'} solid ${props => props.color};
  opacity: 100%;
  width: ${props => props.width ? props.width : '80px'};
  margin-left: ${props => props.center ? 'auto' : ''};
  margin-right: ${props => props.center ? 'auto' : ''};
`;

export const HorizontalLineBig = styled.hr`
  border: 0.5px solid #FFFFFF;
  display: flex;
  justify-content: center;
  opacity: 100%;
`;

export const CustomInput = styled.input`
  border-radius: 5px;
  border: ${props => props.border ? '1px solid #426A6D' : 'none'};;
  height: 40px;
  width: ${props => props.width ? props.width : '350px'};
  margin-bottom: 10px;
  font-family: 'Gotham-Rounded-Book';
`;

export const CustomTextArea = styled.textarea`
  border-radius: 5px;
  border: none;
  height:  ${props => props.height};
  width: ${props => props.width};
  margin-bottom: 10px;
  padding: 15px;
`;

export const CustomSelect = styled.select`
  border-radius: 5px;
  border: none;
  height: 40px;
  width: 350px;
  margin-bottom: 10px;
  font-family: 'Gotham-Rounded-Book'
`;

export const CustomSelectSmall = styled.select`
  border-radius: 5px;
  border: none;
  margin-bottom: 10px;
  font-family: 'Gotham-Rounded-Medium'
`;

export const CustomGreenButton = styled.button`
  width: 150px;
  border: none;
  border-radius: 0px;
  color: #FFFFFF;
  background-color: #426A6D;
  height: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: 'Gotham-Rounded-Medium';
`;

export const CustomButton = styled.button`
  width: 170px;
  border: none;
  border-radius: 40px;
  color: #FFFFFF;
  background-color: #2F61FC;
  height: 40px;
  font-family: 'Gotham-Rounded-Book';
`;

export const CustomButtonSmall = styled.button`
  width: 120px;
  border: none;
  border-radius: 40px;
  color: #FFFFFF;
  background-color: #2F61FC;
  height: 40px;
  font-family: 'Gotham-Rounded-Book';
`;

export const SocialButton = styled.button`
  width: 250px;
  border: none;
  border-radius: 40px;
  color: #FFFFFF;
  background-color: #2F61FC;
  height: 40px;
  font-family: 'Gotham-Rounded-Book';
`;

export const HomeContainer = styled.div`
  width: 100%;
  height: 1146px;
  background: ${props => props.isMobile ? `url(${bgMobile})` : `url(${bg})`};
  background-size: cover;
  color: #FFFFFF;
  margin-top: -82px;
`;

export const HomeContext = styled.div`
  margin: 0;
  position: absolute;
  top: 6%;
  left: ${props => props.isMobile ? '50%' : '67%'};
  transform: translate(-50%, -50%);
  background: transparent;
  width: ${props => props.isMobile ? '' : '480px'};
  color: #FFFFFF;
`;

export const BenefitsContainer = styled.div`
  width: 100%;
  background: #F9F9F9;
  text-align: center;
`;

export const BenefitsContext = styled.div`
  background: transparent;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 85%;
`;

export const VisitOurConsumerSiteContainer = styled.div`
  width: 100%;
  background: url(${bgVisitConsumer});
  background-size: cover;
  color: #000000;
  margin-top: 80px;
  height: ${props => props.isMobile ? '800px' : '600px'};
  text-align: center;
`;

export const VisitOurConsumerSiteContext = styled.div`
  margin: 0;
  background: transparent;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding: ${props => props.isMobile ? '20px' : '100px'};
  color: #FFFFFF;
`;

export const DashboardContainer = styled.div`
  width: 100%;
  display: ${props => props.display ? props.display : ''};;
`;

export const DashboardContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #3B3D43;
  padding-top: 40px;
`;

export const ConsumerHomeContainer = styled.div`
  width: 100%;
  min-height: 800px;
  padding-bottom: 50px;
  background: linear-gradient(to bottom, #7fcbae, #00ff99);
  background-size: cover;
  color: #FFFFFF;
  margin-top: -82px;
`;

export const ConsumerHomeContext = styled.div`
  background: transparent;
  color: #FFFFFF;
  display: ${props => props.isMobile ? '' : 'flex'};
`;

export const StyledFooter = styled.div`
  background: #353438;
  min-height: 200px;
  display: grid;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

export const OfferHeadlinePhoto = styled.div`
  height: 100%;
  width: 200px;
  background: ${props =>  `url(${props.bg})`};
  background-size: cover;
`;

export const ConsumerTransactionsContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  padding-top: 40px;
`;

export const OneLineText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: ${props => props.title ? '20px' : '11px'}
`;
