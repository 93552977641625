import React from 'react';
import Home from './Home';
import Features from './Features';
import HowItWorks from './HowItWorks';
import Benefits from './Benefits';
import Testemonials from './Testemonials';
import OurPartners from './OurPartners/OurPartners';
import ContactUs from './ContactUs';
import VisitOurConsumerSite from './VisitOurConsumerSite';
import { RetailerHeaderHome } from '../Header/Header';

const Homepage = () => {
  return(
    <>
      <RetailerHeaderHome />
      <Home />
      <Features />
      <HowItWorks />
      <Benefits />
      <Testemonials />
      <OurPartners />
      <VisitOurConsumerSite />
      <ContactUs />
    </>
  )
};

export default Homepage;
