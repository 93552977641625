import React, { forwardRef, useState } from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CustomButton, HorizontalLineSmall, LoginBoxOverflown, CustomGreenButton, CustomInput, CustomSelect } from '../index.styled';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import 'react-quill/dist/quill.snow.css';
import { addProduct } from '../../services/productService';

const useStyles = makeStyles({
  typography: {
    fontFamily: 'Gotham-Rounded-Medium !important'
  },
  label: {
    fontFamily: 'Gotham-Rounded-Book !important',
  },
});

const categories = ['clothing', 'food', 'fuel', 'drinks', 'stationary', 'accessories'];

const Transition = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const CreateTerminal = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [price, setPrice] = useState('');
  const [vat, setVat] = useState('');
  const classes = useStyles();
  const [showAlert, setShowAlert] = useState(false);
  const currentUser = useSelector((state) => state.users.currentUser);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearFields = () => {
    setName('');
    setId('');
    setDescription('');
    setPrice('');
    setVat('');
    setCategory('');
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleIdChange = (e) => {
    setId(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleVatChange = (e) => {
    setVat(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  }

  const handleAddProductClick = async () => {
      await addProduct({
        data : {
          name,
          id,
          description,
          vat,
          price,
          createDate: new Date(),
          category,
        },
        token: currentUser.token,
      }).then(() => {
        setShowAlert(true);
        clearFields();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };

  return (
    <div>
      <CustomGreenButton hidden={currentUser.role !== 'retail_admin' && currentUser.role !== 'store_admin'} onClick={handleClickOpen} size="small">
        Create new
      </CustomGreenButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: 'linear-gradient(#7fcbae, #00ff99)',
            textAlign: 'center'
          }
        }}
      >
        <LoginBoxOverflown>
        <Collapse in={showAlert}>
          <Alert
            severity='success'
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Successfully created new product!
          </Alert>
        </Collapse>
          <IconButton
            onClick={handleClose}
            aria-label="close"
            style={{
              position: 'absolute',
              right: '0',
              color: '#FFFFFF'
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.typography} variant='body1'>
            CREATE NEW PRODUCT
          </Typography>
          <HorizontalLineSmall center />
          <br />
          <br />
          <CustomInput
            value={name}
            onChange={handleNameChange}
            placeholder="Enter product name*"
          />
          <br />
          <CustomInput
            value={id}
            onChange={handleIdChange}
            placeholder="Enter product ID*"
          />
          <br />
          <CustomInput
            value={description}
            onChange={handleDescriptionChange}
            placeholder="Enter product description*"
          />
          <br />
          <CustomSelect
            placeholder='Product category*'
            value={category}
            onChange={handleCategoryChange}
          >
            <option value="" disabled defaultValue="">Select product category *</option>
            {
              categories.map((s) => {
                return <option key={s} value={s}>{s.charAt(0).toUpperCase() + s.slice(1)}</option>
              })
            }
          </CustomSelect>
          <br />
          <CustomInput
            value={price}
            onChange={handlePriceChange}
            placeholder="Enter product price*"
          />
          <br />
          <CustomInput
            value={vat}
            onChange={handleVatChange}
            placeholder="Enter product VAT*"
          />
          <br />
          <br />
          <CustomButton
            variant='contained'
            onClick={() => handleAddProductClick()}
            disabled={name === ''
            || id === ''
            || description === ''
            || price === ''
            || vat === ''
          }
          >
            Create product
          </CustomButton>
        </LoginBoxOverflown>
      </Dialog>
    </div>
  )
}

export default CreateTerminal;
