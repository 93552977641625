import axios from 'axios';
import { URLS } from '../constants';

const BASE_URL = `${URLS.BASE_ENDPOINT_URL}/terminals`;

export const getTerminalById = (terminal_id, token) => axios.get(`${BASE_URL}/getById/${terminal_id}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getTerminalsByStoreId = (store_id, token) => axios.get(`${BASE_URL}/getByStore/${store_id}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getTerminalsForRetailer = (retailer_id, token) => axios.get(`${BASE_URL}/getForRetailer/${retailer_id}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getTerminalsForStoreManager = (s_manager, token) => axios.get(`${BASE_URL}/getForStoreManager/${s_manager}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const createTerminal = ({ data, token }) => axios.post(`${BASE_URL}/create`, data, { headers: {
    authorization: `Bearer ${token}`
}});
export const editTerminal = ({ data, token }) => axios.post(`${BASE_URL}/update`, data, { headers: {
    authorization: `Bearer ${token}`
}});
