import React, { useEffect, useState } from 'react';
import { DashboardContainer, DashboardContent, HorizontalLineSmall } from '../index.styled';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getRetailerOffers } from '../../services/retailerService';
import CreateOffer from './CreateOffer';
import { getStoreManagerOffers } from '../../services/storeService';
import { RetailerHeaderDashboard } from '../Header/Header';
import { Redirect } from 'react-router-dom';
import { Delete } from '@material-ui/icons';
import { deleteOffer } from '../../services/offerService';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#B5B5B5',
    color: theme.palette.common.white,
    fontFamily: 'Gotham-Rounded-Medium !important',
    textTransform: 'uppercase'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#3B3D40',
    textOverflow: 'ellipsis',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const useStyles = makeStyles((theme) => ({
  typographyLarge: {
    fontFamily: 'Gotham-Rounded-Book !important',
    color: '#A0D2BB',
    textAlign: 'right'
  },
  typographySmall: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#426A6D',
    textAlign: 'left',
    textTransform: 'uppercase',
    letterSpacing: '4px !important',
    marginLeft: '5px !important'
  },
  welcomeMessage: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '70%',
    marginTop: '-40px'
  },
  label: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '70%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  offersContainer: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '70%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  offer: {
    flex: '1 0 32%',
    margin: '5px',
    height: '200px',
    border: '1px solid black',
    display: 'flex'
  },
  tableContainer: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '70%',
  }
}));

const Offers = () => {
  const classes = useStyles();
  const [offers, setOffers] = useState([]);
  const [showMessage, setShowMessage] = useState(true);

  const currentUser = useSelector((state) => state.users.currentUser);
  const transactions = useSelector((state) => state.users.transactions);

  const deleteOfferClick = async (id) => {
    await deleteOffer(id, currentUser.token).then(async (res) => {
      if (currentUser.role === 'retail_admin' || currentUser.role === 'retail_user') {
        await getRetailerOffers(currentUser.retailer_id, currentUser.token).then((response) => {
          setOffers(response.data);
        });
      } else if (currentUser.role === 'store_admin' || currentUser.role === 'store_user') {
        await getStoreManagerOffers(currentUser.uid, currentUser.token).then((response) => {
          setOffers(response.data);
        });
      }
    })
  };

  useEffect(() => {
    if (currentUser.role === 'retail_admin' || currentUser.role === 'retail_user') {
      getRetailerOffers(currentUser.retailer_id, currentUser.token).then((response) => {
        setOffers(response.data);
      });
    } else if (currentUser.role === 'store_admin' || currentUser.role === 'store_user') {
      getStoreManagerOffers(currentUser.uid, currentUser.token).then((response) => {
        setOffers(response.data);
      });
    }
    setTimeout(() => {
      setShowMessage(false)
    }, 2000);
  }, [currentUser.retailer_id, currentUser.role, currentUser.token, currentUser.uid])

  return (
    <>
      {
        (currentUser.retailer_id) ? (
          <>
            <RetailerHeaderDashboard />
            <HorizontalLineSmall center color='#426A6D' thin width='80%' />
            <DashboardContainer>
              <DashboardContent>
                <div className={classes.welcomeMessage}>
                  <Typography variant='h6' className={classes.typographyLarge}>
                    Hi {currentUser.firstName}, welcome back
                  </Typography>
                </div>
                <br />
                <div className={classes.label}>
                  <Typography variant='body2' className={classes.typographySmall}>
                    Offers
                  </Typography>
                  <CreateOffer transactions={transactions?.transactions} />
                </div>
                {
                  (offers.length === 0) ? (
                    <div>
                      <Typography className={classes.typography} hidden={showMessage} variant='h4'>
                        You don't have any offers yet.
                      </Typography>
                      <Typography hidden={!showMessage} className={classes.typography} variant='h4'>
                        Loading...
                      </Typography>
                    </div>
                  ) : (
                    <div className={classes.tableContainer}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Offer name</StyledTableCell>
                              <StyledTableCell align="left">Description</StyledTableCell>
                              <StyledTableCell align="left">Store ID</StyledTableCell>
                              <StyledTableCell align="left">Created at</StyledTableCell>
                              <StyledTableCell align="left">Start date</StyledTableCell>
                              <StyledTableCell align="left">End date</StyledTableCell>
                              <StyledTableCell align="left">Actions</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          {offers.map((row) => (
                            <StyledTableRow key={row.title}>
                              <StyledTableCell component="th" scope="row">
                                {row.title}
                              </StyledTableCell>
                              <StyledTableCell align="left">{row.description.slice(0, 90)+'...'}</StyledTableCell>
                              <StyledTableCell align="left">{row.s_id}</StyledTableCell>
                              <StyledTableCell align="left">{new Date(row.createdAt._seconds * 1000).toDateString()}</StyledTableCell>
                              <StyledTableCell align="left">{row.s_date}</StyledTableCell>
                              <StyledTableCell align="left">{row.e_date}</StyledTableCell>
                              <StyledTableCell align="left" style={{ display: 'flex' }}>
                                <CreateOffer edit={true} offer={row} transactions={transactions?.transactions}/>
                                <IconButton onClick={() => deleteOfferClick(row.id)}>
                                  <Delete />
                                </IconButton>
                                <CreateOffer copy={true} offer={row} transactions={transactions?.transactions}/>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )
                }
              </DashboardContent>
            </DashboardContainer>
          </>
        ) : (
          <Redirect to='/' />
        )
      }
    </>
  )
}

export default Offers;
