import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ConsumerHeaderDashboard } from '../../Header/Header'
import { ConsumerTransactionsContainer, CustomGreenButton, CustomInput, HorizontalLineSmall } from '../../index.styled';
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { app } from '../../../firebase';
import { saveFromReceipt } from '../../../services/transactionsService';
import { Alert } from '@mui/material';


const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#426A6D',
    textDecoration: 'none'
  }
}));

const ConsumerUploadReceipts = () => {
  const classes = useStyles();
  const currentUser = useSelector((state) => state.users.currentUser);
  const [receiptImage, setReceiptImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  const handleReceiptImageChange = (e) => {
    setReceiptImage(e.target.files[0]);
  };

  const handleReceiptUpload = () => {
    setIsLoading(true);
    setErrorText('');
    app.storage().ref(`receipts/${receiptImage.name}`).put(receiptImage).then((snapshot) => {
        snapshot.ref.getDownloadURL().then(async (url) => {
            await saveFromReceipt({
                data: {
                    receiptImageUrl: url,
                    customer: currentUser,
                },
                token: currentUser.token,
            }).then((response) => {
              app.storage().ref(`receipts/${receiptImage.name}`).delete().then(() => {
                setIsLoading(false);
                window.location.href = '/consumer-transactions';
              })
            }).catch((err) => {
              if (err.response.status === 409) {
                setErrorText('Transaction with the same information already exists. Please make sure that you have not scanned the same invoice already.')
              }
              setIsLoading(false)
            })
        })
    })
  };

  return (
    <>
      <ConsumerHeaderDashboard />
      <HorizontalLineSmall center color='#426A6D' thin width='80%' />
      <ConsumerTransactionsContainer>
        <Typography className={classes.typography}>
          Hi {currentUser.firstName},
        </Typography>
        <Typography className={classes.typography}>
          Welcome to your receipt uploader.
        </Typography>
        <Typography className={classes.typography}>
          Once you upload the photo of your receipt, we will store the transaction information to your My-POP account.
        </Typography>
        <Alert severity='error' hidden={errorText === ''}>
          {errorText}
        </Alert>
        <div style={{ display: 'flex', marginTop: '20px' }}>
          <Typography className={classes.typography}>
            <label for="accept"> Upload your receipt *: &nbsp;</label>
          </Typography>
          <CustomInput type="file" accept="image/*" onChange={handleReceiptImageChange} />
        </div>
        <CustomGreenButton disabled={receiptImage === ''} onClick={() => handleReceiptUpload()}>
            {
                !isLoading ? (
                    <Typography>
                        Upload receipt
                    </Typography>
                ) : (
                    <CircularProgress color="success" />
                )
            }
            
        </CustomGreenButton>
      </ConsumerTransactionsContainer>
    </>
  )
}

export default ConsumerUploadReceipts;