import axios from 'axios';
import { URLS } from '../constants';

const BASE_URL = `${URLS.BASE_ENDPOINT_URL}/products`;

export const getProducts = (token) => axios.get(`${BASE_URL}/getProducts`, { headers: {
    authorization: `Bearer ${token}`
}});
export const addProduct = ({ data, token }) => axios.post(`${BASE_URL}/create`, data, { headers: {
    authorization: `Bearer ${token}`
}})
