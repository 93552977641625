/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { forwardRef, useState } from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import { CustomButton, CustomInput, HorizontalLineSmall, HorizontalLineBig, LoginBox , SocialButton, CustomButtonSmall} from '../index.styled';
import { app } from '../../firebase';
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import ForgotPassword from '../ForgotPassword';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

const consumerView = window.location.href.includes('/consumer');


const useStyles = makeStyles({
  typography: {
    fontFamily: 'Gotham-Rounded-Medium !important'
  },
  typographyUnderlined: {
    fontFamily: 'Gotham-Rounded-Light !important',
    textDecoration: 'underline',
  }
});

const Transition = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

export const Login = ({ smallButton, show }) => {
  const [open, setOpen] = useState(show);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const classes = useStyles();
  const [showAlert, setShowAlert] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const signInWithProvider = (provider) => {
    app.auth().signInWithPopup(provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        const {user} = result;
        console.log(user);
      }).catch((error) => {
        setShowAlert(true);
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const {email} = error.customData;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        console.log(error.code);
      });
  }

  const signInWithEmail = (email, password) => {
    app.auth().signInWithEmailAndPassword(email, password)
      .then((result) => {
        setShowAlert(false);
        const { user } = result;
        console.log(user);
      }).catch((error) => {
        setShowAlert(true);
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const {email} = error.customData;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        console.log(error.code);
      });
  }

  return(
    <div>
      {
        smallButton ? (
          <CustomButtonSmall hidden={show} variant="contained" onClick={handleClickOpen}>
            Login
          </CustomButtonSmall>
        ) : (
          <CustomButton hidden={show} variant="contained" onClick={handleClickOpen}>
            Login
          </CustomButton>
        )
      }
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: 'linear-gradient(#7fcbae, #00ff99)',
            textAlign: 'center'
          }
        }}
      >
        <LoginBox>
        <Collapse in={showAlert}>
          <Alert
            severity='error'
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Invalid credentials!
          </Alert>
        </Collapse>
          <IconButton
            onClick={handleClose}
            aria-label="close"
            style={{
              position: 'absolute',
              right: '0',
              color: '#FFFFFF'
            }}
          >
            <CloseIcon />
          </IconButton>
          {consumerView ? (
            <Typography className={classes.typography} variant='body1'>
              ACCOUNT LOGIN
            </Typography>
          ) : (
            <Typography className={classes.typography} variant='body1'>
              RETAILER LOGIN
            </Typography>
          )}
          <HorizontalLineSmall center />
          <br />
          <Typography variant='h4' className={classes.typography}>
            Welcome back
          </Typography>
          <br />
          <br />
          <CustomInput
            placeholder='E-mail'
            aria-label='E-mail'
            value={email}
            onChange={handleEmailChange}
          />
          <br />
          <CustomInput
            placeholder='Password'
            aria-label='Password'
            type='password'
            value={password}
            onChange={handlePasswordChange}
          />
          <br />
          <ForgotPassword />
          <br />
          <br />
          <CustomButton variant='contained' disabled={email === '' || password === ''} onClick={() => signInWithEmail(email, password)}>
            Login
          </CustomButton>
          <HorizontalLineBig hidden={!consumerView} />
          <SocialButton hidden={!consumerView} variant='contained' onClick={() => signInWithProvider(googleProvider)}>
            <GoogleIcon />
            Login with Google
          </SocialButton>
          <br />
          <br />
          <SocialButton hidden={!consumerView} variant='contained' onClick={() => signInWithProvider(facebookProvider)}>
            <FacebookIcon />
            Login with Facebook
          </SocialButton>
          <br />
          <br />
          <SocialButton hidden={!consumerView} variant='contained' onClick={() => signInWithProvider(appleProvider)}>
            <AppleIcon />
            Login with Apple
          </SocialButton>
        </LoginBox>
      </Dialog>
    </div>
  )
}

export default Login;
