import React, { forwardRef, useEffect, useState } from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { CustomButton, CustomInput, HorizontalLineSmall, LoginBox } from './index.styled';
import { Alert, Collapse, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { app } from '../firebase';
import Login from './Authentication/Login';

const useStyles = makeStyles({
  typography: {
    fontFamily: 'Gotham-Rounded-Medium !important'
  },
  typographyUnderlined: {
    fontFamily: 'Gotham-Rounded-Light !important',
    textDecoration: 'underline',
    "&:hover": {
      cursor: 'help'
    }
  }
});

const Transition = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

export const ForgotPassword = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('')
  const [alert, setAlert] = useState(<span />);
  const [showAlert, setShowAlert] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    showAlert ? setAlert(
      <Collapse in={showAlert}>
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    ) : setAlert(
      <span />
    )
  }, [showAlert, message, severity]);

  const resetPassword = () => {
    app.auth().sendPasswordResetEmail(email)
      .then(() => {
        setSeverity('success');
        setMessage('Email is sent. Check your email as you will be redirected to login with your new password!');
        setShowAlert(true);
        setTimeout(() => {
          setShowLogin(true);
        }, 5000);
      }).catch((error) => {
        setSeverity('error');
        setMessage(error.message);
        setShowAlert(true);
      });

  }

  return (
    <div>
      <Typography onClick={() => handleClickOpen()} variant='caption' className={classes.typographyUnderlined}>
        Forgot your password?
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: 'linear-gradient(#7fcbae, #00ff99)',
            textAlign: 'center'
          }
        }}
      >
        <LoginBox>
          {alert}
          {
            (showLogin) ? (
              <Login show={showLogin} />
            ) : (
              <span />
            )
          }
          <IconButton
            onClick={handleClose}
            aria-label="close"
            style={{
              position: 'absolute',
              right: '0',
              color: '#FFFFFF'
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.typography} variant='h4'>
            Forgot your password?
          </Typography>
          <HorizontalLineSmall center />
          <br />
          <Typography variant='body1' className={classes.typography}>
            No worries
          </Typography>
          <br />
          <br />
          <CustomInput
            placeholder='E-mail'
            aria-label='E-mail'
            value={email}
            onChange={handleEmailChange}
          />
          <br />
          <br />
          <br />
          <CustomButton variant='contained' onClick={() => resetPassword()}>
            Send instructions
          </CustomButton>
        </LoginBox>
      </Dialog>
    </div>
  )
}

export default ForgotPassword;
