import React, { useState } from 'react';
import Scroll from 'react-scroll';
import {
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { BenefitsContainer, BenefitsContext, CustomButton, CustomInput, HorizontalLineSmall } from '../../index.styled';
import Box from '@mui/material/Box';
import { Apple, Facebook } from '@material-ui/icons';
import { Google } from '@mui/icons-material';
import { consumerSignUp } from '../../../services/userService';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { app } from '../../../firebase';
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';

const Element  = Scroll.Element;

const useStyles = makeStyles((theme) => ({
  typographyh4: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#3B3D40',
    textAlign: 'center',
    marginTop: '50px',
  },
  typographyh6: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#3B3D40',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginTop: '50px',
  },
  typographyBody: {
    fontFamily: 'Gotham-Rounded-Light !important',
    color: '#3B3D40',
    textAlign: 'center',
    marginTop: '30px',
  },
  first: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '50%',
    margin: 'auto',
  },
  second: {
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  firstMobile: {
    justifyContent: 'space-between',
    marginBottom: '20px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  secondMobile: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const SignUp = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [accept, setAccept] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSurname = (e) => {
    setSurname(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleAccept = (e) => {
    setAccept(e.target.checked);
  };

  const clearFields = () => {
    setName('');
    setSurname('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setAccept(false);
  }

  const signInWithProvider = (provider) => {
    app.auth().signInWithPopup(provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        const {user} = result;
        console.log(user);
      }).catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const {email} = error.customData;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        console.log(error);
      });
  }

  const handleSignUp = () => {
    consumerSignUp({
      data: {
        firstName: name,
        lastName: surname,
        email,
        password,
        isSocial: false,
      }
    }).then(() => {
      setAlertMessage('Registration successful. You can now log in.');
      setAlertSeverity('success');
      setShowAlert(true);
      clearFields();
    }).catch((err) => {
      setAlertMessage('Something went wrong...');
      setAlertSeverity('error');
      setShowAlert(true);
    });
  };

  return (
    <Element name="consumer-sign-up" style={{ paddingBottom: '50px' }}>
      <BenefitsContainer>
        <BenefitsContext>
          <Typography variant='h6' className={classes.typographyh6}>
            SIGN UP TODAY
          </Typography>
          <HorizontalLineSmall center color='#000000' />
          <Typography variant='h4' className={classes.typographyh4}>
            Receive loyalty offers from your favourite retailers. Store and organize all your receipts in one place, it couldn't be easier.
          </Typography>
          <Typography variant='body1' className={classes.typographyBody}>
            Download the app and sign up for free.
          </Typography>
          <Collapse in={showAlert} style={{ width: '40%', margin: 'auto', marginTop: '20px' }}>
            <Alert
              severity={alertSeverity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {alertMessage}
            </Alert>
          </Collapse>
          <Box sx={{ width: '100%', marginTop: '40px' }}>
            <div className={isMobile ? classes.firstMobile : classes.first}>
              <CustomInput onChange={handleName} value={name} width='47%' placeholder='Name*' style={{ flexGrow: '1', margin: '5px' }} />
              <CustomInput onChange={handleSurname} value={surname} width='47%' placeholder='Surname*' style={{ flexGrow: '1', margin: '5px' }} />
              <CustomInput onChange={handleEmail} value={email} width='47%' placeholder='Email*' style={{ flexGrow: '1', margin: '5px' }} />
              <CustomInput onChange={handlePassword} value={password} type='password' width='47%' placeholder='Password*' style={{ flexGrow: '1', margin: '5px' }} />
              <CustomInput onChange={handleConfirmPassword} value={confirmPassword} type='password' width='47%' placeholder='Confirm Password*' style={{ flexGrow: '1', margin: '5px' }} />
              <Typography style={{ width: '47%', flexGrow: '1', margin: '5px' }} variant='body1' className={classes.typographyBody}>
               or sign in with:
               <Facebook style={{ cursor: 'pointer', margin: '5px' }} onClick={() => signInWithProvider(new FacebookAuthProvider())} />
               <Google style={{ cursor: 'pointer', margin: '5px' }} onClick={() => signInWithProvider(new GoogleAuthProvider())} />
               <Apple style={{ cursor: 'pointer', margin: '5px' }} onClick={() => signInWithProvider(new OAuthProvider("apple.com"))} />
              </Typography>
            </div>
            <br />
            <br />
            <div className={isMobile ? classes.secondMobile : classes.second}>
              <input type="checkbox" checked={accept} onChange={handleAccept} />
              <Typography variant='caption' className={classes.typographyBody}>
                <label for="vehicle1">&nbsp; &nbsp; I accept My-POP terms & condtions</label>
              </Typography>
              <br />
              <br />
              <CustomButton disabled={
                name === ''
                || surname === ''
                || email === ''
                || password === ''
                || password !== confirmPassword
                || !accept
              }
              onClick={() => handleSignUp()}
              >
                Sign up
              </CustomButton>
            </div>
          </Box>
        </BenefitsContext>
      </BenefitsContainer>
  </Element>
  )
}

export default SignUp;
