/* eslint-disable no-restricted-globals */
import { Typography } from '@material-ui/core';
import React from 'react';
import { CustomButton } from '../index.styled';

const TermsAndConditions = () => {
  const goBack = () => {
    history.back();
  };

  return (
    <div style={{ padding: '25px', width: '85%' }}>
      <Typography variant="h4">
        Terms and Conditions of www.my-pop.com
      </Typography>
      <br />
      <Typography>
        The following terms and conditions (collectively, these "Terms and Conditions") apply to your use of www.my-pop.co.uk,
        www.my-pop.com, including any content, functionality and services offered on or via www.my-pop.co.uk,
        www.my-pop.com (the "Website").
      </Typography>
      <br />
      <Typography>
        My-pop is a domain and as a brand operated and owned by My-Digital Ltd
      </Typography>
      <br />
      <Typography>
        Please read the Terms and Conditions carefully before you start using My-POP, because by using the Website you accept and agree to be bound and abide by these Terms and Conditions.
      </Typography>
      <br />
      <Typography>
        These Terms and Conditions are effective as of March 16, 2018. We expressly reserve the right to change these Terms and Conditions from time to time without notice to you. You acknowledge and agree that it is your responsibility to review this Website and these Terms and Conditions from time to time and to familiarize yourself with any modifications. Your continued use of this Website after such modifications will constitute acknowledgement of the modified Terms and Conditions and agreement to abide and be bound by the modified Terms and Conditions.
      </Typography>
      <br />
      <Typography variant="h5">
        Conduct on Website
      </Typography>
      <br />
      <Typography>
        Your use of the Website is subject to all applicable laws and regulations, and you are solely responsible for the substance of your communications through the Website.
      </Typography>
      <br />
      <Typography>
        By posting information in or otherwise using any communications service, chat room, message board, newsgroup, software library, or other interactive service that may be available to you on or through this Website, you agree that you will not upload, share, post, or otherwise distribute or facilitate distribution of any content — including text, communications, software, images, sounds, data, or other information — that:
      </Typography>
      <br />
      <ul>
        <li>
          <Typography>
            Is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy, tortious, contains explicit or graphic descriptions or accounts of sexual acts (including but not limited to sexual language of a violent or threatening nature directed at another individual or group of individuals), or otherwise violates our rules or policies
          </Typography>
        </li>
        <li>
          <Typography>
            Victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability
          </Typography>
        </li>
        <li>
          <Typography>
            Infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any party
          </Typography>
        </li>
        <li>
          <Typography>
            Constitutes unauthorized or unsolicited advertising, junk or bulk email (also known as "spamming"), chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling
          </Typography>
        </li>
        <li>
          <Typography>
            Contains software viruses or any other computer code, files, or programs that are designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of any third party
          </Typography>
        </li>
        <li>
          <Typography>
            Impersonates any person or entity, including any of our employees or representatives
          </Typography>
        </li>
      </ul>
      <br />
      <Typography>
        You agree that we may at any time, and at our sole discretion, terminate your membership, account, or other affiliation with our site without prior notice to you for violating any of the above provisions.
      </Typography>
      <br />
      <Typography>
        In addition, you acknowledge that we will cooperate fully with investigations of violations of systems or network security at other sites, including cooperating with law enforcement authorities in investigating suspected criminal violations.
      </Typography>
      <br />
      <Typography variant="h5">
        Intellectual property
      </Typography>
      <br />
      <Typography>
        By accepting these Terms and Conditions, you acknowledge and agree that all content presented to you on this Website is protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws, and is the sole property of My-POP.
      </Typography>
      <br />
      <Typography>
        You are only permitted to use the content as expressly authorized by us or the specific content provider. Except for a single copy made for personal use only, you may not copy, reproduce, modify, republish, upload, post, transmit, or distribute any documents or information from this Website in any form or by any means without prior written permission from us or the specific content provider, and you are solely responsible for obtaining permission before reusing any copyrighted material that is available on this Website.
      </Typography>
      <br />
      <Typography variant="h5">
        Third Party Websites
      </Typography>
      <br />
      <Typography>
        This Website may link you to other sites on the Internet or otherwise include references to information, documents, software, materials and/or services provided by other parties. These websites may contain information or material that some people may find inappropriate or offensive.
      </Typography>
      <br />
      <Typography>
        These other websites and parties are not under our control, and you acknowledge that we are not responsible for the accuracy, copyright compliance, legality, decency, or any other aspect of the content of such sites, nor are we responsible for errors or omissions in any references to other parties or their products and services. The inclusion of such a link or reference is provided merely as a convenience and does not imply endorsement of, or association with, the Website or party by us, or any warranty of any kind, either express or implied.  
      </Typography>
      <br />
      <Typography variant="h5">
        Disclaimer of Warranties, Limitations of Liability and Indemnification
      </Typography>
      <br />
      <Typography>
        Your use of My-POP is at your sole risk. The Website is provided "as is" and "as available". We disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement.
      </Typography>
      <br />
      <Typography>
        We are not liable for damages, direct or consequential, resulting from your use of the Website, and you agree to defend, indemnify and hold us harmless from any claims, losses, liability costs and expenses (including but not limites to attorney's fees) arising from your violation of any third-party's rights. You acknowledge that you have only a limited, non-exclusive, nontransferable license to use the Website. Because the Website is not error or bug free, you agree that you will use it carefully and avoid using it ways which might result in any loss of your or any third party's property or information.
      </Typography>
      <br />
      <Typography variant="h5">
       Term and termination
      </Typography>
      <br />
      <Typography>
        This Terms and Conditions will become effective in relation to you when you create a My-POP account or when you start using the My-POP and will remain effective until terminated by you or by us.
      </Typography>
      <br />
      <Typography>
        My-POP reserves the right to terminate this Terms and Conditions or suspend your account at any time in case of unauthorized, or suspected unauthorized use of the Website whether in contravention of this Terms and Conditions or otherwise. If My-POP terminates this Terms and Conditions, or suspends your  account for any of the reasons set out in this section, My-POP shall have no liability or responsibility to you.
      </Typography>
      <br />
      <Typography>
        This Terms and Conditions was created by termsconditionstemplate.net for www.my-pop.co.uk, www.my-pop.com
      </Typography>
      <br />
      <Typography variant="h5">
        Assignment
      </Typography>
      <br />
      <Typography>
        My-POP may assign this Terms and Conditions or any part of it without restrictions. You may not assign this Terms and Conditions or any part of it to any third party.
      </Typography>
      <br />
      <Typography variant="h5">
        Governing law
      </Typography>
      <br />
      <Typography>
        These Terms and Conditions and any dispute or claim arising out of, or related to them, shall be governed by and construed in accordance with the internal laws of the England and Wales without giving effect to any choice or conflict of law provision or rule.
      </Typography>
      <br />
      <Typography>
        Any legal suit, action or proceeding arising out of, or related to, these Terms of Service or the Website shall be instituted exclusively in the federal courts of England and Wales.
      </Typography>
      <br />
      <Typography variant="h5">
        Website Disclaimer of www.my-pop.co.uk, www.my-pop.com 
      </Typography>
      <br />
      <Typography>
        Thank you for visiting the My-POP.
      </Typography>
      <br />
      <Typography>
        Please note that the information provided on www.my-pop.co.uk, www.my-pop.com and other related web properties are for informational purposes only.
      </Typography>
      <br />
      <Typography>
        My-pop is a domain and as a brand operated and owned by My-Digital Ltd
      </Typography>
      <br />
      <Typography>
        The information is provided by www.my-pop.co.uk, www.my-pop.com and whilst we endeavor to keep the information up-to-date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
      </Typography>
      <br />
      <Typography>
        In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of or in connection with the use of this website.
      </Typography>
      <br />
      <Typography>
        Through this website you are able to link to other websites which are not under the control of www.my-pop.co.uk, www.my-pop.com. We have no control over the nature, content and availability of those websites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
      </Typography>
      <br />
      <Typography>
        Every effort is made to keep the website up and running smoothly. However, My-POP takes no responsibility for and will not be liable for the website being temporarily unavailable due to technical issues beyond our control.
      </Typography>
      <br />
      <Typography>
        Our Website is provided by My-POP on an "AS IS" basis.
      </Typography>
      <br />
      <Typography>
        This Website Disclaimer was created by disclaimer-template.com for www.my-pop.co.uk, www.my-pop.com
      </Typography>
      <br />
      <Typography>
        My-POP makes no representations or warranties of any kind, express or implied as to the operation of the site, the information, content, materials or products included on the site.
      </Typography>
      <br />
      <Typography>
        To the full extent permissible by applicable law, My-POP disclaims all warranties, express or implied, including, but not limited to, the implied warranties and/or conditions of merchantability or satisfactory quality and fitness for a particular purpose and non-infringement.
      </Typography>
      <br />
      <Typography>
        My-POP will not be liable for any damages of any kind arising from the use of the site, including, but not limited to direct, indirect, incidental, punitive and consequential damages.
      </Typography>
      <br />
      <Typography>
        My-POP does not warrant that the site, or the server that makes it available, is free of viruses or other harmful components.
      </Typography>
      <br />
      <Typography variant="h5">
        Terms of Use of www.my-pop.co.uk , www.my-pop.com
      </Typography>
      <br />
      <Typography>
        Welcome to the My-PoP  website (the "Website").
      </Typography>
      <br />
      <Typography>
        My-pop is a domain and as a brand operated and owned by My-Digital Ltd.
      </Typography>
      <br />
      <Typography>
        My-PoP provides this Website and Services (located at www.my-pop.co.uk , www.my-pop.com) to you subject to the notices, terms, and conditions set forth in these terms (the "Terms of Use"). In addition, when you use any of our Services, you will be subject to the rules, guidelines, policies, terms, and conditions applicable to such service, and they are incorporated into this Terms of Use by this reference.
      </Typography>
      <br />
      <Typography>
        These Terms of Use are effective as of March 16, 2018.
      </Typography>
      <br />
      <Typography>
        Your eligibility for use of the Website is contingent upon meeting the following conditions:
      </Typography>
      <br />
      <ul>
        <li>
          <Typography>
            You are at least 18 years of age 
          </Typography>
        </li>
        <li>
          <Typography>
            You use the Website and Services according to these Terms of Use and all applicable laws and regulations determined by the state and country of residence
          </Typography>
        </li>
        <li>
          <Typography>
            You provide complete and accurate registration information and maintain accurate registration information on the Webite
          </Typography>
        </li>
        <li>
          <Typography>
            You agree and understand that My-PoP may, at any time, and without prior notice, revoke and/or cancel your access if you fail to meet these criteria or violate any portion of these Terms of Use
          </Typography>
        </li>
      </ul>
      <br />
      <Typography variant="h5">
        Use of this Website
      </Typography>
      <br />
      <Typography>
        In connection with your use of our Website, you must act responsibly and exercise good judgment. Without limiting the foregoing, you will not:
      </Typography>
      <br />
      <ul>
        <li>
          <Typography>
            Violate any local, state, provincial, national, or other law or regulation, or any order of a court
          </Typography>
        </li>
        <li>
          <Typography>
            Infringe the rights of any person or entity, including without limitation, their intellectual property, privacy, publicity or contractual rights
          </Typography>
        </li>
        <li>
          <Typography>
            Interfere with or damage our Services, including, without limitation, through the use of viruses, cancel bots, Trojan horses, harmful code, flood pings, denial-of-service attacks, packet or IP spoofing, forged routing or electronic mail address information or similar methods or technology
          </Typography>
        </li>
        <li>
          <Typography>
            Use automated scripts to collect information or otherwise interact with the Services or the Website
          </Typography>
        </li>
        <li>
          <Typography>
            Enter into this agreement on behalf of another person or entity without consent or the legal capacity to make such agreements as a representative of an organization or entity
          </Typography>
        </li>
      </ul>
      <br />
      <Typography variant="h5">
        Intellectual property
      </Typography>
      <br />
      <Typography>
        All code, text, software, scripts, graphics, files, photos, images, logos, and materials contained on this Website, or within the Services, are the sole property of My-PoP.
      </Typography>
      <br />
      <Typography>
        Unauthorized use of any materials contained on this Website or within the Service may violate copyright laws, trademark laws, the laws of privacy and publicity, and/or other regulations and statutes. If you believe that any of the materials infringe on any third party's rights, please contact My-PoP immediately at the address provided below.
      </Typography>
      <br />
      <Typography variant="h5">
        Third Party Websites
      </Typography>
      <br />
      <Typography>
        Our Website may link you to other sites on the Internet or otherwise include references to information, documents, software, materials and/or services provided by other parties. These websites may contain information or material that some people may find inappropriate or offensive.
      </Typography>
      <br />
      <Typography>
        These other websites and parties are not under our control, and you acknowledge that we are not responsible for the accuracy, copyright compliance, legality, decency, or any other aspect of the content of such sites, nor are we responsible for errors or omissions in any references to other parties or their products and services. The inclusion of such a link or reference is provided merely as a convenience and does not imply endorsement of, or association with, the Website or party by us, or any warranty of any kind, either express or implied.
      </Typography>
      <br />
      <Typography variant="h5">
        Disclaimer of Warranty and Limitation of Liability
      </Typography>
      <br />
      <Typography>
        The Website is provided "AS IS." appfigures, its suppliers, officers, directors, employees, and agents exclude and disclaim all representations and warranties, express or implied, related to this Website or in connection with the Services. You exclude My-PoP from all liability for damages related to or arising out of the use of this Website.
      </Typography>
      <br />
      <Typography variant="h5">
        Changes to these Terms of Use
      </Typography>
      <br />
      <Typography>
        My-PoP retains the right to, at any time, modify or discontinue, any or all parts of the Website without notice.
      </Typography>
      <br />
      <Typography>
        This Terms of Use was created by termsusetemplate.com for www.my-pop.co.uk , www.my-pop.com
      </Typography>
      <br />
      <Typography>
        Additionally, My-PoP reserves the right, in its sole discretion, to modify these Terms of Use at any time, effective by posting new terms on the Website with the date of modification. You are responsible for reading and understanding the terms of this agreement prior to registering with, or using the Service. Your use of the Website and/or Services after any such modification has been published constitutes your acceptance of the new terms as modified in these Terms of Use.
      </Typography>
      <br />
      <Typography variant="h5">
        Governing Law
      </Typography>
      <br />
      <Typography>
        These Terms of Use and any dispute or claim arising out of, or related to them, shall be governed by and construed in accordance with the internal laws of the England and Wales without giving effect to any choice or conflict of law provision or rule.
      </Typography>
      <br />
      <Typography>
        Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Website shall be instituted exclusively in the federal courts of England and Wales.
      </Typography>
      <br />
      <br />
      <CustomButton onClick={() => goBack()}>
        Back
      </CustomButton>
    </div>
  )
}

export default TermsAndConditions