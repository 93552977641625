import React from 'react';
import {
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CustomButton, HorizontalLineSmall, VisitOurConsumerSiteContainer, VisitOurConsumerSiteContext } from '../index.styled';
import { Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import appStore from '../../images/AppStore.png';
import playStore from '../../images/PlayStore.png';

const useStyles = makeStyles({
  typography: {
    fontFamily: 'Gotham-Rounded-Medium !important'
  },
  typographyBold: {
    fontFamily: 'Gotham-Rounded-Bold !important',
  },
  store: {
    width: '200px',
    '&:hover': {
      cursor: 'pointer',
    },
  }
});

export const VisitOurConsumerSite = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  return (
    <VisitOurConsumerSiteContainer isMobile={isMobile}>
      <VisitOurConsumerSiteContext isMobile={isMobile}>
        <Typography variant='body1' className={classes.typographyBold}>
          CONSUMERS
        </Typography>
        <HorizontalLineSmall center />
        <Typography variant='h4' className={classes.typography}>
          Visit our consumer site to see how it can benefit your customers
        </Typography>
        <br />
        <br />
        <CustomButton onClick={() => window.location.href='/consumer'}>
          Visit site
        </CustomButton>
        <br />
        <br />
        <br />
        <br />
        <Typography variant='caption' className={classes.typography}>
          AVAILABLE ON BOTH
        </Typography>
        <br />
        <br />
        <div>
          <a href='https://apps.apple.com/gb/app/my-pop/id6446941679' rel="noreferrer"  target='_blank'><img className={classes.store} src={appStore} alt='appstore' /></a>
          <a href='https://play.google.com/store/apps/details?id=com.tech.mypopapp&gl=GB' rel="noreferrer"  target='_blank'><img className={classes.store} src={playStore} alt='playstore' /></a>
        </div>
      </VisitOurConsumerSiteContext>
    </VisitOurConsumerSiteContainer>
  )
}

export default VisitOurConsumerSite;

