import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import H2Title from './index.styled';

const TitleWrapper = styled.div`
    text-align: center;
    padding-top: 20px;
    margin-bottom: 50px;
    font-weight: 500;
    color: ${({ $color = '#FFFFFF' }) => $color};
`;

const SectionTitle = ({ children, color }) => {
  return (
      <TitleWrapper $color={color}>
          <H2Title>{children}</H2Title>
      </TitleWrapper>
  )
}

SectionTitle.propTypes = {
    color: PropTypes.string,
}

SectionTitle.defaultProps = {
    color: '#FFFFFF',
};

export default SectionTitle;
