import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware, connectRouter } from "connected-react-router";
import thunk from "redux-thunk";
import usersReducer from "./users/users";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const history = createBrowserHistory();

const rootReducer = (history) => ({
  users: persistReducer(
    {
      key: "users",
      storage,
    },
    usersReducer
  ),
  router: connectRouter(history),
});

const preloadedState = {};
const store = configureStore({
  middleware: [thunk, routerMiddleware(history)],
  reducer: rootReducer(history),
  preloadedState,
});

export default store;
