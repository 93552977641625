import React from 'react';
import { Line, DualAxes } from '@ant-design/plots';

export const TransactionsReviewGraph = (props) => {
  const data = props.data
  const config = {
    data: [data, data],
    xField: 'period',
    yField: ['transactions', 'value'],
    yAxis: {
      value: {
        min: 0,
        label: {
          formatter: (val) => `${val}个`,
        },
      },
      count: false,
    },
    geometryOptions: [
      {
        geometry: 'column',
        color: '#426A6D',
        columnWidthRatio: 0.4,
        label: {
          position: 'middle',
        },
      },
      {
        geometry: 'line',
        smooth: true,
        color: '#5AD8A6',
      },
    ],
    interactions: [
      {
        type: 'element-highlight',
      },
      {
        type: 'active-region',
      },
    ],
    annotations: {
      count: [
        {
          type: 'dataMarker',
          top: true,
          line: {
            length: 20,
          },
        },
      ],
    },
  };
  return <DualAxes {...config} />;
};

export const LineGraphValueOfOffersRedemption = (props) => {
  const data = props.data;
  const config = {
    data,
    width: 300,
    xField: 'month',
    yField: 'value_of_offers',
    color: '#426A6D',
    point: {
      size: 5,
      style: {
        fill: '#A0D2BB',
        stroke: '#426A6D',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };
  return <Line {...config} />
};

export const LineGraphValueOfReceipts = (props) => {
  const data = props.data;
  const config = {
    data,
    width: 300,
    xField: 'month',
    yField: 'value_of_receipt',
    color: '#426A6D',
    point: {
      size: 5,
      style: {
        fill: '#A0D2BB',
        stroke: '#426A6D',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };
  return <Line {...config} />
};

export const LineGraphRedeemedOffers = (props) => {
  const data = props.data;
  const config = {
    data,
    width: 300,
    xField: 'month',
    yField: 'redemptions',
    color: '#426A6D',
    point: {
      size: 5,
      style: {
        fill: '#A0D2BB',
        stroke: '#426A6D',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };
  return <Line {...config} />
};

export const VerticalBarOffersRolledOut = (props) => {
  const data = props.data
  const config = {
    data: [data, data],
    xField: 'period',
    yField: ['offers', 'redemptions'],
    yAxis: {
      value: {
        min: 0,
        label: {
          formatter: (val) => `${val}个`,
        },
      },
      count: false,
    },
    geometryOptions: [
      {
        geometry: 'column',
        color: '#426A6D',
        columnWidthRatio: 0.4,
        label: {
          position: 'middle',
        },
      },
      {
        geometry: 'line',
        smooth: true,
        color: '#5AD8A6',
      },
    ],
    interactions: [
      {
        type: 'element-highlight',
      },
      {
        type: 'active-region',
      },
    ],
    annotations: {
      count: [
        {
          type: 'dataMarker',
          top: true,
          line: {
            length: 20,
          },
        },
      ],
    },
  };
  return <DualAxes {...config} />;
};
