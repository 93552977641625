import axios from 'axios';
import { URLS } from '../constants';

const BASE_URL = `${URLS.BASE_ENDPOINT_URL}/stores`;

export const getStoresByRetailerId = (retailerId, token) => axios.get(`${BASE_URL}/getByRetailerId/${retailerId}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getStoresForManager = (manager_id, token) => axios.get(`${BASE_URL}/getStoresForManager/${manager_id}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getStoreTransactions = (storeId, token) => axios.get(`${BASE_URL}/getTransactions/${storeId}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getStoreManagerTransactions = (managerId, token) => axios.get(`${BASE_URL}/getTransactionForManager/${managerId}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getStoreManagerOffers = (managerId, token) => axios.get(`${BASE_URL}/getOffersForManager/${managerId}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const createStore = ({ data, token }) => axios.post(`${BASE_URL}/create`, data, { headers: {
    authorization: `Bearer ${token}`
}});
export const getStoreById = (storeId, token) => axios.get(`${BASE_URL}/getStore/${storeId}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const updateStore = ({ data, token }) => axios.post(`${BASE_URL}/update`, data, { headers: {
    authorization: `Bearer ${token}`
}});
export const deleteStore = (id, token) => axios.delete(`${BASE_URL}/delete/${id}`, { headers: {
    authorization: `Bearer ${token}`
}});
