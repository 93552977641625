/* eslint-disable no-restricted-globals */
import { Typography } from '@material-ui/core';
import React from 'react';
import { CustomButton } from '../index.styled';

const CookiesPolicy = () => {
  const goBack = () => {
    history.back();
  };

  return (
    <div style={{ padding: '25px', width: '85%' }}>
      <Typography variant="h4">
        Cookies Policy of www.my-pop.com
      </Typography>
      <br />
      <Typography>
        Last updated: 25/04/2023
      </Typography>
      <br />
      <Typography variant='h5'>
        Introduction
      </Typography>
      <br />
      <Typography>
        Welcome to MY-POP's Cookie Policy. This policy is meant to provide you with information on the types of cookies we use on our website and app, how we use them, and how you can manage your cookie preferences. By using our website and app, you agree to our use of cookies in accordance with this policy.
      </Typography>
      <br />
      <Typography variant='h5'>
        What are cookies?
      </Typography>
      <br />
      <Typography>
        Cookies are small text files that are placed on your device (computer, smartphone, or tablet) when you visit a website or use an app. They help to recognize your device and store certain information, such as your preferences or past actions. Cookies can be either "session" or "persistent" cookies. Session cookies are temporary and expire once you close your browser, while persistent cookies remain on your device until they are deleted or until they reach their expiration date.
      </Typography>
      <br />
      <Typography variant='h5'>
        How MY-POP uses cookies
      </Typography>
      <br />
      <ol>
        <li>
          <Typography>
            Essential cookies: These cookies are necessary for the proper functioning of our website and app. They enable you to create an account, log in, and access your digital receipts and retailer offers. Without these cookies, our services cannot be provided.
          </Typography>
        </li>
        <li>
          <Typography>
            Performance and analytics cookies: These cookies help us understand how users interact with our website and app by collecting information on usage patterns, errors, and overall user experience. This information helps us improve our services and develop new features.
          </Typography>
        </li>
        <li>
          <Typography>
            Functional cookies: These cookies remember your preferences, such as language, region, or retailer preferences, and enhance your experience on our website and app.
          </Typography>
        </li>
        <li>
          <Typography>
            Advertising and targeting cookies: These cookies are used to deliver relevant offers and promotions from your favorite retailers. They may be placed by us or by third-party advertisers with our permission.
          </Typography>
        </li>
      </ol>
      <br />
      <Typography variant='h5'>
        Managing your cookie preferences
      </Typography>
      <br />
      <Typography>
        You can manage your cookie preferences by adjusting your browser settings. Most browsers allow you to block, delete, or disable cookies, although doing so may limit the functionality of our website and app. For more information on how to manage cookies in your specific browser, please refer to its help documentation or settings.
      </Typography>
      <br />
      <Typography variant="h5">
        Changes to this Cookie Policy
      </Typography>
      <br />
      <Typography>
        We may update this Cookie Policy from time to time to reflect changes in our practices or applicable laws. Any changes we make will be posted on this page, so please check back regularly to stay informed of any updates.
      </Typography>
      <br />
      <Typography variant='h5'>
        Contact us
      </Typography>
      <br />
      <Typography>
        If you have any questions or concerns about our use of cookies, please contact us at:
      </Typography>
      <br />
      <Typography>
        My-POP
      </Typography>
      <br />
      <Typography>
        68, Llandaff Rd
      </Typography>
      <br />
      <Typography>
        Cardiff
      </Typography>
      <br />
      <Typography>
        CF11 9NL
      </Typography>
      <br />
      <br />
      <CustomButton onClick={() => goBack()}>
        Back
      </CustomButton>
    </div>
  )
}

export default CookiesPolicy;