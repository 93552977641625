const SET_CURRENT_USER = 'SET_CURRENT_USER';
const SET_RETAILER_TRANSACTIONS = 'SET_RETAILER_TRANSACTION';

const initialState = {
  currentUser: {},
  stores: [],
  transactions: []
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload.currentUser
      };
    case SET_RETAILER_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload.transactions
      }
    default:
      return state;
  }
};

export const setRetailerTransactions = (transactions) => ({
  type: SET_RETAILER_TRANSACTIONS,
  payload: {
    transactions
  },
});

export const setCurrentUser = (currentUser) => ({
  type: SET_CURRENT_USER,
  payload: {
    currentUser
  },
});

export default usersReducer;
