import axios from "axios";
import { URLS } from "../constants";

const BASE_URL = `${URLS.BASE_ENDPOINT_URL}/transactions`;

export const getInvoice = (transaction, token) =>
  axios.get(`${BASE_URL}/getInvoice/${transaction}`, {
    responseType: "blob",
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
export const createTransaction = ({ data, token }) =>
  axios.post(`${BASE_URL}/create`, data, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
export const changeType = (id, token) =>
  axios.put(`${BASE_URL}/changeType/${id}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
export const saveFromReceipt = ({ data, token }) =>
  axios.post(`${BASE_URL}/getFromPicture`, data, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
export const getFilteredTransactions = (searchTerm, myPopId, token) =>
  axios.get(`${BASE_URL}/getFiltered/${myPopId}/${searchTerm}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
export const deleteTransaction = (transactionId, myPopId, token) =>
  axios.delete(
    `${BASE_URL}/delete?transactionId=${transactionId}&myPopId=${myPopId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
export const getTransactionById = (id) =>
  axios.get(`${BASE_URL}/getById/${id}`);
