import axios from "axios";
import { URLS } from "../constants";

const BASE_URL = `${URLS.BASE_ENDPOINT_URL}/retailers`;

export const retailerAdminSignUp = ({ data }) =>
  axios.post(`${BASE_URL}/create-admin`, data);
export const addRetailerUser = ({ data, token }) =>
  axios.post(`${BASE_URL}/create-user`, data, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
export const getRetailerTransactions = (retailer_id, token) =>
  axios.get(`${BASE_URL}/getTransactions/${retailer_id}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
export const getRetailerOffers = (retailer_id, token) =>
  axios.get(`${BASE_URL}/getOffers/${retailer_id}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
export const getTransactionsPerMonthData = (retailer_id, month, token) =>
  axios.get(`${BASE_URL}/getTransactionsPerMonth/${retailer_id}/${month}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
export const getValueOfTransactionsPerMonthData = (retailer_id, month, token) =>
  axios.get(
    `${BASE_URL}/getValueOfTransactionsPerMonth/${retailer_id}/${month}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
export const getOffersPerMonthData = (retailer_id, month, token) =>
  axios.get(`${BASE_URL}/getOffersPerMonth/${retailer_id}/${month}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
export const getNumberOfRedeemedOffersPerMonth = (retailer_id, month, token) =>
  axios.get(
    `${BASE_URL}/getNumberOfRedeemedOffersPerMonth/${retailer_id}/${month}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
export const updateLogo = ({ data }) =>
  axios.post(`${BASE_URL}/update-logo`, data);
export const getRetailerById = (r_id, token) =>
  axios.get(`${BASE_URL}/getById/${r_id}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
export const getRetailerLogo = (r_id, token) =>
  axios.get(`${BASE_URL}/getLogo/${r_id}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
export const getRetailerTerms = (id) =>
  axios.get(`${BASE_URL}/getTermsAndConditions/${id}`);
