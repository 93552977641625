import axios from 'axios';
import { URLS } from '../constants';

const BASE_URL = `${URLS.BASE_ENDPOINT_URL}/users`;

export const getByEmail = (email, token) => axios.get(`${BASE_URL}/getByEmail/${email}`, { headers:  {
    authorization: `Bearer ${token}`
}});
export const getUsersByRetailerForTimePeriod = (retailer, months, token) => axios.get(`${BASE_URL}/usersForTimePeriod/${retailer}/${months}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getUsersByRetailers = (retailer, token) => axios.get(`${BASE_URL}/getUsersForRetailer/${retailer}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const consumerSignUp = ({ data }) => axios.post(`${BASE_URL}/consumerSignUp`, data);
export const getConsumerTransactions = (myPopId, token) => axios.get(`${BASE_URL}/getTransactions/${myPopId}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getConsumerOffers = (myPopId, token) => axios.get(`${BASE_URL}/getOffers/${myPopId}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const updateAddress = ({ data, token }) => axios.post(`${BASE_URL}/updateAddress`, data, { headers: {
    authorization: `Bearer ${token}`
}});
export const updateDob = ({ data, token }) => axios.post(`${BASE_URL}/updateDob`, data, { headers: {
    authorization: `Bearer ${token}`
}});
export const updatePhone = ({ data, token }) => axios.post(`${BASE_URL}/updatePhone`, data, { headers: {
    authorization: `Bearer ${token}`
}});
export const getRetailerUsers = (retailer_id, token) => axios.get(`${BASE_URL}/getRetailerUsers/${retailer_id}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getUserByMyPopId = (myPopId, token) => axios.get(`${BASE_URL}/getByMyPopId/${myPopId}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getAllUsers = (token) => axios.get(`${BASE_URL}/getAll`, { headers: {
    authorization: `Bearer ${token}`
}});
export const deleteUser = (data, token) => axios.post(`${BASE_URL}/deleteUser`, data, { headers: {
    authorization: `Bearer ${token}`
}})