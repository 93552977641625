import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  CustomGreenButton,
  CustomInput,
  CustomSelectSmall,
} from "../index.styled";
import { makeStyles } from "@material-ui/core/styles";
import "react-quill/dist/quill.snow.css";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { getStoreById } from "../../services/storeService";
import { getRetailerById } from "../../services/retailerService";
import { useSelector } from "react-redux";
import { createTransaction } from "../../services/transactionsService";
import { getUserByMyPopId } from "../../services/userService";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#B5B5B5",
    color: theme.palette.common.white,
    fontFamily: "Gotham-Rounded-Medium !important",
    textTransform: "uppercase",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gotham-Rounded-Medium !important",
    color: "#3B3D40",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  typography: {
    fontFamily: "Gotham-Rounded-Medium !important",
  },
  label: {
    fontFamily: "Gotham-Rounded-Book !important",
  },
});

const ReviewTransaction = ({
  selectedProducts,
  total,
  vat,
  store,
  myPopId,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [storeObj, setStoreObj] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [transactionCustomer, setTransactionCustomer] = useState({});

  const currentUser = useSelector((state) => state.users.currentUser);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleCardNumberChange = (e) => {
    setCardNumber(e.target.value);
  };

  const handleCardTypeChange = (e) => {
    setCardType(e.target.value);
  };

  useEffect(() => {
    const getCustomer = async (id) => {
      const customer = await getUserByMyPopId(id, currentUser.token);
      setTransactionCustomer(customer.data);
    };
    if (myPopId !== "") {
      getCustomer(myPopId);
    }
    if (store !== "" && typeof store === "string") {
      getStoreById(store, currentUser.token).then((response) => {
        const obj = response.data;
        getRetailerById(currentUser.retailer_id, currentUser.token).then(
          (res) => {
            obj.r_name = res.data.r_name;
            obj.r_vatNumber = res.data.r_vatNumber;
            obj.r_id = currentUser.retailer_id;
            obj.r_logo = res.data.r_logo;
            setStoreObj(obj);
          }
        );
      });
    }
  }, [store, currentUser.retailer_id, currentUser.token, myPopId]);

  const makeAuthCode = () => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 7; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const maskCardNumber = (str, mask, n = 4) => {
    return ("" + str).slice(0, -n).replace(/./g, mask) + ("" + str).slice(-n);
  };

  const handleCreateTransactionClick = async () => {
    let payment = {};
    if (paymentMethod === "card") {
      payment = {
        amount: total,
        authCode: makeAuthCode(),
        cardReference: maskCardNumber(cardNumber, "*"),
        cardType,
        date: new Date(),
        name: paymentMethod,
        terminalId: "xjgU5LwP1D3BVyXNdNFJ",
      };
    } else {
      payment = {
        amount: total,
        name: paymentMethod,
        change: 0,
      };
    }

    let tags = [
      {
        name: "personal",
      },
    ];

    let taxes = [
      {
        name: "vat",
        description: "vat",
        amount: vat,
      },
    ];
    await createTransaction({
      data: {
        products: selectedProducts,
        store: storeObj,
        customer: transactionCustomer.id ? transactionCustomer : null,
        payment,
        taxes,
        tags,
        totalAmount: total,
      },
      token: currentUser.token,
    }).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    });
  };

  return (
    <div>
      <CustomGreenButton
        disabled={selectedProducts.length === 0 || store === ""}
        onClick={handleClickOpen}
      >
        Next
      </CustomGreenButton>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography className={classes.typography}>
            Review transaction
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <Typography variant="body1" className={classes.typography}>
                Your products
              </Typography>
            </FormControl>
            <div className={classes.tableContainer}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ID</StyledTableCell>
                      <StyledTableCell align="right">Name</StyledTableCell>
                      <StyledTableCell align="right">Quantity</StyledTableCell>
                      <StyledTableCell align="right">Price</StyledTableCell>
                      <StyledTableCell align="right">VAT</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedProducts.map((row) => {
                      return (
                        <StyledTableRow key={row.uid}>
                          <StyledTableCell component="th" scope="row">
                            {row.id}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.quantity}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.price}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.vat}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <Typography variant="body1" className={classes.typography}>
                Subtotal: {(total - vat).toFixed(2)} GBP
              </Typography>
              <Typography variant="body1" className={classes.typography}>
                VAT: {vat.toFixed(2)} GBP
              </Typography>
              <Typography variant="body1" className={classes.typography}>
                Total: {total.toFixed(2)} GBP
              </Typography>
            </FormControl>
            <br />
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <Typography variant="body1" className={classes.typography}>
                Payment method:
              </Typography>
              <CustomSelectSmall
                onChange={handlePaymentMethodChange}
                value={paymentMethod}
                placeholder="Payment method*"
              >
                <option disabled defaultValue="">
                  Payment method *
                </option>
                <option value="card">Card</option>
                <option value="cash">Cash</option>
              </CustomSelectSmall>
              {paymentMethod === "card" ? (
                <>
                  <CustomInput
                    value={cardNumber}
                    onChange={handleCardNumberChange}
                    placeholder="Card number"
                  />
                  <CustomSelectSmall
                    value={cardType}
                    onChange={handleCardTypeChange}
                  >
                    <option disabled defaultValue>
                      Card type *
                    </option>
                    <option value="visa">VISA</option>
                    <option value="amex">American express</option>
                    <option value="mastercard">Mastercard</option>
                  </CustomSelectSmall>
                </>
              ) : (
                <span />
              )}
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <CustomGreenButton
            onClick={() => handleCreateTransactionClick()}
            disabled={paymentMethod === ""}
          >
            Create
          </CustomGreenButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReviewTransaction;
