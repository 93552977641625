import React from 'react';
import Scroll from 'react-scroll';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Slider from "react-slick";
import SectionTitle from '../Shared/SectionTitle';
import WomenImg from '../../images/Screen2men.png'
import WomenImgMobile from '../../images/Screen2mobile.png'
import { HorizontalLineSmall } from '../index.styled';

const GridWrapper = styled(Grid)`
  text-align: center;
  font-family: "gotham-rounded-medium";
  color: #FFFFFF;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const GridItem = styled(Grid)``;

const FeatureItem = styled.div`
  color: #FFFFFF;
  font-family: 'gotham-rounded-book';
  font-size: ${({ $isMobile = false }) => $isMobile ? 16 : 20}px;
  letter-spacing: -0.8px;
  text-align: ${({ $isMobile = false }) => $isMobile ? 'left' : 'center'};
  margin-bottom: ${({ $isMobile = false }) => $isMobile ? 30 : 30}px;
  font-weight: 400;

  h4 {
    font-family: 'gotham-rounded-medium';
    text-transform: uppercase;
    font-size: ${({ $isMobile = false }) => $isMobile ? 20 : 22}px;
    font-weight: 500;
  }

  hr { 
    margin-top: ${({ $isMobile = false }) => $isMobile ? 30 : 50}px;
  }
`;

const ImageWrapper = styled.div`
  img {
    max-height: ${({ $isMobile = false }) => $isMobile ? 'auto' : '800px'};
    max-width: 100%;
  }
`;

const Element  = Scroll.Element;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

export const Features = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Element name="features">
      <div style={{
        width: '100%',
        height: '100vh',
        background: 'linear-gradient(to bottom, #7fcbae, #00ff99)',
        padding: '0 10px',
      }}
      >
        
        <SectionTitle>
          Boost your business with smoother transactions <br />
          and more customer insight.
        </SectionTitle>

        {!isMobile ? (
          <GridWrapper container spacing={2}>
            <GridItem item xs={4}>
              <FeatureItem>
                <h4>Manage your receipts</h4>
                With My-POP you can <br /> view and manage all customer <br /> receipts in one place.
                <HorizontalLineSmall center={!isMobile} />
              </FeatureItem>
              <FeatureItem>
                <h4>Customer convenience</h4>
                It’s quick easy,<br /> no need to collect emails <br /> and no more paper!
                <HorizontalLineSmall center={!isMobile} />
              </FeatureItem>
              <FeatureItem>
                <h4>Data analytics</h4>
                Analyse your business <br /> trends and take action. <br /> It’s all about the data.
              </FeatureItem>
            </GridItem>
            <GridItem item xs={4}>
              <ImageWrapper>
                <img src={WomenImg} alt="Mobile" />
              </ImageWrapper>
            </GridItem>
            <GridItem item xs={4}>
              <FeatureItem>
                <h4>Greater insight</h4>
                Get in-depth analysis<br />about your customers’<br />spending habits.
                <HorizontalLineSmall center={!isMobile} />
              </FeatureItem>
              <FeatureItem>
                <h4>Reach your customers</h4>
                Bring customers special<br/> offers and promotions with <br/> targeted ‘in app’ marketing.
                <HorizontalLineSmall center={!isMobile} />
              </FeatureItem>
              <FeatureItem>
                <h4>Better after sales</h4>
                Manage returns and <br/> warranty without losing <br/>revenue.
              </FeatureItem>
            </GridItem>
          </GridWrapper>
        ) : (
          <GridWrapper container spacing={2}>
            <GridItem item xs={6}>
              <Slider {...settings}>
                <div>
                  <FeatureItem $isMobile={isMobile}>
                    <h4>Manage your receipts</h4>
                    With My-POP you can view and manage all customer receipts in one place.
                    <HorizontalLineSmall center={!isMobile} />
                  </FeatureItem>
                  <FeatureItem $isMobile={isMobile}>
                    <h4>Customer convenience</h4>
                    It’s quick easy, no need to collect emails and no more paper!
                    <HorizontalLineSmall center={!isMobile} />
                  </FeatureItem>
                  <FeatureItem $isMobile={isMobile}>
                    <h4>Data analytics</h4>
                    Analyse your business trends and take action. It’s all about the data.
                  </FeatureItem>
                </div>
                <div>
                  <FeatureItem $isMobile={isMobile}>
                    <h4>Greater insight</h4>
                    Get in-depth analysis about your customers’ spending habits.
                    <HorizontalLineSmall center={!isMobile} />
                  </FeatureItem>
                  <FeatureItem $isMobile={isMobile}>
                    <h4>Reach your customers</h4>
                    Bring customers special offers and promotions with targeted ‘in app’ marketing.
                    <HorizontalLineSmall center={!isMobile} />
                  </FeatureItem>
                  <FeatureItem $isMobile={isMobile}>
                    <h4>Better after sales</h4>
                    Manage returns and warranty without losing revenue.
                  </FeatureItem>
                </div>
              </Slider>
            </GridItem>
            <GridItem item xs={6}>
              <ImageWrapper>
                <img src={WomenImgMobile} alt="Mobile" />
              </ImageWrapper>
            </GridItem>
          </GridWrapper>
        )}
      </div>
    </Element>
  )
}

export default Features;
