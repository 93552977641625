/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { forwardRef, useState } from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CustomButton, HorizontalLineSmall, LoginBox } from '../../index.styled';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import inTouch from '../../../images/intouch.png';

const useStyles = makeStyles({
  typography: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    fontSize: '20px'
  },
  typographyUnderlined: {
    fontFamily: 'Gotham-Rounded-Light !important',
    textDecoration: 'underline',
  },
  content: {
    padding: '20px',
  }
});

const Transition = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

export const VisitPartners = () => {

  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <div>
      <CustomButton variant="contained" onClick={handleClickOpen}>
        Visit partners
      </CustomButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: 'linear-gradient(#7fcbae, #00ff99)',
            textAlign: 'center'
          }
        }}
      >
        <LoginBox>
          <IconButton
            onClick={handleClose}
            aria-label="close"
            style={{
              position: 'absolute',
              right: '0',
              color: '#FFFFFF'
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.typography} variant='body1'>
            MY-POP PARTNERS
          </Typography>
          <HorizontalLineSmall center />
          <br />
          <br />
          <div className={classes.content}>
            <div>
              <img src={inTouch} alt='logo' />
            </div>
            <div>
              <Typography variant='body' className={classes.typography}>
              “In-Touch is a consumer insights and ‘marketing partner’ platform powered by digital and mobile technology. 
                It offers DATA analytics and insights into brand performance in-store. Along with other digital value-add solutions.
                It’s a simple solution which can seamlessly process secure transactions and offer in-app and 
                in-store rewards plus deliver and collect in a variety of ways and environments.”
              </Typography>
              <br />
            </div>
          </div>
        </LoginBox>
      </Dialog>
    </div>
  )
}

export default VisitPartners;
