import React, { useState } from 'react'
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { LoginBox } from '../index.styled';
import logo from '../../images/logo_big.png'
import Scroll from 'react-scroll';
import { app } from '../../firebase';

const scroller = Scroll.scroller;
const retailerView = window.location.href.includes('/retailer-dashboard');

const useStyles = makeStyles(()=>({
  menuIcon:{
    position: 'absolute !important',
    right: '20px',
    color: '#FFFFFF !important'
  },
  icon:{
    position: 'absolute !important',
    right: '20px !important',
    top: '-40px !important',
    color: '#FFFFFF !important',
  },
  logo:{
    cursor: 'pointer',
    width: '82px',
    position: 'absolute',
    left: '20px',
    top: '-200px !important',
  },
  list:{
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
  },
  listItem:{
    textAlign: 'center',
  },
  link: {
    fontFamily: 'Gotham-Rounded-Book !important',
    color: '#FFFFFF',
    fontSize: '22px',
    textDecoration: 'none',
    letterSpacing: '5px',
    textTransform: 'lowercase'
  }
}));

export const RetailerDrawerComponent = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles();

  const signOut = () => {
    app.auth().signOut();
  };

  return (
    <>
      <Drawer
        open={openDrawer}
        anchor='right'
        onClose={() => setOpenDrawer(false)}
        direc
        PaperProps={{
          style: {
            background: 'linear-gradient(#7fcbae, #00ff99)',
            width: '100%',
            textAlign: 'center',
          }
        }}
      >
        <LoginBox>
          <List
            className={classes.list}
          >
            <img src={logo} alt="my-pop-logo" className={classes.logo} />
            <IconButton
              onClick={() => setOpenDrawer(false)}
              aria-label="close"
              className={classes.icon}
            >
              <CloseIcon />
            </IconButton>
            {
              (!retailerView) ? (
                <>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link to="/" onClick={() => scroller.scrollTo('home', {
                        duration: 150,
                        delay: 100,
                        smooth: true,
                        offset: 0,
                      })}
                        className={classes.link}>
                        Home
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                    <Link to="/" onClick={() => window.location.href = '/consumer'}
                          className={classes.link}>
                          Consumer portal
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                    <Link to="/" onClick={() => scroller.scrollTo('features', {
                      duration: 150,
                      delay: 100,
                      smooth: true,
                      offset: -100,
                    })}
                      className={classes.link}>
                      Features
                    </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link to="/" onClick={() => scroller.scrollTo('how-it-works', {
                        duration: 150,
                        delay: 100,
                        smooth: true,
                        offset: -100,
                      })}
                        className={classes.link}>
                        How it works
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link to="/" onClick={() => scroller.scrollTo('benefits', {
                          duration: 150,
                          delay: 100,
                          smooth: true,
                          offset: -100,
                        })}
                          className={classes.link}>
                          Benefits
                        </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link to="/" onClick={() => scroller.scrollTo('testemonials', {
                            duration: 150,
                            delay: 100,
                            smooth: true,
                            offset: -100,
                          })}
                            className={classes.link}>
                            Testemonials
                        </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                    <Link to="/" onClick={() => scroller.scrollTo('our-partners', {
                          duration: 150,
                          delay: 100,
                          smooth: true,
                          offset: -100,
                        })}
                          className={classes.link}>
                          Our partners
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link to="/" onClick={() => scroller.scrollTo('contact-us', {
                            duration: 150,
                            delay: 100,
                            smooth: true,
                            offset: -100,
                          })}
                            className={classes.link}>
                            Contact us
                        </Link>
                    </ListItemText>
                  </ListItem>
                </>
              ) : (
                <>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link
                        to="/retailer-dashboard" 
                        className={classes.link}>
                        Dashboard
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link
                        to="/insights"
                        className={classes.link}>
                        Insights
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link
                        to="/offers"
                        className={classes.link}>
                        Offers
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link
                        to="/stores"
                        className={classes.link}>
                        Stores
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link
                        to="/terminals"
                        className={classes.link}>
                        Terminals
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link
                        to="/"
                        className={classes.link}
                        onClick={() => signOut()}
                      >
                        Log out
                      </Link>
                    </ListItemText>
                  </ListItem>
                </>
              )
            }
          </List>
        </LoginBox>
      </Drawer>
      <IconButton className={classes.menuIcon} onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </>
  )
}

export const ConsumerDrawerComponent = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Drawer
        open={openDrawer}
        anchor='right'
        onClose={() => setOpenDrawer(false)}
        direc
        PaperProps={{
          style: {
            background: 'linear-gradient(#7fcbae, #00ff99)',
            width: '100%',
            textAlign: 'center',
          }
        }}
      >
        <LoginBox>
          <List
            className={classes.list}
          >
            <img src={logo} alt="my-pop-logo" className={classes.logo} />
            <IconButton
              onClick={() => setOpenDrawer(false)}
              aria-label="close"
              className={classes.icon}
            >
              <CloseIcon />
            </IconButton>
                <>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link to="/consumer" onClick={() => scroller.scrollTo('consumer-home', {
                        duration: 150,
                        delay: 100,
                        smooth: true,
                        offset: 0,
                      })}
                        className={classes.link}>
                        Home
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                    <Link to="/" onClick={() => window.location.href = '/'}
                          className={classes.link}>
                          Retailer portal
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                    <Link to="/consumer" onClick={() => scroller.scrollTo('consumer-how-it-works', {
                      duration: 150,
                      delay: 100,
                      smooth: true,
                      offset: -100,
                    })}
                      className={classes.link}>
                      How it works
                    </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem} onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                      <Link to="/consumer" onClick={() => scroller.scrollTo('consumer-sign-up', {
                        duration: 150,
                        delay: 100,
                        smooth: true,
                        offset: -100,
                      })}
                        className={classes.link}>
                        Sign up
                      </Link>
                    </ListItemText>
                  </ListItem>
                </>
          </List>
        </LoginBox>
      </Drawer>
      <IconButton className={classes.menuIcon} onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </>
  )
}
