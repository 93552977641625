import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CustomSelectSmall,
  DashboardContainer,
  DashboardContent,
  HorizontalLineSmall,
} from "../index.styled";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  getTransactionsPerMonthData,
  getRetailerOffers,
  getRetailerTransactions,
} from "../../services/retailerService";
import { setRetailerTransactions } from "../../redux/users/users";
import CountUp from "react-countup";
import phone from "../../images/Screen2men.png";
import CreateOffer from "./CreateOffer";
import { LocalMall, TrackChanges, Visibility } from "@material-ui/icons";
import { Calculate, CurrencyPound, Soap } from "@mui/icons-material";
import {
  getUsersByRetailerForTimePeriod,
  getUsersByRetailers,
} from "../../services/userService";
import {
  getStoreManagerOffers,
  getStoreManagerTransactions,
} from "../../services/storeService";
import { TransactionsReviewGraph } from "../Shared/Graphs";
import { RetailerHeaderDashboard } from "../Header/Header";
import { Redirect } from "react-router-dom";
import {
  getActiveOffersForRetailer,
  getActiveOffersForStoreManager,
  getRedemeedOffersForRetailer,
  getRedemeedOffersForStoreManager,
} from "../../services/offerService";

const useStyles = makeStyles((theme) => ({
  typographyh4: {
    fontFamily: "Gotham-Rounded-Book !important",
    color: "#3B3D40",
    textAlign: "center",
    textTransform: "uppercase",
  },
  typographyLarge: {
    fontFamily: "Gotham-Rounded-Book !important",
    color: "#A0D2BB",
    textAlign: "left",
    width: "70%",
  },
  typographyImg: {
    fontFamily: "Gotham-Rounded-Medium !important",
    color: "#A0D2BB",
    textTransform: "uppercase",
  },
  typography: {
    fontFamily: "Gotham-Rounded-Medium !important",
    color: "#A0D2BB",
    textAlign: "left",
  },
  leftPart: {
    width: "auto",
    paddingLeft: "150px",
    paddingRight: "50px",
    paddingTop: "20px",
  },
  rightPart: {
    width: "auto",
    paddingLeft: "50px",
    paddingRight: "100px",
    paddingTop: "20px",
  },
  leftPartTable: {
    display: "flex",
    flexWrap: "wrap",
  },
  leftPartCell: {
    flex: "1 0 21%" /* explanation below */,
    margin: "5px",
    height: "200px",
  },
  phoneImg: {
    height: "450px",
  },
  imgContainer: {
    display: "flex",
  },
  img: {
    width: "64px",
  },
  leftDiv: {
    marginTop: "auto",
    marginBottom: "auto",
    height: "150px",
    width: "70%",
  },
  tabText: {
    flexGrow: "1",
    marginRight: "0px",
    width: "100%",
    textAlign: "center",
    fontFamily: "Gotham-Rounded-Medium !important",
    fontSize: "15px !important",
    "&:selected": {
      backgroundColor: "blue !important",
    },
  },
  tabPanel: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "750px",
  },
}));

const RetailerDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const currentUser = useSelector((state) => state.users.currentUser);
  const transactions = useSelector((state) => state.users.transactions);
  const [customers, setCustomers] = useState([]);
  const [customersPeriod, setCustomersPeriod] = useState([]);
  const [periodInMonths, setPeriodInMonths] = useState(1);
  const [periodInMonthsGraph, setPeriodInMonthsGraph] = useState(12);
  const [offers, setOffers] = useState([]);
  const [transactionsPerMonth, setTransactionsPerMonth] = useState([]);
  const [numberActiveOffers, setNumberActiveOffers] = useState(0);
  const [numberRedemeedOffers, setNumberRedemeedOffers] = useState(0);

  const dispatch = useDispatch();

  const handlePeriodChange = (e) => {
    setPeriodInMonths(e.target.value);
  };

  const handlePeriodGraphChange = (e) => {
    setPeriodInMonthsGraph(e.target.value);
  };

  useEffect(() => {
    if (
      currentUser.role === "retail_admin" ||
      currentUser.role === "retail_user"
    ) {
      getRetailerTransactions(currentUser.retailer_id, currentUser.token).then(
        (response) => {
          dispatch(setRetailerTransactions(response.data));
        }
      );
      getRetailerOffers(currentUser.retailer_id, currentUser.token).then(
        (response) => {
          setOffers(response.data);
        }
      );
      getActiveOffersForRetailer(
        currentUser.retailer_id,
        currentUser.token
      ).then((response) => {
        setNumberActiveOffers(response.data.activeOffers);
      });
      getRedemeedOffersForRetailer(
        currentUser.retailer_id,
        currentUser.token
      ).then((response) => {
        setNumberRedemeedOffers(response.data.redemeedOffers);
      });
    } else if (
      currentUser.role === "store_admin" ||
      currentUser.role === "store_user"
    ) {
      getStoreManagerTransactions(currentUser.uid, currentUser.token).then(
        (response) => {
          dispatch(setRetailerTransactions(response.data));
        }
      );
      getStoreManagerOffers(currentUser.uid, currentUser.token).then(
        (response) => {
          setOffers(response.data);
        }
      );
      getActiveOffersForStoreManager(currentUser.uid, currentUser.token).then(
        (response) => {
          setNumberActiveOffers(response.data.activeOffers);
        }
      );
      getRedemeedOffersForStoreManager(currentUser.uid, currentUser.token).then(
        (response) => {
          setNumberRedemeedOffers(response.data.redemeedOffers);
        }
      );
    }
    getUsersByRetailerForTimePeriod(
      currentUser.retailer_id,
      periodInMonths,
      currentUser.token
    ).then((response) => {
      setCustomersPeriod(response.data);
    });
    getUsersByRetailers(currentUser.retailer_id, currentUser.token).then(
      (response) => {
        setCustomers(response.data.users);
      }
    );
  }, [
    currentUser.retailer_id,
    currentUser.role,
    currentUser.token,
    currentUser.uid,
    dispatch,
    periodInMonths,
  ]);

  useEffect(() => {
    getTransactionsPerMonthData(
      currentUser.retailer_id,
      periodInMonthsGraph,
      currentUser.token
    ).then((response) => {
      setTransactionsPerMonth(response.data);
    });
  }, [currentUser.retailer_id, currentUser.token, periodInMonthsGraph]);

  return (
    <>
      {currentUser.retailer_id ? (
        <>
          <RetailerHeaderDashboard />
          <HorizontalLineSmall center color="#426A6D" thin width="80%" />
          <DashboardContainer display="flex">
            <DashboardContent isMobile={isMobile} className={classes.leftPart}>
              <div className={classes.leftPartTable}>
                <div className={classes.leftPartCell}>
                  <HorizontalLineSmall
                    center
                    color="#A0D2BB"
                    thin
                    width="110px"
                  />
                  <Typography className={classes.typographyh4} variant="h2">
                    <CountUp duration={0.5} end={customersPeriod?.length} />
                  </Typography>
                  <Typography className={classes.typographyh4}>
                    New buyers
                  </Typography>
                  <HorizontalLineSmall
                    center
                    color="#A0D2BB"
                    thin
                    width="110px"
                  />
                  <Typography variant="caption">
                    in the last
                    <CustomSelectSmall
                      value={periodInMonths}
                      onChange={handlePeriodChange}
                    >
                      <option value={1} defaultValue={1}>
                        30 days
                      </option>
                      <option value={3} defaultValue={3}>
                        quater
                      </option>
                      <option value={6} defaultValue={1}>
                        6 month
                      </option>
                      <option value={12} defaultValue={12}>
                        year
                      </option>
                    </CustomSelectSmall>
                  </Typography>
                </div>
                <div className={classes.leftPartCell}>
                  <TrackChanges
                    style={{ fontSize: "2.5rem", color: "#A0D2BB" }}
                  />
                  <Typography className={classes.typographyh4} variant="h3">
                    <CountUp duration={2} end={offers.length} />
                  </Typography>
                  <Typography className={classes.typographyh4}>
                    Offers
                  </Typography>
                </div>
                <div className={classes.leftPartCell}>
                  <Visibility
                    style={{ fontSize: "2.5rem", color: "#A0D2BB" }}
                  />
                  <Typography className={classes.typographyh4} variant="h3">
                    <CountUp duration={2} end={numberActiveOffers} />
                  </Typography>
                  <Typography className={classes.typographyh4}>
                    Active offers
                  </Typography>
                </div>
                <div className={classes.leftPartCell}>
                  <Soap style={{ fontSize: "2.5rem", color: "#A0D2BB" }} />
                  <Typography className={classes.typographyh4} variant="h3">
                    <CountUp duration={2} end={numberRedemeedOffers} />
                  </Typography>
                  <Typography className={classes.typographyh4}>
                    Redemeed offers
                  </Typography>
                </div>
                <div className={classes.leftPartCell}>
                  <HorizontalLineSmall
                    center
                    color="#A0D2BB"
                    thin
                    width="110px"
                  />
                  <Typography className={classes.typographyh4} variant="h2">
                    <CountUp duration={2} end={customers?.length} />
                  </Typography>
                  <Typography className={classes.typographyh4}>
                    Total buyers
                  </Typography>
                  <HorizontalLineSmall
                    center
                    color="#A0D2BB"
                    thin
                    width="110px"
                  />
                </div>
                <div className={classes.leftPartCell}>
                  <CurrencyPound
                    style={{ fontSize: "2.5rem", color: "#A0D2BB" }}
                  />
                  <Typography className={classes.typographyh4} variant="h3">
                    <CountUp
                      decimals={1}
                      decimal="."
                      prefix="£"
                      duration={2}
                      end={transactions?.totalValue}
                    />
                  </Typography>
                  <Typography className={classes.typographyh4}>
                    Total transactions value
                  </Typography>
                </div>
                <div className={classes.leftPartCell}>
                  <LocalMall style={{ fontSize: "2.5rem", color: "#A0D2BB" }} />
                  <Typography className={classes.typographyh4} variant="h3">
                    <CountUp
                      duration={2}
                      end={transactions?.transactions?.length}
                    />
                  </Typography>
                  <Typography className={classes.typographyh4}>
                    Transactions
                  </Typography>
                </div>
                <div className={classes.leftPartCell}>
                  <Calculate style={{ fontSize: "2.5rem", color: "#A0D2BB" }} />
                  <Typography className={classes.typographyh4} variant="h3">
                    <CountUp
                      duration={2}
                      decimals={1}
                      decimal="."
                      prefix="£"
                      end={
                        transactions?.totalValue /
                        transactions?.transactions?.length
                      }
                    />
                  </Typography>
                  <Typography className={classes.typographyh4}>
                    Average transaction value
                  </Typography>
                </div>
              </div>
              <div>
                <div className={classes.tabPanel}>
                  in the last
                  <CustomSelectSmall
                    value={periodInMonthsGraph}
                    onChange={handlePeriodGraphChange}
                  >
                    <option value={1} defaultValue={1}>
                      30 days
                    </option>
                    <option value={3} defaultValue={3}>
                      quater
                    </option>
                    <option value={6} defaultValue={1}>
                      6 month
                    </option>
                    <option value={12} defaultValue={12}>
                      year
                    </option>
                  </CustomSelectSmall>
                  <TransactionsReviewGraph data={transactionsPerMonth} />
                </div>
              </div>
              <br />
            </DashboardContent>
            <DashboardContent isMobile={isMobile} className={classes.rightPart}>
              <Typography className={classes.typographyLarge} variant="h4">
                Here is a snapshot of what's happening within your store.
              </Typography>
              <br />
              <Typography className={classes.typography}>
                Why not create some offers and attract your consumers?
              </Typography>
              <br />
              <div className={classes.imgContainer}>
                <div className={classes.leftDiv}>
                  <Typography className={classes.typographyImg} variant="h4">
                    What's your latest offer?
                  </Typography>
                  <br />
                  <CreateOffer transactions={transactions?.transactions} />
                </div>
                <img className={classes.phoneImg} src={phone} alt="phoneImg" />
              </div>
            </DashboardContent>
          </DashboardContainer>
        </>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default RetailerDashboard;
