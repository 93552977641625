import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CustomGreenButton } from '../../index.styled';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { deleteUser } from '../../../services/userService';
import { app } from '../../../firebase';

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#426A6D',
    textDecoration: 'none'
  },
  typographyDarkUppercase: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: 'rgb(45, 97, 99)',
    textTransform: 'uppercase'
  },
  yesButton: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    width: '150px',
    color: '#426A6D !important',
    border: '1px solid #426A6D !important',
    height: '40px',
    borderRadius: '0px !important'
  }

}));

export default function DeleteAccountPopUp({ myPopId }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYesClick = async () => {
    console.log(myPopId);
    await deleteUser({
      data: {
        myPopId
      }
    }).then(() => {
      app.auth().signOut();
    })
  }

  return (
    <div>
      <CustomGreenButton variant="outlined" onClick={handleClickOpen}>
        Delete account
      </CustomGreenButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.typographyDarkUppercase} id="alert-dialog-title">
          Delete My-POP account?
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.typography} id="alert-dialog-description">
            Are you sure you want to delete your account? Keep in mind that you will miss all future offers from your favorite merchants!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomGreenButton onClick={handleClose}>No</CustomGreenButton>
          <Button className={classes.yesButton} onClick={() => handleYesClick()} variant='outlined'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}