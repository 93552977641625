import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VerticalBarOffersRolledOut } from '../Shared/Graphs';
import { useSelector } from 'react-redux';
import { getOffersPerMonthData } from '../../services/retailerService';
import { CustomSelectSmall, HorizontalLineSmall } from '../index.styled';
import { RetailerHeaderDashboard } from '../Header/Header';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { getStoreManagerOffers } from '../../services/storeService';
import { Typography } from '@material-ui/core';
import { getValueOfRedemptions } from '../../services/offerService';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '70px',
    padding: '20px'
  },
  item: {
    flex: '1 0 35%',
    justifyContent: 'space-between',
    textAlign: 'center',
    overflowY: 'auto',
    maxHeight: '500px'
  },
  header: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#426A6D',
    textTransform: 'uppercase'
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#B5B5B5',
    color: theme.palette.common.white,
    fontFamily: 'Gotham-Rounded-Medium !important',
    textTransform: 'uppercase'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#3B3D40',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Insights = () => {
  const classes = useStyles();
  const currentUser = useSelector((state) => state.users.currentUser);
  const [offersPerMonth, setOffersPerMonth] = useState([]);
  const [offers, setOffers] = useState([]);
  const [periodInMonthsGraph, setPeriodInMonthsGraph] = useState(12);

  useEffect(() => {
    if (currentUser.role === 'retail_admin' || currentUser.role === 'retail_user') {
      getValueOfRedemptions(currentUser.retailer_id, currentUser.token).then((response) => {
        setOffers(response.data);
      })
    } else if (currentUser.role === 'store_admin' || currentUser.role === 'store_user') {
      getStoreManagerOffers(currentUser.uid, currentUser.token).then((response) => {
        setOffers(response.data);
      });
    }

  }, [currentUser.uid, currentUser.role, currentUser.retailer_id, currentUser.token]);

  useEffect(() => {
      getOffersPerMonthData(currentUser.retailer_id, 6, currentUser.token).then((response) => {
        setOffersPerMonth(response.data);
      })
  }, [currentUser.retailer_id, currentUser.token])

  useEffect(() => {
      getOffersPerMonthData(currentUser.retailer_id, periodInMonthsGraph, currentUser.token).then((response) => {
        setOffersPerMonth(response.data);
      })
  }, [currentUser.retailer_id, currentUser.token, periodInMonthsGraph])

  const handlePeriodGraphChange = (e) => {
    setPeriodInMonthsGraph(e.target.value);
  };

  return (
    <>
      {
        (currentUser.retailer_id) ? (
          <>
            <RetailerHeaderDashboard />
            <HorizontalLineSmall center color='#426A6D' thin width='80%' />
            <div className={classes.container}>
              <div className={classes.item}>
                <Typography variant='h5' className={classes.header}>
                  Offers overview
                </Typography>
                <div className={classes.tabPanel}>
                  in the last
                  <CustomSelectSmall
                    value={periodInMonthsGraph}
                    onChange={handlePeriodGraphChange}
                  >
                    <option value={1} defaultValue={1}>30 days</option>
                    <option value={3} defaultValue={3}>quater</option>
                    <option value={6} defaultValue={1}>6 month</option>
                    <option value={12} defaultValue={12}>year</option>
                  </CustomSelectSmall>
                  <VerticalBarOffersRolledOut data={offersPerMonth} />
                </div>
              </div>
              <div className={classes.item}>
                <Typography variant='h5' className={classes.header}>
                  Upsell due to offers
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Offer code</StyledTableCell>
                            <StyledTableCell align="right">Redemption count</StyledTableCell>
                            <StyledTableCell align="right">Total value of receipt</StyledTableCell>
                            <StyledTableCell align="right">Value of redemption</StyledTableCell>
                            <StyledTableCell align="right">Upsell value</StyledTableCell>
                          </TableRow>
                        </TableHead>
                      <TableBody>
                        {offers.map((row) => (
                        <StyledTableRow key={row.offerCode}>
                          <StyledTableCell component="th" scope="row">
                            {row.offerCode}
                          </StyledTableCell>
                          <StyledTableCell align="right">{row.numberOfTimesRedeemed}</StyledTableCell>
                          <StyledTableCell align="right">{row.totalValueOfReceipt} £</StyledTableCell>
                          <StyledTableCell align="right">{row.totalRedemptionValue} £</StyledTableCell>
                          <StyledTableCell align="right">{row.upsellValue} £</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className={classes.item}>
                <Typography variant='h5' className={classes.header}>
                  Offers performance
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Date</StyledTableCell>
                              <StyledTableCell align="right">Description</StyledTableCell>
                              <StyledTableCell align="right">Offer code</StyledTableCell>
                              <StyledTableCell align="right">Redemption count</StyledTableCell>
                              <StyledTableCell align="right">Total value of redemption</StyledTableCell>
                            </TableRow>
                          </TableHead>
                        <TableBody>
                          {offers.map((row) => (
                          <StyledTableRow key={row.offerCode}>
                            <StyledTableCell component="th" scope="row">
                              {new Date(row.createdAt._seconds * 1000).toDateString()}
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.description}</StyledTableCell>
                            <StyledTableCell align="right">{row.offerCode}</StyledTableCell>
                            <StyledTableCell align="right">{row.numberOfTimesRedeemed}</StyledTableCell>
                            <StyledTableCell align="right">{row.totalRedemptionValue} £</StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              <div className={classes.item}>
              <Typography variant='h5' className={classes.header}>
                Monthly offer performance
              </Typography>
              <br />
              <Typography variant='h5' className={classes.header}>
                Coming soon...
              </Typography>
              {/* <div>
                  <Box sx={{ width: '100%', bgcolor: 'transparent' }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      centered
                    >
                      <Tab className={classes.tabText} label="Total value of receipt" {...a11yProps(0)} />
                      <Tab className={classes.tabText} label="Value of redeemed offers" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <div className={classes.tabPanel}>
                      in the last
                      <CustomSelectSmall
                        value={periodInMonthsGraph}
                        onChange={handlePeriodGraphChange}
                      >
                        <option value={1} defaultValue={1}>30 days</option>
                        <option value={3} defaultValue={3}>quater</option>
                        <option value={6} defaultValue={1}>6 month</option>
                        <option value={12} defaultValue={12}>year</option>
                      </CustomSelectSmall>
                      <LineGraphValueOfTransactions data={offersPerMonth} />
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div className={classes.tabPanel}>
                      in the last
                      <CustomSelectSmall
                        value={periodInMonthsGraph}
                        onChange={handlePeriodGraphChange}
                      >
                        <option value={1} defaultValue={1}>30 days</option>
                        <option value={3} defaultValue={3}>quater</option>
                        <option value={6} defaultValue={1}>6 month</option>
                        <option value={12} defaultValue={12}>year</option>
                      </CustomSelectSmall>
                      <LineGraphValueOfTransactions data={offersPerMonth} />
                    </div>
                  </TabPanel>
                </div> */}
              </div> 
            </div>
          </>
        ) : (
          <Redirect to='/'/>
        )
      }
    </>
  )
};

export default Insights;
