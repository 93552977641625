import React, { useState } from 'react';
import Scroll from 'react-scroll';
import {
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { BenefitsContainer, BenefitsContext, CustomButton, CustomInput, CustomTextArea, HorizontalLineSmall } from '../index.styled';
import Box from '@mui/material/Box';
import { sendEmail } from '../../services/emailService';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Element  = Scroll.Element;

const useStyles = makeStyles((theme) => ({
  typographyh4: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#3B3D40',
    textAlign: 'center',
    marginTop: '50px',
  },
  typographyh6: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#3B3D40',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginTop: '50px',
  },
  first: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  second: {
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  firstMobile: {
    justifyContent: 'space-between',
    marginBottom: '20px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  secondMobile: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const ContactUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const clearFields = () => {
    setName('');
    setEmail('');
    setPhone('');
    setMessage('');
  };

  const handleSendMessageClick = () => {
    const data = {
      name,
      email,
      phone,
      message
    };
    sendEmail({ data }).then(() => {
      setAlertMessage('Thank you for your message! We will get back to you as soon as possible!');
      setAlertSeverity('success');
      setShowAlert(true);
      clearFields();
    }).catch((err) => {
      setAlertMessage('Your message was not sent! Try contacting us in a different way.');
      setAlertSeverity('error');
      setShowAlert(true);
    });
  }

  return (
    <Element name="contact-us">
      <BenefitsContainer>
        <BenefitsContext>
          <Typography variant='h6' className={classes.typographyh6}>
            CONTACT
          </Typography>
          <HorizontalLineSmall center color='#000000' />
          <Typography variant='h4' className={classes.typographyh4}>
            Let us show you what My-POP can do for your business.
          </Typography>
          <Collapse in={showAlert} style={{ width: '40%', margin: 'auto', marginTop: '20px' }}>
            <Alert
              severity={alertSeverity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {alertMessage}
            </Alert>
          </Collapse>
          <Box sx={{ width: '100%', marginTop: '40px' }}>
            <div className={isMobile ? classes.firstMobile : classes.first}>
              <CustomInput onChange={handleName} value={name} width='250px' placeholder='Name*' />
              <CustomInput type="email" onChange={handleEmail} value={email} width='250px' placeholder='E-mail*' />
              <CustomInput onChange={handlePhone} value={phone} width='250px' placeholder='Phone*' />
            </div>
            <div className={isMobile ? classes.secondMobile : classes.second}>
              <CustomTextArea onChange={handleMessage} value={message} placeholder='Your message*' height='250px' width='100%' />
              <br />
              <br />
              <CustomButton onClick={() => handleSendMessageClick()} disabled={name === '' || email === '' || phone === '' || message === ''}>
                Send your message
              </CustomButton>
              <br />
              <br />
            </div>
          </Box>
        </BenefitsContext>
      </BenefitsContainer>
  </Element>
  )
}

export default ContactUs;
