import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import { app } from './firebase';
import { setCurrentUser, setRetailerTransactions } from './redux/users/users';
import routes from './routes';
import PrivateLayoutContainer from './components/layouts/PrivateLayoutContainer';
import { consumerSignUp, getByEmail } from './services/userService';
import Footer from './components/Footer';
import { getRetailerLogo } from './services/retailerService';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (<PrivateLayoutContainer><Component {...props} /></PrivateLayoutContainer>)}
  />
);

const App = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    app.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await app.auth().currentUser.getIdToken();
        // const loggedUser = await db.collection('users').doc(user.uid).get();
        // console.log(user);
        await getByEmail(user.email, token).then((response) => {
            dispatch(setCurrentUser({
              ...response.data,
              token
            }));
        }).catch((err) => {
          console.error(err.response.status, err.code);
          if (err.response.status === 404) {
            consumerSignUp({
              data: {
                firstName: user.displayName.split(' ')[0],
                lastName: user.displayName.split(' ')[1],
                email: user.email,
                isSocial: true,
              },
              token
            }).then(async () => {
              await getByEmail(user.email, token).then(async (res) => {
                const obj = res.data;
                await getRetailerLogo(res.data.retailer_id, token).then((r) => {
                  obj.r_logo = r.data.logo;
                  dispatch(setCurrentUser(obj));
                })
              }).catch((err) => {
                console.error(err.response.status, err.code);
              })
            })
          }
        });
        <Redirect from='/' to='/retailer-dashboard' />
      } else {
        dispatch(setCurrentUser({}));
        dispatch(setRetailerTransactions({}));
      }
    });
  }, [dispatch]);

  return(
  <>
    <main>
        <div className="justify-content-center" style={{ position: 'relative', minHeight: '100vh' }}>
          <div style={{ paddingBottom: '250px' }}>
            <Switch>
              {routes.map((route) => {
                const { path, isPublic = false } = route;
                return isPublic
                ? <Route key={path} {...route} />
                : <PrivateRoute key={path} {...route} />
              })}
            </Switch>
          </div>
          <Footer />
        </div>
    </main>
  </>
  );
};

export default App
