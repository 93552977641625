import React from "react";
import { StyledFooter } from "./index.styled";
import logo from "../images/logo_big.png";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Facebook, Instagram, Twitter } from "@material-ui/icons";

const useStyles = makeStyles({
  footerTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "auto",
    marginRight: "auto",
  },
  typography: {
    fontFamily: "Gotham-Rounded-Medium !important",
    color: "#989898",
    textTransform: "uppercase",
    margin: "10px",
  },
  typographyHover: {
    fontFamily: "Gotham-Rounded-Medium !important",
    color: "#989898",
    textTransform: "uppercase",
    margin: "10px",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
});

const consumerView = window.location.href.includes("/consumer");

const Footer = () => {
  const classes = useStyles();

  return (
    <StyledFooter>
      <img
        alt="logo"
        src={logo}
        style={{ marginLeft: "auto", marginRight: "auto" }}
      />
      <div className={classes.footerTextWrapper}>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.facebook.com/mypopreceipts"
        >
          <Facebook className={classes.typography} />
        </a>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.instagram.com/mypopreceipts/"
        >
          <Instagram className={classes.typography} />
        </a>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://twitter.com/mypopreceipts"
        >
          <Twitter className={classes.typography} />
        </a>
      </div>
      <div className={classes.footerTextWrapper}>
        <Typography variant="body2" className={classes.typography}>
          Copyright © My-Digital LTD {new Date().getFullYear()}. All rigths
          reserved.
        </Typography>
      </div>
      <div className={classes.footerTextWrapper}>
        <Typography
          onClick={() => (window.location.href = "/terms-and-conditions")}
          variant="body2"
          className={classes.typographyHover}
        >
          Terms & conditions
        </Typography>
        <Typography
          onClick={() => (window.location.href = "/cookies-policy")}
          variant="body2"
          className={classes.typographyHover}
        >
          Cookies policy
        </Typography>
        <Typography
          onClick={() => (window.location.href = "/privacy-policy")}
          variant="body2"
          className={classes.typographyHover}
        >
          Privacy policy
        </Typography>
        {consumerView ? (
          <Typography
            onClick={() => (window.location.href = "/")}
            variant="body2"
            className={classes.typographyHover}
          >
            Retailer portal
          </Typography>
        ) : (
          <Typography
            onClick={() => (window.location.href = "/consumer")}
            variant="body2"
            className={classes.typographyHover}
          >
            Consumer portal
          </Typography>
        )}
      </div>
    </StyledFooter>
  );
};

export default Footer;
