import React from 'react';
import Scroll from 'react-scroll';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Slider from "react-slick";
import SectionTitle from '../Shared/SectionTitle';
import PointOfPurImg from '../../images/point_of_pur.png';
import StoreImg from '../../images/store.png';
import TargetedMarketingImg from '../../images/targeted_marketing.png';
import NfcImg from '../../images/nfc1.png';
import { useTheme, useMediaQuery } from '@mui/material';

const Element  = Scroll.Element;

const BoxWrapper = styled.div`
  display: flex;
  padding: 30px 20px 0 20px;
  margin-top: 30px;
`;

const StyledBox = styled(Box)`
  max-width: ${({ $isMobile = false }) => $isMobile ? 100 : 25}%;
  flex-basis: auto;
  flex-grow: 1;
  text-align: center;
  font-family: Gotham-Rounded-Book;
  font-size: 18px;
  color: #3b3d40;
  padding: 0 15px;

  img {
    height: 100px;
    margin: 0 auto;
    margin-bottom: 5px;
  }

  h3 {
    font-family: Gotham-Rounded-Medium !important;
    text-transform: uppercase;
    font-size: 18px;
    margin: 20px 0;
  }

  p {
    padding: 0 18px;
  }
`;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const HowItWorks = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Element name="how-it-works">
    <div style={{
      width: '100%',
      maxHeight: '100vh',
      background: '#FFFFFF',
      paddingTop: '80px',
      paddingBottom: '100px',
    }}
    >
      <SectionTitle color="#3B3D40">
        How it works
      </SectionTitle>

      {!isMobile ? (
        <BoxWrapper>
          <StyledBox $isMobile={isMobile}>
            <img src={TargetedMarketingImg} alt="TARGETED MARKETING" />
            <h3>TARGETED MARKETING</h3>
            <p>Send one-to-one offers and loyalty promotions directly to  customer’s phone anytime and instantly.</p>
          </StyledBox>
          <StyledBox $isMobile={isMobile}>
            <img src={PointOfPurImg} alt="POINT OF PURCHASE" />
            <h3>POINT OF PURCHASE</h3>
            <p>Just download or connect My-POP onto your EPOS / payment terminal and register your merchant account. Easy.</p>
          </StyledBox>
          <StyledBox $isMobile={isMobile}>
            <img src={NfcImg} alt="ONE ZAP AND IT'S DONE" />
            <h3>ONE ZAP AND IT'S DONE</h3>
            <p>Customers tap their phone with NFC at the till, or scan their QR code/bar code from their My-POP app. Whoosh – the receipt is sent straight to their phone and you retain one for your records.</p>
          </StyledBox>
          <StyledBox $isMobile={isMobile}>
            <img src={StoreImg} alt="EVERYTHING IN ONE PLACE" />
            <h3>EVERYTHING IN ONE PLACE</h3>
            <p>Hey presto! You can access all your customers’ digital receipts in one place and get customised reports.</p>
          </StyledBox>
        </BoxWrapper>
      ) : (
        <Slider {...settings}>
            <div>
            <StyledBox $isMobile={isMobile}>
              <img src={TargetedMarketingImg} alt="TARGETED MARKETING" />
              <h3>TARGETED MARKETING</h3>
              <p>Send one-to-one offers and loyalty promotions directly to  customer’s phone anytime and instantly.</p>
            </StyledBox>
          </div>
          <div>
            <StyledBox $isMobile={isMobile}>
              <img src={PointOfPurImg} alt="POINT OF PURCHASE" />
              <h3>POINT OF PURCHASE</h3>
              <p>Just download or connect My-POP onto your EPOS / payment terminal and register your merchant account. Easy.</p>
            </StyledBox>
          </div>
          <div>
            <StyledBox $isMobile={isMobile}>
              <img src={NfcImg} alt="ONE ZAP AND IT'S DONE" />
              <h3>ONE ZAP AND IT'S DONE</h3>
              <p>Customers tap their phone with NFC at the till, or scan their QR code/bar code from their My-POP app. Whoosh – the receipt is sent straight to their phone and you retain one for your records.</p>
            </StyledBox>
          </div>
          <div>
            <StyledBox $isMobile={isMobile}>
              <img src={StoreImg} alt="EVERYTHING IN ONE PLACE" />
              <h3>EVERYTHING IN ONE PLACE</h3>
              <p>Hey presto! You can access all your customers’ digital receipts in one place and get customised reports.</p>
            </StyledBox>
          </div>
        </Slider>
      )}
    </div>
  </Element>
  )
}

export default HowItWorks;
