import React, { forwardRef, useState } from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CustomButton, CustomInput, HorizontalLineSmall, LoginBox, CustomSelect} from '../index.styled';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import { People } from '@material-ui/icons';
import { addRetailerUser } from '../../services/retailerService';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  typography: {
    fontFamily: 'Gotham-Rounded-Medium !important'
  },
  typographyUnderlined: {
    fontFamily: 'Gotham-Rounded-Light !important',
    textDecoration: 'underline',
  }
});

const Transition = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const AddUser = ({ MenuItem }) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const classes = useStyles();
  const [showAlert, setShowAlert] = useState(false);
  const currentUser = useSelector((state) => state.users.currentUser);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearFields = () => {
    setEmail('');
    setFirstName('');
    setLastName('');
    setRole('');
  }

  const handleAddUserClick = async () => {
    await addRetailerUser({
      data: {
        firstName,
        lastName,
        email,
        role,
        retailer_id: currentUser.retailer_id,
      },
      token: currentUser.token,
    }).then(() => {
      setShowAlert(true);
      clearFields();
    });
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen} size="small">
        <People style={{ fontSize: '2.0rem', color: '#A0D2BB' }} />
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: 'linear-gradient(#7fcbae, #00ff99)',
            textAlign: 'center'
          }
        }}
      >
        <LoginBox>
        <Collapse in={showAlert}>
        <Alert
          severity='success'
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Successfully a new colleague added!
        </Alert>
      </Collapse>
          <IconButton
            onClick={handleClose}
            aria-label="close"
            style={{
              position: 'absolute',
              right: '0',
              color: '#FFFFFF'
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.typography} variant='body1'>
            ADD YOUR COLLEAGUE
          </Typography>
          <HorizontalLineSmall center />
          <br />
          <br />
          <CustomInput
            placeholder='First name*'
            aria-label='First name'
            value={firstName}
            onChange={handleFirstNameChange}
          />
          <br />
          <CustomInput
            placeholder='Last name*'
            aria-label='Last name'
            value={lastName}
            onChange={handleLastNameChange}
          />
          <br />
          <CustomInput
            placeholder='E-mail*'
            aria-label='E-mail'
            value={email}
            onChange={handleEmailChange}
          />
          <br />
          <CustomSelect
            placeholder='Role*'
            aria-label='Role'
            value={role}
            onChange={handleRoleChange}
          >
            <option value="" disabled defaultValue="">Select role *</option>
            <option value="retail_admin">Retail admin</option>
            <option value="retail_user">Retail user</option>
            <option value="store_admin">Store admin</option>
            <option value="store_user">Store user</option>
          </CustomSelect>
          <br />
          <CustomButton variant='contained' onClick={() => handleAddUserClick()}>
            Add colleague
          </CustomButton>
        </LoginBox>
      </Dialog>
    </div>
  )
}

export default AddUser;
