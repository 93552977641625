import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConsumerHeaderDashboard } from '../../Header/Header'
import { ConsumerTransactionsContainer, CustomGreenButton, HorizontalLineSmall } from '../../index.styled';
import { Box, Collapse, IconButton, Switch, Typography, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import logo from '../../../images/logo.png';
import { getConsumerTransactions } from '../../../services/userService';
import { KeyboardArrowDown, KeyboardArrowUp, PictureAsPdf, Settings, ShoppingBasket, Star, Receipt } from '@material-ui/icons';
import { FilterAltOff, Support } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { URLS } from '../../../constants';
import { changeType, getFilteredTransactions } from '../../../services/transactionsService';
import { InputAdornment, OutlinedInput, Tooltip } from '@mui/material';
import DeleteTransactionPopUp from './DeleteTransactionPopUp';

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#426A6D',
    textDecoration: 'none'
  },
  styledRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  typographyDarkUppercase: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#383838',
    textTransform: 'uppercase'
  },
  typographyDarkLowercase: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#383838',
  },
  typographyLightUppercase: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#808080',
    textTransform: 'uppercase'
  },
  typographyLightLowercase: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#808080',
  },
  typographyLightWhite: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    width: '100%',
    textAlign: 'center',
  },
  tabDark: {
    flex: '1 0 24%',
    borderRight: '0.5px solid #FFFFFF',
    paddingLeft: '20px',
    paddingRight: '20px',
    background: '#2D6163',
    height: '150px',
    textAlign: 'center',
    paddingTop: '30px'
  },
  tabLight: {
    flex: '1 0 24%',
    borderRight: '0.5px solid #FFFFFF',
    paddingLeft: '20px',
    paddingRight: '20px',
    background: '#74C6A5',
    height: '150px',
    textAlign: 'center',
    paddingTop: '30px',
    textDecoration: 'none !important',
    "&:hover": {
      cursor: 'pointer',
    }
  },
  icon: {
    fontSize: '80px !important',
    color: '#FFFFFF',
  },
  typographyWhite: {
    fontFamily: 'Gotham-Rounded-Book !important',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    "&:hover": {
      cursor: 'pointer',
    },
    textDecoration: 'none'
  },
  searchBarContainer: {
    width: '100%',
    height: '60px',
    background: '#2D6163',
    paddingLeft: '10px'
  },
  searchBar: {
    fontFamily: 'Gotham-Rounded-Book !important',
  }
}));

const CustomSwitch = withStyles({
  switchBase: {
    color: '#74C6A5',
    '&$checked': {
      color: '#74C6A5',
    },
    '&$checked + $track': {
      backgroundColor: '#74C6A5',
    },
  },
  checked: {},
  track: {},
})(Switch);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#B5B5B5',
    color: theme.palette.common.white,
    fontFamily: 'Gotham-Rounded-Medium !important',
    textTransform: 'uppercase'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#3B3D40'
  },
}));



const CollapsibleRow = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const currentUser = useSelector((state) => state.users.currentUser);

  const downloadInvoice = (transaction) => {
    window.open(`${URLS.BASE_ENDPOINT_URL}/transactions/getInvoice/${transaction}/${currentUser.token}`, '_blank');
  };

  const handleChangeTransactionType = async (id) => {
    await changeType(id, currentUser.token).then(() => {
      window.location.reload();
    })
  };

  return (
    <React.Fragment>
      <TableRow className={classes.styledRow} sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell component="th" scope="row">
          {new Date(row.creationDateTime?._seconds * 1000).toDateString()}
        </StyledTableCell>
        <StyledTableCell align="right">
          {row?.parts.store.r_retailerName}
        </StyledTableCell>
        <StyledTableCell align="right">
          {
            (row?.parts.lineItems.length > 1) ? (
              <span>
                Multiple
              </span>
            ) : (
              <span>
                {row?.parts.lineItems[0]?.customerProduct?.productType.description}
              </span>
            )
          }
        </StyledTableCell>
        <StyledTableCell align="right">£ {row?.parts.price.amount.toFixed(2)}</StyledTableCell>
        <StyledTableCell align="right">
               P <CustomSwitch
                checked={row?.tags[0].name === 'business'}
                onChange={() => handleChangeTransactionType(row?.uid)}
                color="primary"
               /> B
            </StyledTableCell>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => downloadInvoice(row?.uid)}
          >
            <PictureAsPdf />
          </IconButton>
          <Tooltip
            style={{ color: 'rgba(0, 0, 0, 0.54)' }}
            hidden={row.parts.store.r_id !== 'N/A'}
            title="Transaction is retrieved from a scanned receipt."
          >
            <Receipt />
          </Tooltip>
          <DeleteTransactionPopUp transactionId={row?.uid} myPopId={currentUser.myPopId} token={currentUser.token} hidden={row.parts.store.r_id !== 'N/A'} />
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" className={classes.styledRow} unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '20px', paddingBottom: '20px' }}>
                <div style={{ flex: '1 0 15%', borderRight: '0.5px solid #D5D5D5', textAlign: 'center' }}>
                  <img src={row?.parts.store.r_logo} style={{ textAlign: 'center' }} alt='logo' />
                </div>
                <div style={{ flex: '1 0 28%', borderRight: '0.5px solid #D5D5D5', borderLeft: '0.5px solid #D5D5D5', paddingLeft: '20px', paddingRight: '20px' }}>
                  <Typography variant='body2' className={classes.typographyDarkLowercase}>
                    {row?.parts.store.name}
                  </Typography>
                  <Typography variant='body2' className={classes.typographyDarkLowercase}>
                    Thank you for shopping with us
                  </Typography>
                  <br />
                  <Typography className={classes.typographyDarkUppercase}>
                    Sale advisor: {row?.parts.agent.name}
                  </Typography>
                  <br />
                  <Typography style={{ display: 'flex' }} variant='caption' className={classes.typographyLightUppercase}>
                    Store no: <Typography style={{ marginLeft: '15px' }} variant='caption' className={classes.typographyDarkLowercase}>{row?.parts.store.id}</Typography>
                  </Typography>
                  <Typography style={{ display: 'flex' }} variant='caption' className={classes.typographyLightUppercase}>
                    Till no: <Typography style={{ marginLeft: '15px' }} variant='caption' className={classes.typographyDarkLowercase}>{row?.parts.payments[0].terminalId}</Typography>
                  </Typography>
                  <Typography style={{ display: 'flex' }} variant='caption' className={classes.typographyLightUppercase}>
                    Trans. no: <Typography style={{ marginLeft: '15px' }} variant='caption' className={classes.typographyDarkLowercase}>{row?.id}</Typography>
                  </Typography>
                  <Typography style={{ display: 'flex' }} variant='caption' className={classes.typographyLightUppercase}>
                    Date: <Typography style={{ marginLeft: '15px' }} variant='caption' className={classes.typographyDarkLowercase}>{new Date(row.creationDateTime?._seconds * 1000).toLocaleDateString()}</Typography>
                  </Typography>
                  <Typography style={{ display: 'flex' }} variant='caption' className={classes.typographyLightUppercase}>
                    Time: <Typography style={{ marginLeft: '15px' }} variant='caption' className={classes.typographyDarkLowercase}>{new Date(row.creationDateTime?._seconds * 1000).toLocaleTimeString()}</Typography>
                  </Typography>
                  <Typography style={{ display: 'flex' }} variant='caption' className={classes.typographyLightUppercase}>
                    Adviser no: <Typography style={{ marginLeft: '15px' }} variant='caption' className={classes.typographyDarkLowercase}>{row?.parts.agent.id}</Typography>
                  </Typography>
                  <br />
                  <br />
                  <Typography style={{ display: 'flex' }} variant='body2' className={classes.typographyLightUppercase}>
                    VAT number: <Typography style={{ marginLeft: '15px' }} variant='body2' className={classes.typographyDarkLowercase}>{row?.parts.store.r_vatNumber}</Typography>
                  </Typography>
                </div>
                <div style={{ flex: '1 0 24%', borderRight: '0.5px solid #D5D5D5', borderLeft: '0.5px solid #D5D5D5', paddingLeft: '20px', paddingRight: '20px' }}>
                  {row?.parts.lineItems.map((product) => {
                    return (
                      <div key={product.id}>
                        <div>
                          <Typography className={classes.typographyDarkLowercase}>
                            {product.customerProduct.name}
                          </Typography>
                          <Typography className={classes.typographyLightLowercase}>
                            ID: {product.customerProduct.id}
                          </Typography>
                          <Typography className={classes.typographyLightLowercase}>
                            Type: {product.customerProduct.productType.description}
                          </Typography>
                          <Typography className={classes.typographyLightLowercase}>
                            Quantity: {product.customerProduct.quantity}
                          </Typography>
                          {
                            (row?.parts.store.id === 'N/A') ? (
                              <Typography className={classes.typographyDarkLowercase}>
                                Price: £{(product.price.amount/product.customerProduct.quantity).toFixed(2)}
                              </Typography>
                            ) : (
                              <Typography className={classes.typographyDarkLowercase}>
                                Price: £{product.price.amount}
                              </Typography>
                            )
                          }
                        </div>
                        <br />
                        <br />
                      </div>
                    )
                  })}
                </div>
                <div style={{ flex: '1 0 24%', borderLeft: '0.5px solid #D5D5D5', paddingLeft: '20px', paddingRight: '20px' }}>
                  <Typography className={classes.typographyDarkUppercase}>
                    Payments
                  </Typography>
                  <br />
                  {row?.parts.payments.map((payment) => {
                    return (
                      <div key={payment.name}>
                        <Typography className={classes.typographyDarkUppercase}>
                          Payment amount: £{payment.amount.toFixed(2)}
                        </Typography>
                        {(payment.name === 'card') ? (
                          <Typography variant='body2' className={classes.typographyLightUppercase}>
                            Payment type: CARD
                          </Typography>
                        ) : (
                          <Typography variant='body2' className={classes.typographyLightUppercase}>
                            Payment type: CASH
                          </Typography>
                        )}
                        <Typography variant='body2' className={classes.typographyLightUppercase}>
                          Payment date: {new Date(row.creationDateTime?._seconds * 1000).toLocaleDateString()} at {new Date(row.creationDateTime?._seconds * 1000).toLocaleTimeString()}
                        </Typography>
                        <Typography className={classes.typographyLightUppercase}>
                          Change: £{payment.change}
                        </Typography>
                        {(payment.name === 'card') ? (
                          <>
                            <Typography variant='body2' className={classes.typographyLightUppercase}>
                              PIN verified
                            </Typography>
                            <Typography variant='body2' className={classes.typographyLightUppercase}>
                              {payment.cardReference}
                            </Typography>
                            <Typography variant='body2' className={classes.typographyLightUppercase}>
                              Authorisation: {payment.authCode}
                            </Typography>
                          </>
                      ) : (
                        <span />
                      )}
                      <br />
                      </div>
                    )
                  })}
                  <br />
                  <Typography variant='body2' className={classes.typographyLightUppercase}>
                      Total receipt amount
                    </Typography>
                  <Typography className={classes.typographyDarkUppercase} variant='h3'>
                    £{row?.parts.price.amount.toFixed(2)}
                  </Typography>
                </div>
              </div>
              <br />
              <div>
                <div style={{ paddingTop: '13px', marginLeft: 'auto', marginRight: 'auto', width: '70%', height: '45px', background: '#2D6163' }}>
                  <Typography variant="body2" className={classes.typographyLightWhite}>
                    To check the last date to you full refund to the original payment see Terms & Conditions
                  </Typography>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )


}

const ConsumerTransactions = () => {
  const classes = useStyles();
  const currentUser = useSelector((state) => state.users.currentUser);
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [transactionFilter, setTransactionFilter] = useState('all');
  const [showMessage, setShowMessage] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [showFiltered, setShowFiltered] = useState(false);

  const handleSeacrhTermChange = (e) => {
    setSearchTerm(e.target.value);
    // const temp = [];
    // if (e.target.value !== '') {
    //   transactions.forEach((transaction) => {
    //     transaction.parts.lineItems.forEach((item) => {
    //       if ((item.customerProduct.name.toLowerCase().includes(e.target.value.toLowerCase()) || transaction.parts.merchant.merchantName.toLowerCase().includes(e.target.value.toLowerCase())) && !temp.includes(transaction)) {
    //         temp.push(transaction);
    //       }
    //     })
    //   });
    //   setFilteredTransactions(temp);
    // }
  };

  const handleOnSearchClick = async () => {
    await getFilteredTransactions(searchTerm, currentUser.myPopId, currentUser.token).then((response) => {
      setFilteredTransactions(response.data);
      setShowFiltered(true);
      console.log(response.data);
    });
  };

  useEffect(() => {
    getConsumerTransactions(currentUser?.myPopId, currentUser?.token).then((response) => {
      console.log(response.data);
      
      setTransactions(response.data);
    });

    setTimeout(() => {
      setShowMessage(false)
    }, 3000);
  }, [currentUser?.myPopId, currentUser?.token])

  return (
    <>
      <ConsumerHeaderDashboard />
      <HorizontalLineSmall center color='#426A6D' thin width='80%' />
      <ConsumerTransactionsContainer>
        <img src={logo} alt='logo' />
        <Typography className={classes.typography}>
          Hi {currentUser.firstName},
        </Typography>
        <Typography className={classes.typography}>
          Welcome to your pop page. Here you can view and manage all your receipts.
        </Typography>
        <Typography className={classes.typography}>
          Don't forget to check the latest offers from your favorite stores in 'my deals'.
        </Typography>
        <div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className={classes.tabDark}>
              <ShoppingBasket className={classes.icon} />
              <Typography className={classes.typographyWhite}>
                Transactions
              </Typography>
            </div>
            <Link className={classes.tabLight} to='/consumer-offers'>
              <div>
                <Star className={classes.icon} />
                <Typography className={classes.typographyWhite}>
                  My offers
                </Typography>
              </div>
            </Link>
            <div className={classes.tabLight}>
              <Support className={classes.icon} />
              <Typography className={classes.typographyWhite}>
                Support
              </Typography>
            </div>
            <div className={classes.tabLight}>
              <Settings className={classes.icon} />
              <Typography className={classes.typographyWhite}>
                Settings
              </Typography>
            </div>  
          </div>
          <div style={{ display: 'flex', background: '#2D6163', height: '50px', width: '100%', justifyContent: 'space-between', padding: '10px' }}>
            <Typography variant={transactionFilter === 'all' ? 'body1' : 'body2'} className={classes.typographyWhite} onClick={() => setTransactionFilter('all')}>
              All
            </Typography>
            <Typography variant={transactionFilter === 'personal' ? 'body1' : 'body2'} className={classes.typographyWhite} onClick={() => setTransactionFilter('personal')}>
              Personal
            </Typography>
            <Typography variant={transactionFilter === 'business' ? 'body1' : 'body2'} className={classes.typographyWhite} onClick={() => setTransactionFilter('business')}>
              Business
            </Typography>
          </div>
          <div className={classes.searchBarContainer}>
            <OutlinedInput
              placeholder='Search for the transaction...'
              value={searchTerm}
              onChange={handleSeacrhTermChange}
              sx={{
                fontFamily: 'Gotham-Rounded-Book !important',
                width: '350px !important',
                backgroundColor: '#FFFFFF !important',
                height: '40px !important'
              }}
              endAdornment={
              <InputAdornment position="end">
                <Tooltip
                  hidden={!showFiltered}
                  title='Reset search'
                >
                  <IconButton
                    onClick={() => {
                      setShowFiltered(false);
                      setSearchTerm('');
                    }}
                  >
                    <FilterAltOff />
                  </IconButton>
                </Tooltip>  
              </InputAdornment>
              }
            />
            <CustomGreenButton
              onClick={() => handleOnSearchClick()}
              disabled={searchTerm === ''}
            >
              Search
            </CustomGreenButton>

          </div>
        </div>
        {
            (transactions.length === 0) ? (
              <div>
                <Typography className={classes.typography} hidden={showMessage} variant='h4'>
                  You don't have any transactions yet.
                </Typography>
                <Typography hidden={!showMessage} className={classes.typography} variant='h4'>
                  Loading...
                </Typography>
              </div>
            ) : (
              <div className={classes.tableContainer}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell align="right">Vendor</StyledTableCell>
                        <StyledTableCell align="right">Category</StyledTableCell>
                        <StyledTableCell align="right">Price in GBP</StyledTableCell>
                        <StyledTableCell align="right">Type</StyledTableCell>
                        <StyledTableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        (!showFiltered) ? (
                          <>
                          {transactions?.map((row) => (
                            (transactionFilter === 'all') ? (
                              <CollapsibleRow row={row} key={row?.uid} />
                            ) : (
                              row.tags[0].name === transactionFilter
                            ) ? (
                              <CollapsibleRow row={row} key={row?.uid} />
                            ) : (
                              <span />
                            )
                          ))}
                          </>
                        ) : (
                          <>
                            {filteredTransactions.map((row) => (
                              (transactionFilter === 'all') ? (
                                <CollapsibleRow row={row} key={row?.uid} />
                              ) : (
                                row.tags[0].name === transactionFilter
                              ) ? (
                                <CollapsibleRow row={row} key={row?.uid} />
                              ) : (
                                <span />
                              )
                            ))}
                          </>
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )
          }
      </ConsumerTransactionsContainer>
    </>
  )
}

export default ConsumerTransactions