/* eslint-disable no-restricted-globals */
import { Typography } from '@material-ui/core';
import React from 'react';
import { CustomButton } from '../index.styled';

const PrivacyPolicy = () => {
  const goBack = () => {
    history.back();
  };

  return (
    <div style={{ padding: '25px', width: '85%' }}>
      <Typography variant="h4">
        Privacy Policy of www.my-pop.com
      </Typography>
      <br />
      <Typography>
        Last updated: 25/04/2023
      </Typography>
      <br />
      <Typography variant='h5'>
        Introduction
      </Typography>
      <br />
      <Typography>
        At MY-POP, we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your personal information when you use our website and app, and your rights in relation to your data.
      </Typography>
      <br />
      <Typography>
        By using our website and app, you agree to the collection and use of your personal information in accordance with this policy. If you do not agree with this policy, please do not use our website and app.
      </Typography>
      <br />
      <Typography variant='h5'>
        Information we collect
      </Typography>
      <br />
      <Typography>
        When you use our website and app, we may collect the following types of information:
      </Typography>
      <br />
      <ol>
        <li>
          <Typography>
            Personal information: This includes information you provide when creating an account or using our services, such as your name, email address, phone number, and retailer preferences.
          </Typography>
        </li>
        <li>
          <Typography>
            Usage information: We collect information about how you interact with our website and app, including the pages you visit, the links you click, and the actions you take.
          </Typography>
        </li>
        <li>
          <Typography>
            Device and technical information: We collect information about your device and your internet connection, such as your IP address, operating system, browser type, and device identifier.
          </Typography>
        </li>
        <li>
          <Typography>
            Location information: With your consent, we may collect your geolocation data to provide you with location-based offers and promotions from your favorite retailers.
          </Typography>
        </li>
      </ol>
      <br />
      <Typography variant='h5'>
        How we use your information
      </Typography>
      <br />
      <Typography>
        We use the information we collect for the following purposes:
      </Typography>
      <br />
      <ol>
        <li>
          <Typography>
            To provide and maintain our services, including enabling you to create an account, access your digital receipts, and receive offers from your favorite retailers.
          </Typography>
        </li>
        <li>
          <Typography>
            To improve and personalize your experience on our website and app, such as by remembering your preferences and providing content that is relevant to you
          </Typography>
        </li>
        <li>
          <Typography>
            To analyze and understand how our website and app are used, so we can improve and develop new features.
          </Typography>
        </li>
        <li>
          <Typography>
            To communicate with you about our services, offers, promotions, and important updates.
          </Typography>
        </li>
        <li>
          <Typography>
            To protect the security and integrity of our website, app, and user accounts.
          </Typography>
        </li>
        <li>
          <Typography>
            To comply with legal requirements and respond to legal requests.
          </Typography>
        </li>
      </ol>
      <Typography variant="h5">
        Sharing your information
      </Typography>
      <br />
      <Typography>
        We may share your personal information with third parties in the following circumstances:
      </Typography>
      <br />
      <ol>
        <li>
          <Typography>
            With your consent, such as when you choose to share your digital receipts or retailer offers with others.
          </Typography>
        </li>
        <li>
          <Typography>
            With our service providers, who help us operate and maintain our website and app. These providers are contractually obligated to protect your information and use it only for the purposes for which it was disclosed.
          </Typography>
        </li>
        <li>
          <Typography>
            With third-party advertisers, who may use your information to deliver relevant offers and promotions. You can opt-out of targeted advertising by adjusting your cookie preferences, as outlined in our Cookie Policy.
          </Typography>
        </li>
        <li>
          <Typography>
            If required by law, or if we believe it is necessary to protect our rights or the rights of others, such as to comply with a legal request, to prevent fraud, or to enforce our terms of service.
          </Typography>
        </li>
      </ol>
      <Typography variant='h5'>
        Your rights and choices
      </Typography>
      <br />
      <Typography>
        You have the right to access, update, or delete your personal information at any time by logging into your MY-POP account and modifying your account settings. You may also request a copy of your personal information, or request that we restrict the processing of your data or erase it entirely, by contacting us at the address provided below.
      </Typography>
      <br />
      <Typography>
        If you have any concerns about our privacy practices, you have the right to lodge a complaint with a relevant data protection authority.
      </Typography>
      <br />
      <Typography variant='h5'>
        Changes to this Privacy Policy
      </Typography>
      <br />
      <Typography>
        We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. Any changes we make will be posted on this page, so please check back regularly to stay informed of any updates.
      </Typography>
      <br />
      <Typography variant='h5'>
        Contact us
      </Typography>
      <br />
      <Typography>
        If you have any questions or concerns about our use of cookies, please contact us at:
      </Typography>
      <br />
      <Typography>
        My-POP
      </Typography>
      <br />
      <Typography>
        68, Llandaff Rd
      </Typography>
      <br />
      <Typography>
        Cardiff
      </Typography>
      <br />
      <Typography>
        CF11 9NL
      </Typography>
      <br />
      <br />
      <CustomButton onClick={() => goBack()}>
        Back
      </CustomButton>
    </div>
  )
}

export default PrivacyPolicy;