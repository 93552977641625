import axios from 'axios';
import { URLS } from '../constants';

const BASE_URL = `${URLS.BASE_ENDPOINT_URL}/offers`;

export const createOffer = ({ data, token }) => axios.post(`${BASE_URL}/create`, data, { headers: {
    authorization: `Bearer ${token}`
}});
export const getValueOfRedemptions = (retailer_id, token) => axios.get(`${BASE_URL}/getValueOfRedemptions/${retailer_id}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getActiveOffersForRetailer = (retailer_id, token) => axios.get(`${BASE_URL}/activeOffersRetailer/${retailer_id}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getActiveOffersForStoreManager = (s_manager, token) => axios.get(`${BASE_URL}/activeOffersStoreManager/${s_manager}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getRedemeedOffersForRetailer = (retailer_id, token) => axios.get(`${BASE_URL}/redemeedOffersRetailer/${retailer_id}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const getRedemeedOffersForStoreManager = (s_manager, token) => axios.get(`${BASE_URL}/redemeedOffersStoreManager/${s_manager}`, { headers: {
    authorization: `Bearer ${token}`
}});
export const deleteOffer = (id, token) => axios.delete(`${BASE_URL}/deleteOffer/${id}`, { headers: {
    authorization: `Bearer ${token}`
}})
export const updateOffer = ({ data, token }) => axios.post(`${BASE_URL}/updateOffer`, data, { headers: {
    authorization: `Bearer ${token}`
}});
