import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ConsumerHeaderDashboard } from '../../Header/Header'
import { ConsumerTransactionsContainer, CustomGreenButton, CustomInput, HorizontalLineSmall } from '../../index.styled';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../../images/logo.png';
import { QRCode } from 'react-qrcode-logo';
import { updateAddress, updateDob, updatePhone } from '../../../services/userService';
import { Box, IconButton } from '@mui/material';
import { EditRounded } from '@mui/icons-material';
import DeleteAccountPopUp from './DeleteAccountPopUp';

const useStyles = makeStyles((theme) => ({
  typography: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#426A6D',
    textDecoration: 'none'
  },
  styledRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  typographyDarkUppercase: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: 'rgb(45, 97, 99)',
    textTransform: 'uppercase'
  },
  typographyLightUppercase: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#A0D2BB',
    textTransform: 'uppercase'
  },
  icon: {
    color: 'rgb(45, 97, 99)',
  },
}));

const ConsumerDashboard = () => {
  const classes = useStyles();
  const currentUser = useSelector((state) => state.users.currentUser);
  const [dob, setDob] = useState('');
  const [street, setStreet] = useState(currentUser.address?.street ? currentUser.address?.street : '');
  const [postalCode, setPostalCode] = useState(currentUser.address?.postalCode ? currentUser.address?.postalCode : '');
  const [city, setCity] = useState(currentUser.address?.city ? currentUser.address?.city : '');
  const [country, setCountry] = useState(currentUser.address?.country ? currentUser.address?.country : '');
  const [phoneNumber, setPhoneNumber] = useState(currentUser?.phone ? currentUser?.phone : '');
  const [editPhone, setEditPhone] = useState(false);
  const [editAddress, setEditAddress] = useState(false);

  const handleDobChange = (e) => {
    setDob(e.target.value);
  };

  const handleStreetChange = (e) => {
    setStreet(e.target.value);
  };

  const handlePostalCodeChange = (e) => {
    setPostalCode(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handlePhoneNumberChnage = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleIconPhoneChange = () => {
    setEditPhone(!editPhone);
  };

  const handleIconAddressChange = () => {
    setEditAddress(!editAddress);
  };

  const handleUpdatePhoneNumberClick = () => {
    updatePhone({
      data: {
        uid: currentUser.uid,
        phone: phoneNumber,
      },
      token: currentUser.token,
    }).then(() => {
      window.location.reload();
    });
  };

  const handleUpdateAddressClick = () => {
    updateAddress({
      data: {
        street,
        postalCode,
        city,
        country,
        uid: currentUser.uid
      },
      token: currentUser.token,
    }).then(() => {
      window.location.reload();
    });
  };

  const handleUpdateDobClick = () => {
    updateDob({
      data: {
        dob,
        uid: currentUser.uid
      },
      token: currentUser.token,
    }).then(() => {
      window.location.reload();
    });
  }

  return (
    <>
      <ConsumerHeaderDashboard />
      <HorizontalLineSmall center color='#426A6D' thin width='80%' />
      <ConsumerTransactionsContainer>
        <Typography className={classes.typography}>
          Hi {currentUser.firstName},
        </Typography>
        <Typography className={classes.typography}>
          Welcome to your pop page. Here is your My-POP QR code.
        </Typography>
        <Typography className={classes.typography}>
          You can also find your account information...
        </Typography>
        <br />
        <div style={{ marginLeft: '-10px' }}>
          <QRCode
            fgColor='rgb(45, 97, 99)'
            logoImage={logo}
            logoHeight='80'
            logoWidth='80'
            value={currentUser?.myPopId}
            size='200'
            logoOpacity='0.9'
          />
        </div>
        <div style={{ display: 'flex' }}>
          <Typography className={classes.typographyDarkUppercase}>
            First name:
          </Typography>
          <Typography className={classes.typographyLightUppercase}>
             &nbsp;{currentUser?.firstName}
          </Typography>
        </div>
        <br />
        <div style={{ display: 'flex' }}>
          <Typography className={classes.typographyDarkUppercase}>
            Last name:
          </Typography>
          <Typography className={classes.typographyLightUppercase}>
            &nbsp;{currentUser?.lastName}
          </Typography>
        </div>
        <br />
        <div style={{ display: 'flex' }}>
          <Typography className={classes.typographyDarkUppercase}>
            E-mail address:
          </Typography>
          <Typography className={classes.typographyLightUppercase}>
            &nbsp;{currentUser?.email}
          </Typography>
        </div>
        <br />
        {
            (currentUser.phone && !editPhone) ? (
              <div style={{ display: 'flex' }}>
                <Typography className={classes.typographyDarkUppercase}>
                  Phone:
                </Typography>
                <Typography className={classes.typographyLightUppercase}>
                  &nbsp;{currentUser?.phone}
                </Typography>
                <IconButton onClick={() => handleIconPhoneChange()}>
                  <EditRounded className={classes.icon} />
                </IconButton>
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <CustomInput
                  onChange={handlePhoneNumberChnage}
                  value={phoneNumber}
                  border
                  placeholder='Phone number'
                />
                &nbsp;
                <CustomGreenButton onClick={() => handleUpdatePhoneNumberClick()}>
                  Update phone
                </CustomGreenButton>
              </div>
            )
          }
          {
            (currentUser.dob) ? (
              <div style={{ display: 'flex' }}>
                <Typography className={classes.typographyDarkUppercase}>
                  Date of birth:
                </Typography>
                <Typography className={classes.typographyLightUppercase}>
                  &nbsp;{currentUser.dob}
                </Typography>
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <CustomInput
                  type='date'
                  onChange={handleDobChange}
                  value={dob}
                  border
                  placeholder='Date of birth'
                  max={new Date().toISOString().split("T")[0]}
                />
                &nbsp;
                <CustomGreenButton onClick={() => handleUpdateDobClick()}>
                  Update DOB
                </CustomGreenButton>
              </div>
            )
          }
          <br />
           {
            (currentUser.address && !editAddress) ? (
              <>
                <div style={{ display: 'flex' }}>
                  <Typography className={classes.typographyDarkUppercase}>
                    Street:
                  </Typography>
                  <Typography className={classes.typographyLightUppercase}>
                    &nbsp;{currentUser.address.street}
                  </Typography>
                  <IconButton onClick={() => handleIconAddressChange()}>
                    <EditRounded className={classes.icon} />
                  </IconButton>
                </div>
                <div style={{ display: 'flex' }}>
                  <Typography className={classes.typographyDarkUppercase}>
                    Postal code:
                  </Typography>
                  <Typography className={classes.typographyLightUppercase}>
                    &nbsp;{currentUser.address.postalCode}
                  </Typography>
                </div>
                <br />
                <div style={{ display: 'flex' }}>
                  <Typography className={classes.typographyDarkUppercase}>
                    City:
                  </Typography>
                  <Typography className={classes.typographyLightUppercase}>
                    &nbsp;{currentUser.address.city}
                  </Typography>
                </div>
                <br />
                <div style={{ display: 'flex' }}>
                  <Typography className={classes.typographyDarkUppercase}>
                    Country:
                  </Typography>
                  <Typography className={classes.typographyLightUppercase}>
                    &nbsp;{currentUser.address.country}
                  </Typography>
                </div>
              </>
            ) : (
              <div style={{display: 'grid'}}>
                <Typography className={classes.typographyDarkUppercase}>
                  Add your address:
                </Typography>
                <CustomInput
                  border
                  placeholder='Street'
                  value={street}
                  onChange={handleStreetChange}
                />
                <CustomInput
                  border
                  placeholder='Postal code'
                  value={postalCode}
                  onChange={handlePostalCodeChange}
                />
                <CustomInput
                  border
                  placeholder='City'
                  value={city}
                  onChange={handleCityChange}
                />
                <CustomInput
                  border
                  placeholder='Country'
                  value={country}
                  onChange={handleCountryChange}
                />
                <CustomGreenButton onClick={() => handleUpdateAddressClick()}>
                  UPDATE ADDRESS
                </CustomGreenButton>
              </div>
            )
          }
          <br />
          <Typography className={classes.typographyDarkUppercase} style={{ textAlign: 'center' }}>
            List of other loyalty programs is coming soon...
          </Typography>
          <Box style={{ marginTop: '80px' }}>
            <DeleteAccountPopUp myPopId={currentUser.myPopId} />
          </Box>
      </ConsumerTransactionsContainer>
    </>
  )
}

export default ConsumerDashboard