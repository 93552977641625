import React from 'react';
import Scroll from 'react-scroll';
import {
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { BenefitsContainer, BenefitsContext } from '../../index.styled';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import retailer1 from '../../../images/retailer1.png';
import checkBullet from '../../../images/Layer11copy3.png';
import howBusiness from '../../../images/how_business.png';
import payment from '../../../images/payment.png';
import VisitPartners from './VisitPartners';

const Element  = Scroll.Element;

const useStyles = makeStyles((theme) => ({
  typographyh4: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#3B3D40',
    marginTop: '100px',
  },
  typographyh5: {
    fontFamily: 'Gotham-Rounded-Medium !important',
    color: '#3B3D40',
    textAlign: 'left',
  },
  typographyHeaderMobile: {
    fontFamily: 'Gotham-Rounded-Bold !important',
    color: '#3B3D40',
    textAlign: 'left',
    fontSize: '17px'
  },
  tabText: {
    flexGrow: '1',
    marginRight: '0px',
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Gotham-Rounded-Medium !important',
    fontSize: '15px !important',
    '&:selected': {
      backgroundColor: 'blue !important',
    },
  },
  tabPanel: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight:'auto',
    width: '750px',
    textAlign: 'center',
  },
  tabPanelFirst: {
    width: '64px'
  },
  tabPanelSecond: {
    marginLeft: '40px'
  },
  sliderPanel: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight:'auto',
  },
  img: {
    width: '64px'
  },
  list: {
    marginLeft: '0px',
    listStyleImage: `url(${checkBullet})`,
    paddingLeft: '20px',
    marginTop: '40px',
    fontFamily: 'Gotham-Rounded-Book !important',
    textAlign: 'left'
  },
}));

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const OurPartners = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <Element name="our-partners">
      <BenefitsContainer>
        <BenefitsContext>
          <Typography variant='h4' className={classes.typographyh4}>
            Partner with My-POP
          </Typography>
          <Box sx={{ width: '100%', marginTop: '40px' }}>
          {!isMobile ? (
              <>
                <Box sx={{ width: '100%', bgcolor: 'transparent' }}>
                  <Tabs
                  value={value}
                  onChange={handleChange}
                  centered
                  >
                    <Tab className={classes.tabText} label="EPOS/Payment terminals" {...a11yProps(0)} />
                    <Tab className={classes.tabText} label="Aggregators and resellers" {...a11yProps(1)} />
                    <Tab className={classes.tabText} label="Software Providers" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <div className={classes.tabPanel}>
                    <div className={classes.tabPanelFirst}>
                      <img className={classes.img} src={retailer1} alt="retailer1" />
                    </div>
                    <div className={classes.tabPanelSecond}>
                      <Typography className={classes.typographyh5} variant='h5'>
                        Future proof your terminals:
                      </Typography>
                      <ul className={classes.list}>
                        <li>
                          Start sending digital receipts and stop printing paper receipts.
                        </li>
                        <li>
                          Enable cost efficiency to your merchants.
                        </li>
                        <li>
                          Remove fraud, reduce costs, improve profitability and customer experience.
                        </li>
                      </ul>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className={classes.tabPanel}>
                    <div className={classes.tabPanelFirst}>
                      <img className={classes.img} src={howBusiness} alt="retailer1" />
                    </div>
                    <div className={classes.tabPanelSecond}>
                      <Typography className={classes.typographyh5} variant='h5'>
                        Add contactless experience to your clients:
                      </Typography>
                      <ul className={classes.list}>
                        <li>
                          Easily integrate with MY-POP to offer digital receipt platform to your customer.
                        </li>
                        <li>
                          Send digital receipts and stop printing paper receipts.
                        </li>
                        <li>
                          Enable cost efficiency to your merchants.
                        </li>
                      </ul>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className={classes.tabPanel}>
                    <div className={classes.tabPanelFirst}>
                      <img className={classes.img} src={payment} alt="retailer1" />
                    </div>
                    <div className={classes.tabPanelSecond}>
                      <Typography className={classes.typographyh5} variant='h5'>
                        Innovators, developers, gateways and software providers can integrate MY-POP technology anywhere within their payment application or checkout process.
                      </Typography>
                      <ul className={classes.list}>
                        <li>
                          Incorporate digital receipts platform for any payment environment, online or in person.
                        </li>
                        <li>
                          Instantly access developer tools, resources and complete certification.
                        </li>
                      </ul>
                    </div>
                  </div>
                </TabPanel>
              </>
            ) : (
              <>
                <Slider {...settings}>
                  <div>
                    <div className={classes.sliderPanel}>
                      <div className={classes.tabPanelFirst}>
                        <img className={classes.img} src={retailer1} alt="retailer1" />
                      </div>
                      <div className={classes.tabPanelSecond}>
                        <Typography className={classes.typographyHeaderMobile} variant='body1'>
                          Future proof your terminals:
                        </Typography>
                        <ul className={classes.list}>
                          <li>
                            Start sending digital receipts and stop printing paper receipts.
                          </li>
                          <li>
                            Enable cost efficiency to your merchants.
                          </li>
                          <li>
                            Remove fraud, reduce costs, improve profitability and customer experience.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={classes.sliderPanel}>
                      <div className={classes.tabPanelFirst}>
                        <img className={classes.img} src={howBusiness} alt="retailer1" />
                      </div>
                      <div className={classes.tabPanelSecond}>
                        <Typography className={classes.typographyHeaderMobile} variant='body1'>
                          Add contactless experience to your clients:
                        </Typography>
                        <ul className={classes.list}>
                          <li>
                            Easily integrate with MY-POP to offer digital receipt platform to your customer.
                          </li>
                          <li>
                            Send digital receipts and stop printing paper receipts.
                          </li>
                          <li>
                            Enable cost efficiency to your merchants.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={classes.sliderPanel}>
                      <div className={classes.tabPanelFirst}>
                        <img className={classes.img} src={payment} alt="retailer1" />
                      </div>
                      <div className={classes.tabPanelSecond}>
                        <Typography className={classes.typographyHeaderMobile} variant='body1'>
                          Innovators, developers, gateways and software providers can integrate MY-POP technology anywhere within their payment application or checkout process.
                        </Typography>
                        <ul className={classes.list}>
                          <li>
                            Incorporate digital receipts platform for any payment environment, online or in person.
                          </li>
                          <li>
                            Instantly access developer tools, resources and complete certification.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Slider>
              </>
            )}
          </Box>
        </BenefitsContext>
        <br />
        <br />
        <br />
        <VisitPartners />
      </BenefitsContainer>
  </Element>
  )
}

export default OurPartners;
